import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Users } from 'lucide-react';
import { authService } from '../../services/auth'; // Ajout de l'import

interface Team {
  _id: string;
  name: string;
}

interface TeamSelectorProps {
  selectedTeamId: string | null;
  onTeamSelect: (teamId: string | null) => void;
}

export function TeamSelector({ selectedTeamId, onTeamSelect }: TeamSelectorProps) {
  const { data: teams, isLoading } = useQuery<Team[]>({
    queryKey: ['teams'],
    queryFn: async () => {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/teams`, {
        headers: authService.getAuthHeaders() // Utilisation de authService
      });
      if (!response.ok) throw new Error('Failed to fetch teams');
      return response.json();
    }
  });

  if (isLoading) {
    return (
      <div className="animate-pulse h-10 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
    );
  }

  if (!teams?.length) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <Users className="w-5 h-5 text-gray-500" />
      <select
        value={selectedTeamId || ''}
        onChange={(e) => onTeamSelect(e.target.value || null)}
        className="px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
      >
        <option value="">Mes données personnelles</option>
        {teams.map((team) => (
          <option key={team._id} value={team._id}>
            {team.name}
          </option>
        ))}
      </select>
    </div>
  );
}