import React, { useState, useEffect } from 'react';
import { Image as ImageIcon, Video, AlertCircle } from 'lucide-react';
import { getMediaUrl } from '../lib/posts';

interface MediaThumbnailProps {
  media: {
    url: string;
    type: 'image' | 'video';
    externalUrl?: string;
  };
  className?: string;
}

export default function MediaThumbnail({ media, className = '' }: MediaThumbnailProps) {
  const [error, setError] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    const url = getMediaUrl(media);
    console.log('MediaThumbnail URL:', url);
    setCurrentUrl(url);
  }, [media]);

  const handleError = () => {
    console.log('Media load error for URL:', currentUrl);
    
    // Si on utilise déjà l'URL externe, essayer l'URL locale
    if (media.externalUrl && currentUrl === media.externalUrl) {
      const localUrl = getMediaUrl({ ...media, externalUrl: undefined });
      console.log('Trying local URL:', localUrl);
      setCurrentUrl(localUrl);
      return;
    }
    
    setError(true);
  };

  if (error) {
    return (
      <div className={`flex items-center justify-center bg-gray-100 dark:bg-gray-700 ${className}`}>
        <AlertCircle className="w-8 h-8 text-gray-400" />
      </div>
    );
  }

  if (media.type === 'image') {
    return (
      <div className="relative group">
        <img
          src={currentUrl}
          alt=""
          className={`object-cover ${className}`}
          onError={handleError}
        />
        <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
          <ImageIcon className="w-8 h-8 text-white" />
        </div>
      </div>
    );
  }

  if (media.type === 'video') {
    return (
      <div className={`relative group ${className}`}>
        <video
          src={currentUrl}
          className="w-full h-full object-cover"
          onError={handleError}
        />
        <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
          <Video className="w-8 h-8 text-white" />
        </div>
      </div>
    );
  }

  return (
    <div className={`flex items-center justify-center bg-gray-100 dark:bg-gray-700 ${className}`}>
      <ImageIcon className="w-8 h-8 text-gray-400" />
    </div>
  );
}