import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserPlus, Mail, Lock, User, Loader2, Facebook, Chrome } from 'lucide-react';
import { useTranslatedToast } from '../hooks/useTranslatedToast';
import { WaitlistForm } from '../components/WaitlistForm';

export function Signup() {
  const [showWaitlist, setShowWaitlist] = useState(false);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center p-4">
      <div className="max-w-md w-full space-y-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
              Rejoignez la liste d'attente
            </h1>
            <p className="text-gray-600 dark:text-gray-400 mt-2">
              Notre plateforme est actuellement en phase de test privée. 
              Inscrivez-vous pour être parmi les premiers à y accéder.
            </p>
          </div>

          <button
            onClick={() => setShowWaitlist(true)}
            className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Mail className="w-5 h-5" />
            S'inscrire à la liste d'attente
          </button>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Déjà invité ?{' '}
              <Link
                to="/login"
                className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
              >
                Se connecter
              </Link>
            </p>
          </div>
        </div>
      </div>

      {showWaitlist && (
        <WaitlistForm onClose={() => setShowWaitlist(false)} />
      )}
    </div>
  );
}