import React, { useState } from 'react';
import { useTeams } from '../../hooks/useTeams';
import { Users, Plus, Settings2, Mail, Loader2, UserPlus, Shield, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

export function TeamManagement() {
  const { t } = useTranslation(['app', 'teams']);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const { useTeamsList, createTeam, inviteMember } = useTeams();
  const { data: teams, isLoading } = useTeamsList();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-indigo-500" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold flex items-center gap-2">
          <Users className="w-6 h-6 text-indigo-500" />
          {t('teams:teams.management.title')}
        </h1>
        <button
          onClick={() => setShowInviteModal(true)}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          <Plus className="w-5 h-5" />
          Nouvelle équipe
        </button>
      </div>

      {/* Team List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {teams?.map((team: any) => (
          <div
            key={team._id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6"
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="font-semibold text-lg">{team.name}</h3>
                {team.description && (
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    {team.description}
                  </p>
                )}
              </div>
              <button
                onClick={() => setSelectedTeam(team)}
                className="p-2 text-gray-500 hover:text-indigo-500 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <Settings2 className="w-5 h-5" />
              </button>
            </div>

            <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
              <Users className="w-4 h-4" />
              <span>
                {team.members.length} {t('teams:teams.management.members')}
              </span>
            </div>

            <div className="mt-4 flex justify-end">
              <button
                onClick={() => {
                  setSelectedTeam(team);
                  setShowInviteModal(true);
                }}
                className="flex items-center gap-2 px-3 py-1.5 text-sm text-indigo-600 hover:bg-indigo-50 dark:text-indigo-400 dark:hover:bg-indigo-900/30 rounded-lg"
              >
                <UserPlus className="w-4 h-4" />
                {t('teams:teams.management.invite')}
              </button>
            </div>zssqzéssssqzaéqazéaq
          </div>
        ))}
      </div>

      {/* Create/Invite Modal */}
      {showInviteModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-medium flex items-center gap-2">
                <Mail className="w-5 h-5 text-indigo-500" />
                {selectedTeam ? t('teams:teams.management.invite') : 'Nouvelle équipe'}
              </h2>
              <button
                onClick={() => {
                  setShowInviteModal(false);
                  setSelectedTeam(null);
                }}
                className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {selectedTeam ? (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const formData = new FormData(e.currentTarget);
                  await inviteMember.mutateAsync({
                    teamId: selectedTeam._id,
                    email: formData.get('email') as string,
                    role: formData.get('role') as string
                  });
                  setShowInviteModal(false);
                  setSelectedTeam(null);
                }}
                className="space-y-4"
              >
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2">
                    Rôle
                  </label>
                  <select
                    name="role"
                    className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
                  >
                    <option value="editor">{t('teams:teams.management.roles.editor')}</option>
                    <option value="viewer">{t('teams:teams.management.roles.viewer')}</option>
                  </select>
                </div>

                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={() => {
                      setShowInviteModal(false);
                      setSelectedTeam(null);
                    }}
                    className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    disabled={inviteMember.isPending}
                    className="flex items-center gap-2 px-4 py-2 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
                  >
                    {inviteMember.isPending ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : (
                      <UserPlus className="w-4 h-4" />
                    )}
                    Inviter
                  </button>
                </div>
              </form>
            ) : (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const formData = new FormData(e.currentTarget);
                  await createTeam.mutateAsync({
                    name: formData.get('name') as string,
                    description: formData.get('description') as string
                  });
                  setShowInviteModal(false);
                }}
                className="space-y-4"
              >
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Nom de l'équipe
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-2">
                    Description
                  </label>
                  <textarea
                    name="description"
                    rows={3}
                    className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
                  ></textarea>
                </div>

                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={() => setShowInviteModal(false)}
                    className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    disabled={createTeam.isPending}
                    className="flex items-center gap-2 px-4 py-2 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
                  >
                    {createTeam.isPending ? (
                      <Loader2 className="w-4 h-4 animate-spin" />
                    ) : (
                      <Plus className="w-4 h-4" />
                    )}
                    Créer
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}