import { useQuery } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';

interface DashboardFilters {
  period?: string;
  platform?: string;
  pageId?: string | null;
}

export function useDashboard() {
  const { token } = useAuthStore();
  const apiUrl = import.meta.env.VITE_API_URL;

  const useDashboardStats = (filters: DashboardFilters = {}) => {
    const params = new URLSearchParams();
    
    if (filters.period) params.append('period', filters.period);
    if (filters.platform && filters.platform !== 'all') {
      params.append('platform', filters.platform);
      if (filters.pageId && filters.platform === 'facebook') {
        params.append('pageId', filters.pageId);
      }
    }

    return useQuery({
      queryKey: ['dashboard', 'stats', filters],
      queryFn: async () => {
        const response = await fetch(
          `${apiUrl}/analytics/dashboard?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) throw new Error('Failed to fetch dashboard stats');
        return response.json();
      },
    });
  };

  return {
    useDashboardStats,
  };
}