import { useState, useCallback } from 'react';
import { useAuthStore } from '../../../stores/authStore';
import { aiService } from '../services';
import { AIResponse } from '../types';
import { toast } from 'react-hot-toast';

interface UseAIGeneratorOptions {
  type: 'post' | 'reply' | 'campaign' | 'analysis';
  onError?: (error: Error) => void;
}

export function useAIGenerator({ type, onError }: UseAIGeneratorOptions) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [lastResponse, setLastResponse] = useState<AIResponse | null>(null);
  const { token } = useAuthStore();

  const generate = useCallback(async (
    prompt: string,
    options?: {
      platform?: string;
      settings?: Record<string, any>;
      context?: string | string[];
    }
  ) => {
    if (!token) {
      const authError = new Error('Authentication required');
      onError?.(authError);
      throw authError;
    }

    // Prevent generating if AI is disabled
    if (options?.settings?.enabled === false) {
      throw new Error('AI is disabled in settings');
    }

    setIsLoading(true);
    setError(null);

    try {
      // Prevent recursive generation
      const finalSettings = { ...options?.settings };
      delete finalSettings.type;
      delete finalSettings.autoRespond;
      delete finalSettings.autoModerate;

      const response = await aiService.generate(
        prompt,
        {
          type,
          platform: options?.platform,
          settings: finalSettings,
          context: typeof options?.context === 'string' 
            ? options.context 
            : options?.context?.join('\n')
        },
        token
      );

      if (response.confidence < (finalSettings?.confidenceThreshold || 0.7)) {
        toast.warning('La réponse générée manque de confiance, vous devriez la vérifier');
      }

      setLastResponse(response);
      return response;
    } catch (error) {
      console.error('AI generation error:', error);
      const finalError = error instanceof Error ? error : new Error('Unknown error');
      setError(finalError);
      onError?.(finalError);
      throw finalError;
    } finally {
      setIsLoading(false);
    }
  }, [token, type, onError]);

  return {
    generate,
    isLoading,
    error,
    lastResponse,
    clearError: () => setError(null),
    clearResponse: () => setLastResponse(null),
  };
}