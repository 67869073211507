import React, { useState, useRef } from 'react';
import { Save, Loader2, Upload, User } from 'lucide-react';
import { useAuthStore } from '../../stores/authStore';
import apiClient from '../../lib/apiClient';
import { toast } from 'react-hot-toast';
import { getImageUrl } from '../../utils/imageUtils';

interface ProfileSettingsProps {
  profileData: {
    name: string;
    email: string;
    avatar?: string;
  };
  isLoading: boolean;
  onUpdate: (data: any) => Promise<void>;
  onChange: (data: any) => void;
}

export function ProfileSettings({ profileData, isLoading, onUpdate, onChange }: ProfileSettingsProps) {
  const [avatarPreview, setAvatarPreview] = useState<string | null>(profileData.avatar || null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { token } = useAuthStore();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onUpdate(profileData);
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type
    if (!file.type.startsWith('image/')) {
      toast.error('Veuillez sélectionner une image');
      return;
    }

    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast.error('L\'image ne doit pas dépasser 5MB');
      return;
    }

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatarPreview(reader.result as string);
    };
    reader.readAsDataURL(file);

    // Create form data
    const formData = new FormData();
    formData.append('avatar', file);

    try {
      const { data } = await apiClient.post('/users/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      onChange({ ...profileData, avatar: data.avatarUrl });
      toast.success('Photo de profil mise à jour');
    } catch (error) {
      console.error('Avatar upload error:', error);
      toast.error('Erreur lors de la mise à jour de la photo');
      setAvatarPreview(profileData.avatar || null);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <h2 className="text-lg font-medium">Informations du profil</h2>
      
      <div className="flex flex-col items-center space-y-4">
        {/* Avatar */}
        <div 
          onClick={handleAvatarClick}
          className="relative group cursor-pointer"
        >
          {avatarPreview ? (
           <img
           src={getImageUrl(avatarPreview)}
           alt="Profile"
           className="w-24 h-24 rounded-full object-cover"
         />
          ) : (
            <div className="w-24 h-24 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
              <User className="w-12 h-12 text-gray-400" />
            </div>
          )}
          
          <div className="absolute inset-0 flex items-center justify-center rounded-full bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity">
            <Upload className="w-6 h-6 text-white" />
          </div>
        </div>

        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
        />

        <p className="text-sm text-gray-500 dark:text-gray-400">
          Cliquez pour changer votre photo de profil
        </p>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-2">
            Nom complet
          </label>
          <input
            type="text"
            value={profileData.name}
            onChange={(e) => onChange({ ...profileData, name: e.target.value })}
            className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">
            Email
          </label>
          <input
            type="email"
            value={profileData.email}
            onChange={(e) => onChange({ ...profileData, email: e.target.value })}
            className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isLoading ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          Enregistrer
        </button>
      </div>
    </form>
  );
}