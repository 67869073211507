// src/utils/dateUtils.ts

export const DATE_FORMAT_URL = 'yyyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'';

export interface DateRange {
  start: Date | null;
  end: Date | null;
}

export function formatDateForUrl(date: Date | null): string | null {
  if (!date) return null;
  return date.toISOString();
}

export function parseDateFromUrl(dateString: string | null): Date | null {
  if (!dateString) return null;
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? null : date;
}

export function formatDateForInput(date: Date | null): string {
  if (!date) return '';
  return date.toISOString().split('T')[0];
}

export function createDateRange(startDate: string | null, endDate: string | null): DateRange {
  return {
    start: parseDateFromUrl(startDate),
    end: parseDateFromUrl(endDate)
  };
}

export function normalizeDateRange(range: DateRange): DateRange {
  if (!range.start) return { start: null, end: null };
  
  const start = new Date(range.start);
  start.setHours(0, 0, 0, 0);

  let end = range.end ? new Date(range.end) : null;
  if (end) {
    end.setHours(23, 59, 59, 999);
    if (end < start) {
      end = null;
    }
  }

  return { start, end };
}