// src/components/suggestions/ui/AISettings.tsx
import React from 'react';
import { Brain, X } from 'lucide-react';
import { AISettings } from '../types';
import { toast } from 'react-hot-toast';

interface AISettingsPanelProps {
  isOpen: boolean;
  onClose: () => void;
  settings: AISettings;
  onUpdate: (settings: Partial<AISettings>) => void;
  onSave: () => void;
}

export function AISettingsPanel({
  isOpen,
  onClose,
  settings,
  onUpdate,
  onSave
}: AISettingsPanelProps) {
  if (!isOpen) return null;

  const handleSave = () => {
    onSave();
    toast.success('Paramètres IA sauvegardés');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <h2 className="text-lg font-medium flex items-center gap-2">
            <Brain className="w-5 h-5 text-indigo-500" />
            Paramètres de l'IA
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          <ToneSelector value={settings.tone} onChange={(tone) => onUpdate({ tone })} />
          <StyleSelector value={settings.style} onChange={(style) => onUpdate({ style })} />
          <LengthSelector value={settings.length} onChange={(length) => onUpdate({ length })} />
          <CreativitySlider value={settings.creativity} onChange={(creativity) => onUpdate({ creativity })} />
          <OptionsGroup
            options={settings}
            onChange={(updates) => onUpdate(updates)}
          />
        </div>

        <div className="flex justify-end gap-3 p-4 border-t dark:border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
          >
            Annuler
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            Sauvegarder
          </button>
        </div>
      </div>
    </div>
  );
}

// Composants internes pour une meilleure organisation
function ToneSelector({ value, onChange }: { value: string; onChange: (value: string) => void }) {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium">Ton</label>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
      >
        <option value="professional">Professionnel</option>
        <option value="casual">Décontracté</option>
        <option value="friendly">Amical</option>
        <option value="formal">Formel</option>
        <option value="humorous">Humoristique</option>
        <option value="empathetic">Empathique</option>
      </select>
    </div>
  );
}

function StyleSelector({ value, onChange }: { value: string; onChange: (value: string) => void }) {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium">Style</label>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
      >
        <option value="informative">Informatif</option>
        <option value="storytelling">Storytelling</option>
        <option value="persuasive">Persuasif</option>
        <option value="engaging">Engageant</option>
        <option value="educational">Éducatif</option>
      </select>
    </div>
  );
}

function LengthSelector({ value, onChange }: { value: string; onChange: (value: string) => void }) {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium">Longueur</label>
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
      >
        <option value="short">Court</option>
        <option value="medium">Moyen</option>
        <option value="long">Long</option>
      </select>
    </div>
  );
}

function CreativitySlider({ value, onChange }: { value: number; onChange: (value: number) => void }) {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium">
        Créativité: {Math.round(value * 100)}%
      </label>
      <input
        type="range"
        min="0"
        max="1"
        step="0.1"
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
        className="w-full"
      />
      <div className="flex justify-between text-xs text-gray-500">
        <span>Conservateur</span>
        <span>Créatif</span>
      </div>
    </div>
  );
}

function OptionsGroup({ 
  options, 
  onChange 
}: { 
  options: Pick<AISettings, 'useHashtags' | 'useMentions' | 'useEmojis'>;
  onChange: (updates: Partial<AISettings>) => void;
}) {
  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium">Options</label>
      <div className="space-y-2">
        <OptionCheckbox
          label="Utiliser des hashtags"
          checked={options.useHashtags}
          onChange={(checked) => onChange({ useHashtags: checked })}
        />
        <OptionCheckbox
          label="Utiliser des mentions"
          checked={options.useMentions}
          onChange={(checked) => onChange({ useMentions: checked })}
        />
        <OptionCheckbox
          label="Utiliser des emojis"
          checked={options.useEmojis}
          onChange={(checked) => onChange({ useEmojis: checked })}
        />
      </div>
    </div>
  );
}

function OptionCheckbox({ 
  label, 
  checked, 
  onChange 
}: { 
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <label className="flex items-center gap-2">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
      />
      <span className="text-sm">{label}</span>
    </label>
  );
}