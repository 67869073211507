import React, { useState, useEffect } from 'react';
import { Clock, X, TrendingUp } from 'lucide-react';
import { useAuthStore } from '../stores/authStore';
import { toast } from 'react-hot-toast';

interface OptimalTime {
  hour: number;
  engagement: number;
  confidence: number;
}

interface PlatformOptimalTimes {
  platform: string;
  times: OptimalTime[];
  bestDays: string[];
  frequency: string;
  audienceTimezone: string;
}

interface OptimalTimesAnalysisProps {
  platforms: string[];
  onClose: () => void;
  onTimeSelect?: (date: Date) => void;
}

export function OptimalTimesAnalysis({ platforms, onClose, onTimeSelect }: OptimalTimesAnalysisProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PlatformOptimalTimes[]>([]);
  const { token } = useAuthStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/posts/optimal-times`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch optimal times');
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Failed to fetch optimal times:', error);
        toast.error('Erreur lors du chargement des horaires optimaux');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [platforms, token]);

  const formatHour = (hour: number) => {
    return new Date(2000, 0, 1, hour).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleTimeSelect = (platform: string, hour: number) => {
    if (!onTimeSelect) return;

    const date = new Date();
    date.setHours(hour, 0, 0, 0);
    
    // Si l'heure est déjà passée, programmer pour le lendemain
    if (date < new Date()) {
      date.setDate(date.getDate() + 1);
    }
    
    onTimeSelect(date);
    onClose();
    toast.success(`Horaire optimal sélectionné: ${formatHour(hour)}`);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold flex items-center gap-2">
            <Clock className="w-5 h-5 text-indigo-500" />
            Analyse des horaires optimaux
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {loading ? (
          <div className="text-center py-8">Chargement de l'analyse...</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {data.filter(d => platforms.includes(d.platform.toLowerCase())).map((platformData) => (
              <div
                key={platformData.platform}
                className="p-4 border dark:border-gray-700 rounded-lg"
              >
                <h3 className="font-medium mb-4 capitalize">{platformData.platform}</h3>

                {/* Meilleurs horaires */}
                <div className="space-y-3">
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Meilleurs horaires :
                  </p>
                  {platformData.times.map((time, index) => (
                    <button
                      key={index}
                      onClick={() => handleTimeSelect(platformData.platform, time.hour)}
                      className="w-full flex items-center justify-between p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <span>{formatHour(time.hour)}</span>
                      <div className="flex items-center gap-2">
                        <div className="w-20 h-2 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden">
                          <div
                            className="h-full bg-indigo-500"
                            style={{ width: `${time.engagement * 100}%` }}
                          />
                        </div>
                        <span className="text-xs text-gray-500">
                          {Math.round(time.engagement * 100)}%
                        </span>
                      </div>
                    </button>
                  ))}
                </div>

                {/* Meilleurs jours */}
                <div className="mt-4">
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
                    Meilleurs jours :
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {platformData.bestDays.map((day) => (
                      <span
                        key={day}
                        className="px-2 py-1 text-xs bg-indigo-50 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400 rounded"
                      >
                        {day}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Fréquence recommandée */}
                <div className="mt-4">
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Fréquence recommandée :
                  </p>
                  <p className="text-sm font-medium">{platformData.frequency}</p>
                </div>

                {/* Fuseau horaire */}
                <div className="mt-4">
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    Fuseau horaire de l'audience :
                  </p>
                  <p className="text-sm font-medium">
                    {platformData.audienceTimezone}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}