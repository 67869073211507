interface FeedbackOptions {
  suggestion: string;
  isPositive: boolean;
  context?: {
    platform?: string;
    type?: string;
    prompt?: string;
  };
}

export const feedbackService = {
  async submit(options: FeedbackOptions): Promise<void> {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token');

      await fetch(`${import.meta.env.VITE_API_URL}/ai/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          suggestion: options.suggestion,
          feedback: options.isPositive ? 'positive' : 'negative',
          context: options.context
        }),
      });
    } catch (error) {
      console.error('Feedback submission error:', error);
      throw error;
    }
  }
};