import React, { useState } from 'react';
import { 
  Pencil, 
  Trash2, 
  ChevronDown, 
  ChevronUp, 
  MessageCircle, 
  ThumbsUp, 
  Share2, 
  Eye, 
  ExternalLink, 
  Loader2, 
  BarChart2,
  ImageIcon,
  Calendar
} from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Badge } from '../ui/badge';

interface PostListItemProps {
  post: {
    _id: string;
    content: string;
    platform: string;
    status: 'draft' | 'scheduled' | 'pending' | 'published' | 'failed';
    scheduledDate?: Date;
    media?: Array<{
      url: string;
      type: 'image' | 'video';
      alt?: string;
    }>;
    metadata?: {
      pageId?: string;
      pageName?: string;
      publishedAt?: string;
      originalCreatedAt?: string;
    };
    analytics?: {
      likes: number;
      comments: number;
      shares: number;
      reach: number;
      engagement: number;
      impressions: number;
      clicks: number;
      saves: number;
    };
  };
  isExpanded: boolean;
  onToggleExpand: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => Promise<void>;
  onPreview: (post: any) => void;
}

const getStatusBadgeProps = (status: string) => {
  switch (status) {
    case 'published':
      return { label: 'Publié', variant: 'success' };
    case 'scheduled':
      return { label: 'Planifié', variant: 'warning' };
    case 'pending':
      return { label: 'En attente', variant: 'default' };
    case 'draft':
      return { label: 'Brouillon', variant: 'secondary' };
    case 'failed':
      return { label: 'Échec', variant: 'destructive' };
    default:
      return { label: status, variant: 'default' };
  }
};

export const PostListItem = ({
  post,
  isExpanded,
  onToggleExpand,
  onEdit,
  onDelete,
  onPreview
}: PostListItemProps) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isDeleting) return;
    
    setIsDeleting(true);
    try {
      await onDelete(post._id);
    } finally {
      setIsDeleting(false);
    }
  };

  const formatMetric = (value: number) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M`;
    }
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value.toString();
  };

  const renderMetricItem = (Icon: any, value: number) => (
    <div className="flex items-center gap-2">
      <Icon className="w-4 h-4 text-gray-400" />
      <span className="text-sm">{formatMetric(value)}</span>
    </div>
  );

  const statusProps = getStatusBadgeProps(post.status);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <div 
        className="p-4 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700/50"
        onClick={onToggleExpand}
      >
        <div className="flex items-start gap-4">
          {/* Miniature */}
          <div className="w-24 h-24 flex-shrink-0 rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700">
            {post.media && post.media[0] ? (
              <img
                src={post.media[0].url}
                alt={post.media[0].alt || 'Media preview'}
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <ImageIcon className="w-8 h-8 text-gray-400" />
              </div>
            )}
          </div>

          {/* Contenu */}
          <div className="flex-1 min-w-0">
            {/* En-tête avec plateforme et nom de la page */}
            <div className="flex justify-between items-start">
              <div className="flex items-center gap-2 flex-wrap">
                <span className="capitalize font-medium">{post.platform}</span>
                {post.metadata?.pageName && (
                  <>
                    <span className="text-gray-400">•</span>
                    <span className="text-gray-600 dark:text-gray-400">
                      {post.metadata.pageName}
                    </span>
                  </>
                )}
                <Badge variant={statusProps.variant as any}>
                  {statusProps.label}
                </Badge>
                {post.scheduledDate && (
                  <div className="flex items-center gap-1 text-sm text-muted-foreground">
                    <Calendar className="w-4 h-4" />
                    {format(new Date(post.scheduledDate), 'dd MMM yyyy HH:mm', { locale: fr })}
                  </div>
                )}
              </div>

              {/* Actions */}
              <div className="flex items-center gap-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(post._id);
                  }}
                  className="p-2 text-gray-500 hover:text-indigo-500 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <Pencil className="w-5 h-5" />
                </button>
                <button
                  onClick={handleDelete}
                  disabled={isDeleting}
                  className={`p-2 rounded-lg transition-colors ${
                    isDeleting
                      ? 'bg-gray-100 dark:bg-gray-700 cursor-not-allowed'
                      : 'text-gray-500 hover:text-red-500 hover:bg-gray-100 dark:hover:bg-gray-700'
                  }`}
                >
                  {isDeleting ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    <Trash2 className="w-5 h-5" />
                  )}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onPreview(post);
                  }}
                  className="p-2 text-gray-500 hover:text-indigo-500 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <ExternalLink className="w-5 h-5" />
                </button>
                {isExpanded ? (
                  <ChevronUp className="w-5 h-5 text-gray-400" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-400" />
                )}
              </div>
            </div>

            {/* Contenu du post */}
            <p className={`mt-2 text-gray-900 dark:text-gray-100 ${
              isExpanded ? '' : 'line-clamp-3'
            }`}>
              {post.content}
            </p>

            {/* Métriques de base */}
            <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
              {renderMetricItem(ThumbsUp, post.analytics?.likes || 0)}
              {renderMetricItem(MessageCircle, post.analytics?.comments || 0)}
              {renderMetricItem(Share2, post.analytics?.shares || 0)}
              {renderMetricItem(Eye, post.analytics?.reach || 0)}
            </div>

            {/* Date de publication/création */}
            {(post.metadata?.publishedAt || post.metadata?.originalCreatedAt) && (
              <div className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                {format(
                  new Date(post.metadata.publishedAt || post.metadata.originalCreatedAt), 
                  'dd MMM yyyy HH:mm', 
                  { locale: fr }
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Contenu étendu */}
      {isExpanded && (
        <div className="border-t dark:border-gray-700 p-4">
          {/* Galerie de médias */}
          {post.media && post.media.length > 0 && (
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 mb-6">
              {post.media.map((media, index) => (
                <div
                  key={index}
                  className="relative aspect-square rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700"
                >
                  <img
                    src={media.url}
                    alt={media.alt || `Media ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          )}

          {/* Métriques détaillées */}
          <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
            <h4 className="text-sm font-medium flex items-center gap-2 mb-4">
              <BarChart2 className="w-4 h-4 text-indigo-500" />
              Métriques détaillées
            </h4>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Engagement</p>
                <p className="text-lg font-semibold">{post.analytics?.engagement || 0}%</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Impressions</p>
                <p className="text-lg font-semibold">{formatMetric(post.analytics?.impressions || 0)}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Clics</p>
                <p className="text-lg font-semibold">{formatMetric(post.analytics?.clicks || 0)}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Enregistrements</p>
                <p className="text-lg font-semibold">{formatMetric(post.analytics?.saves || 0)}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PostListItem;