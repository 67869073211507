import React from 'react';
import { motion } from 'framer-motion';
import { Search, Calendar, Tag, ArrowRight } from 'lucide-react';

export function Blog() {
  const posts = [
    {
      title: "Comment optimiser votre stratégie social media en 2024",
      excerpt: "Découvrez les dernières tendances et meilleures pratiques pour maximiser votre présence sur les réseaux sociaux.",
      category: "tips",
      date: "2024-01-15",
      image: "https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?q=80&w=2074&auto=format&fit=crop",
      author: {
        name: "John Doe",
        avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=2070&auto=format&fit=crop"
      }
    },
    {
      title: "L'IA dans le marketing digital : guide complet",
      excerpt: "Comment l'intelligence artificielle révolutionne le marketing digital et comment l'utiliser efficacement.",
      category: "guides",
      date: "2024-01-10",
      image: "https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=2070&auto=format&fit=crop",
      author: {
        name: "Jane Smith",
        avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=2070&auto=format&fit=crop"
      }
    },
    {
      title: "Étude de cas : +300% d'engagement en 3 mois",
      excerpt: "Découvrez comment une entreprise a triplé son engagement sur les réseaux sociaux avec notre plateforme.",
      category: "case-studies",
      date: "2024-01-05",
      image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2015&auto=format&fit=crop",
      author: {
        name: "Mike Johnson",
        avatar: "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?q=80&w=2070&auto=format&fit=crop"
      }
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      {/* Hero Section */}
      <div className="container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-3xl mx-auto mb-16"
        >
          <h1 className="text-4xl font-bold mb-6 dark:text-white">
            Blog & Ressources
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Découvrez nos derniers articles, guides et conseils sur le marketing des réseaux sociaux
          </p>

          {/* Search */}
          <div className="mt-8 relative max-w-xl mx-auto">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher un article..."
              className="w-full pl-12 pr-4 py-3 rounded-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
          </div>
        </motion.div>

        {/* Categories */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="flex flex-wrap justify-center gap-4 mb-16"
        >
          {['Tous', 'Conseils', 'Guides', 'Actualités', 'Études de cas'].map((category, index) => (
            <button
              key={category}
              className={`px-4 py-2 rounded-full text-sm ${
                index === 0
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700'
              }`}
            >
              {category}
            </button>
          ))}
        </motion.div>

        {/* Blog Posts Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {posts.map((post, index) => (
            <motion.article
              key={post.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 + index * 0.1 }}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm overflow-hidden"
            >
              <div className="relative h-48">
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-full object-cover"
                />
                <span className="absolute top-4 left-4 px-3 py-1 text-xs font-medium rounded-full bg-white dark:bg-gray-800 text-indigo-600 dark:text-indigo-400">
                  {post.category}
                </span>
              </div>
              <div className="p-6">
                <div className="flex items-center gap-4 mb-4">
                  <img
                    src={post.author.avatar}
                    alt={post.author.name}
                    className="w-10 h-10 rounded-full"
                  />
                  <div>
                    <p className="font-medium dark:text-white">{post.author.name}</p>
                    <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                      <Calendar className="w-4 h-4" />
                      {new Date(post.date).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                <h2 className="text-xl font-semibold mb-2 dark:text-white">
                  {post.title}
                </h2>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  {post.excerpt}
                </p>
                <button className="text-indigo-600 dark:text-indigo-400 font-medium flex items-center gap-2 hover:gap-3 transition-all">
                  Lire la suite
                  <ArrowRight className="w-4 h-4" />
                </button>
              </div>
            </motion.article>
          ))}
        </div>

        {/* Newsletter */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="max-w-2xl mx-auto text-center"
        >
          <h2 className="text-2xl font-bold mb-4 dark:text-white">
            Restez informé
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Recevez nos derniers articles et conseils directement dans votre boîte mail
          </p>
          <div className="flex gap-4">
            <input
              type="email"
              placeholder="Votre email"
              className="flex-1 px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
            <button className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700">
              S'abonner
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
}