import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';
import { toast } from 'react-hot-toast';

export function useSyncManual() {
  const { token } = useAuthStore();
  const queryClient = useQueryClient();
  const apiUrl = import.meta.env.VITE_API_URL;

  // Synchronisation des commentaires
  const syncComments = useMutation({
    mutationFn: async (accountId: string) => {
      const response = await fetch(
        `${apiUrl}/social/sync/${accountId}/comments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to sync comments');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      toast.success('Commentaires synchronisés avec succès');
    },
    onError: (error) => {
      console.error('Comments sync error:', error);
      toast.error('Erreur lors de la synchronisation des commentaires');
    }
  });

  // Synchronisation des médias
  const syncMedia = useMutation({
    mutationFn: async (accountId: string) => {
      const response = await fetch(
        `${apiUrl}/social/sync/${accountId}/media`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to sync media');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      toast.success('Médias synchronisés avec succès');
    },
    onError: (error) => {
      console.error('Media sync error:', error);
      toast.error('Erreur lors de la synchronisation des médias');
    }
  });

  // Synchronisation complète
  const syncAll = useMutation({
    mutationFn: async (accountId: string) => {
      const response = await fetch(
        `${apiUrl}/social/sync/${accountId}?fullSync=true`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to sync account');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      toast.success('Synchronisation complète réussie');
    },
    onError: (error) => {
      console.error('Full sync error:', error);
      toast.error('Erreur lors de la synchronisation complète');
    }
  });

  return {
    syncComments,
    syncMedia,
    syncAll
  };
}