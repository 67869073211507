import React, { useState } from 'react';
import { 
  Settings as SettingsIcon,
  Mail,
  Lock,
  User,
  Bell,
  Share2
} from 'lucide-react';
import { ProfileSettings } from '../components/settings/ProfileSettings';
import { SecuritySettings } from '../components/settings/SecuritySettings';
import { EmailSettings } from '../components/settings/EmailSettings';
import { NotificationSettings } from '../components/settings/NotificationSettings';
import { SocialAccountsSettings } from '../components/settings/SocialAccountsSettings';
import { useAuthStore } from '../stores/authStore';
import { useApi } from '../hooks/useApi';
import { toast } from 'react-hot-toast';
import { useProfile } from '../hooks/useProfile';


type SettingsTab = 'profile' | 'security' | 'email' | 'notifications' | 'social';

export function Settings() {
  const { user, token, setAuth } = useAuthStore();
  const api = useApi();
  const [activeTab, setActiveTab] = useState<SettingsTab>('profile');
  const [isLoading, setIsLoading] = useState(false);
  const { refresh } = useProfile();


  // States for different settings sections
  const [profileData, setProfileData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    avatar: user?.avatar || ''
  });

  const [emailSettings, setEmailSettings] = useState({
    provider: 'sendgrid',
    fromEmail: '',
    sendgridApiKey: '',
    gmailUser: '',
    gmailAppPassword: '',
    office365User: '',
    office365Password: ''
  });

  const [securityData, setSecurityData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    pushNotifications: true,
    commentNotifications: true,
    messageNotifications: true,
    teamNotifications: true
  });

  // Update handlers
  const handleProfileUpdate = async (data: any) => {
    setIsLoading(true);
    try {
      await api.put('/users/profile', data);
      setAuth({ ...user, ...data }, token);
      refresh(); // Refresh profile data
      toast.success('Profil mis à jour avec succès');
    } catch (error) {
      console.error('Profile update error:', error);
      toast.error('Erreur lors de la mise à jour du profil');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailSettingsUpdate = async (data: any) => {
    setIsLoading(true);
    try {
      await api.put('/settings/email', data);
      toast.success('Paramètres email mis à jour avec succès');
    } catch (error) {
      toast.error('Erreur lors de la mise à jour des paramètres email');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = async (data: any) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error('Les mots de passe ne correspondent pas');
      return;
    }
    
    setIsLoading(true);
    try {
      await api.put('/users/password', {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword
      });
      toast.success('Mot de passe mis à jour avec succès');
      setSecurityData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (error) {
      console.error('Password update error:', error);
      toast.error('Erreur lors de la mise à jour du mot de passe');
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleNotificationSettingsUpdate = async (data: any) => {
    setIsLoading(true);
    try {
      await api.put('/settings/notifications', data);
      toast.success('Préférences de notification mises à jour');
    } catch (error) {
      toast.error('Erreur lors de la mise à jour des préférences');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold flex items-center gap-2">
          <SettingsIcon className="w-6 h-6 text-indigo-500" />
          Paramètres
        </h1>
      </div>

      <div className="flex flex-col md:flex-row gap-8">
        {/* Navigation */}
        <nav className="w-full md:w-64 space-y-1">
          <button
            onClick={() => setActiveTab('profile')}
            className={`w-full flex items-center gap-2 px-4 py-2 text-sm rounded-lg ${
              activeTab === 'profile'
                ? 'bg-indigo-50 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                : 'text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800'
            }`}
          >
            <User className="w-5 h-5" />
            Profil
          </button>

          <button
            onClick={() => setActiveTab('security')}
            className={`w-full flex items-center gap-2 px-4 py-2 text-sm rounded-lg ${
              activeTab === 'security'
                ? 'bg-indigo-50 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                : 'text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800'
            }`}
          >
            <Lock className="w-5 h-5" />
            Sécurité
          </button>

          <button
            onClick={() => setActiveTab('email')}
            className={`w-full flex items-center gap-2 px-4 py-2 text-sm rounded-lg ${
              activeTab === 'email'
                ? 'bg-indigo-50 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                : 'text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800'
            }`}
          >
            <Mail className="w-5 h-5" />
            Email
          </button>

          <button
            onClick={() => setActiveTab('notifications')}
            className={`w-full flex items-center gap-2 px-4 py-2 text-sm rounded-lg ${
              activeTab === 'notifications'
                ? 'bg-indigo-50 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                : 'text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800'
            }`}
          >
            <Bell className="w-5 h-5" />
            Notifications
          </button>

          <button
            onClick={() => setActiveTab('social')}
            className={`w-full flex items-center gap-2 px-4 py-2 text-sm rounded-lg ${
              activeTab === 'social'
                ? 'bg-indigo-50 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                : 'text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800'
            }`}
          >
            <Share2 className="w-5 h-5" />
            Comptes sociaux
          </button>
        </nav>

        {/* Content */}
        <div className="flex-1">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
            {activeTab === 'profile' && (
              <ProfileSettings
                profileData={profileData}
                isLoading={isLoading}
                onUpdate={handleProfileUpdate}
                onChange={setProfileData}
              />
            )}

            {activeTab === 'security' && (
              <SecuritySettings
                securityData={securityData}
                isLoading={isLoading}
                onUpdate={handlePasswordChange}
                onChange={setSecurityData}
              />
            )}

            {activeTab === 'email' && (
              <EmailSettings
                emailSettings={emailSettings}
                isLoading={isLoading}
                onUpdate={handleEmailSettingsUpdate}
                onChange={setEmailSettings}
              />
            )}

            {activeTab === 'notifications' && (
              <NotificationSettings
                notificationSettings={notificationSettings}
                isLoading={isLoading}
                onUpdate={handleNotificationSettingsUpdate}
                onChange={setNotificationSettings}
              />
            )}

            {activeTab === 'social' && (
              <SocialAccountsSettings />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}