import React from 'react';
import { useMessages } from '../hooks/useMessages';
import { MessageSquare, AlertTriangle, Clock, User, BarChart } from 'lucide-react';

interface MessagesListProps {
  platform?: string;
}

export function MessagesList({ platform }: MessagesListProps) {
  const { useMessagesList } = useMessages(platform);
  const { data: messages, isLoading, error } = useMessagesList();

  if (isLoading) return <div>Chargement des messages...</div>;
  if (error) return <div>Erreur de chargement des messages</div>;

  const getPriorityColor = (score: number) => {
    if (score >= 0.8) return 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400';
    if (score >= 0.6) return 'bg-orange-100 text-orange-800 dark:bg-orange-900/30 dark:text-orange-400';
    if (score >= 0.4) return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-400';
    return 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400';
  };

  return (
    <div className="space-y-4">
      {messages?.map((message) => (
        <div
          key={message.id}
          className={`p-4 rounded-lg border ${
            message.priority.score >= 0.8 ? 'border-red-200 dark:border-red-800' :
            'border-gray-200 dark:border-gray-700'
          }`}
        >
          <div className="flex items-start gap-4">
            {/* Indicateur de priorité */}
            <div className={`px-2 py-1 rounded text-sm font-medium ${getPriorityColor(message.priority.score)}`}>
              P{Math.ceil(message.priority.score * 5)}
            </div>

            {/* Contenu du message */}
            <div className="flex-1">
              <p className="text-sm">{message.content}</p>
              
              {/* Métriques de priorité */}
              <div className="mt-2 flex flex-wrap gap-3 text-xs text-gray-500 dark:text-gray-400">
                <div className="flex items-center gap-1">
                  <AlertTriangle className="w-3 h-3" />
                  Urgence: {Math.round(message.priority.factors.urgency * 100)}%
                </div>
                <div className="flex items-center gap-1">
                  <BarChart className="w-3 h-3" />
                  Engagement: {Math.round(message.priority.factors.engagement * 100)}%
                </div>
                <div className="flex items-center gap-1">
                  <User className="w-3 h-3" />
                  Valeur client: {Math.round(message.priority.factors.customerValue * 100)}%
                </div>
                <div className="flex items-center gap-1">
                  <Clock className="w-3 h-3" />
                  Temps d'attente: {Math.round(message.priority.factors.responseTime * 100)}%
                </div>
              </div>

              {/* Métadonnées */}
              <div className="mt-2 flex items-center gap-2 text-xs text-gray-500">
                <span>{message.platform}</span>
                <span>•</span>
                <span>{new Date(message.createdAt).toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
      ))}

      {(!messages || messages.length === 0) && (
        <div className="text-center py-8 text-gray-500">
          Aucun message en attente
        </div>
      )}
    </div>
  );
}