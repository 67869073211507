import React from 'react';
import { Button } from '../../components/ui/button';

export const PlatformFilterSelect = ({
  platforms,
  selected,
  onChange
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      {platforms.map((platform) => (
        <Button
          key={platform.id}
          variant={selected.includes(platform.id) ? "secondary" : "outline"}
          size="sm"
          onClick={() => {
            const newSelected = selected.includes(platform.id)
              ? selected.filter(id => id !== platform.id)
              : [...selected, platform.id];
            onChange(newSelected);
          }}
        >
          <span className="capitalize">{platform.name}</span>
        </Button>
      ))}
    </div>
  );
};
