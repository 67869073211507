import React from 'react';
import { User } from 'lucide-react';

interface Page {
  id: string;
  name: string;
  platform: string;
}

interface PageSelectorProps {
  pages: Page[];
  selectedPageId: string | null;
  onPageSelect: (pageId: string | null) => void;
}

export function PageSelector({ pages, selectedPageId, onPageSelect }: PageSelectorProps) {
  if (!pages.length) return null;

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
        Page
      </label>
      <select
        value={selectedPageId || ''}
        onChange={(e) => onPageSelect(e.target.value || null)}
        className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
      >
        <option value="">Toutes les pages</option>
        {pages.map((page) => (
          <option key={page.id} value={page.id}>
            {page.name}
          </option>
        ))}
      </select>
    </div>
  );
}