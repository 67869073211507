import React, { useState, useEffect } from 'react';
import { Loader2, RefreshCw } from 'lucide-react';
import { useAIGenerator } from './hooks/useAIGenerator';
import { useAISettings } from './hooks/useAISettings';
import { SuggestionCard, PromptInput, SuggestionTabs } from './ui';
import { toast } from 'react-hot-toast';
import type { AISettings } from './types/AITypes';

interface PostSuggestionsProps {
  content: string;
  platforms: string[];
  onSelect: (suggestion: string) => void;
  settings?: Partial<AISettings>;
  context?: string[];
}

export function PostSuggestions({ 
  content, 
  platforms, 
  onSelect,
  settings: initialSettings,
  context = []
}: PostSuggestionsProps) {
  // États locaux
  const [activeTab, setActiveTab] = useState<'content' | 'prompt'>('prompt');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [promptSuggestions, setPromptSuggestions] = useState<string[]>([]);

  // Hooks personnalisés
  const { settings } = useAISettings();
  const { generate, isLoading, error } = useAIGenerator({
    type: 'post',
    onError: (error) => {
      toast.error('Erreur lors de la génération des suggestions');
      console.error('Generation error:', error);
    }
  });

  // Fusionner les paramètres
  const mergedSettings = {
    ...settings,
    ...initialSettings
  };

  // Générer des suggestions basées sur le contenu
  const generateFromContent = async () => {
    if (!content.trim() || platforms.length === 0) return;

    try {
      const response = await generate(content, {
        platform: platforms[0],
        settings: mergedSettings,
        context
      });

      if (response?.suggestions) {
        setSuggestions(response.suggestions);
      }
    } catch (error) {
      console.error('Content generation error:', error);
    }
  };

  // Générer des suggestions à partir d'un prompt
  const handlePromptSubmit = async (prompt: string) => {
    try {
      const response = await generate(prompt, {
        platform: platforms[0],
        settings: mergedSettings,
        context: [...context, content].filter(Boolean)
      });

      if (response?.suggestions) {
        setPromptSuggestions(response.suggestions);
      }
    } catch (error) {
      console.error('Prompt generation error:', error);
    }
  };

  // Regénérer les suggestions
  const handleRegenerate = () => {
    if (activeTab === 'content') {
      generateFromContent();
    } else {
      setPromptSuggestions([]);
    }
  };

  // Gérer le feedback
  const handleFeedback = (suggestion: string, isPositive: boolean) => {
    toast.success(
      isPositive ? 'Merci pour votre retour positif !' : 'Merci pour votre retour !'
    );
  };

  // Générer les suggestions initiales basées sur le contenu
  useEffect(() => {
    if (activeTab === 'content' && content && platforms.length > 0) {
      generateFromContent();
    }
  }, [content, platforms.join(','), activeTab]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
      {/* Tabs de navigation */}
      <SuggestionTabs 
        activeTab={activeTab} 
        onChange={setActiveTab} 
      />

      {/* Contenu principal */}
      <div className="p-4 space-y-4">
        {/* Entrée de prompt */}
        {activeTab === 'prompt' && (
          <PromptInput
            onSubmit={handlePromptSubmit}
            placeholder="Décrivez le type de contenu que vous souhaitez..."
            disabled={platforms.length === 0}
          />
        )}

        {/* En-tête de section */}
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {activeTab === 'prompt' ? 'Suggestions basées sur votre prompt' : 'Suggestions basées sur votre contenu'}
          </h3>
          
          {/* Bouton de régénération */}
          {(activeTab === 'content' || promptSuggestions.length > 0) && (
            <button
              onClick={handleRegenerate}
              disabled={isLoading}
              className="text-sm text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300 flex items-center gap-1"
            >
              <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
              Régénérer
            </button>
          )}
        </div>

        {/* État de chargement */}
        {isLoading && (
          <div className="flex items-center justify-center py-8">
            <Loader2 className="w-6 h-6 animate-spin text-indigo-500" />
          </div>
        )}

        {/* Liste des suggestions */}
        {!isLoading && (
          <div className="space-y-3">
            {(activeTab === 'prompt' ? promptSuggestions : suggestions).map((suggestion, index) => (
              <SuggestionCard
                key={index}
                suggestion={suggestion}
                onSelect={onSelect}
                onFeedback={handleFeedback}
                context={{
                  platform: platforms[0],
                  type: 'post',
                  prompt: activeTab === 'prompt' ? content : undefined
                }}
              />
            ))}

            {/* Message quand il n'y a pas de suggestions */}
            {!isLoading && (activeTab === 'prompt' ? promptSuggestions : suggestions).length === 0 && (
              <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                {activeTab === 'prompt' 
                  ? 'Entrez un prompt pour obtenir des suggestions'
                  : content.trim()
                    ? 'Aucune suggestion disponible'
                    : 'Ajoutez du contenu pour obtenir des suggestions'
                }
              </div>
            )}
          </div>
        )}

        {/* Message d'erreur */}
        {error && (
          <div className="text-center py-4 text-red-600 dark:text-red-400">
            Une erreur est survenue lors de la génération des suggestions
          </div>
        )}
      </div>
    </div>
  );
}