import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export function useProfile() {
  const queryClient = useQueryClient();
  
  const refresh = useCallback(() => {
    queryClient.invalidateQueries(['user']);
  }, [queryClient]);

  return { refresh };
}