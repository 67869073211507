import React, { useState } from 'react';
import { FileText, Plus, Brain } from 'lucide-react';
import { PostsList } from '../components/PostsList';
import { PostEditor } from '../components/PostEditor';
import { GlobalAISettings } from '../components/suggestions/ui/GlobalAISettings';
import { useAISettings } from '../components/suggestions/hooks/useAISettings';

export function Posts() {
  const [showEditor, setShowEditor] = useState(false);
  const [showAISettings, setShowAISettings] = useState(false);
  const [editingPostId, setEditingPostId] = useState<string | null>(null);
  const { settings, updateSettings } = useAISettings();

  return (
    <div className="min-h-screen bg-background">
      <div className="sticky top-0 z-10 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold flex items-center gap-2">
                <FileText className="w-6 h-6 text-primary" />
                Publications
              </h1>
            </div>
            <div className="flex items-center gap-2">
              <button
                variant="ghost"
                size="icon"
                onClick={() => setShowAISettings(true)}
                className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800"
              >
                <Brain className="w-5 h-5" />
              </button>
              <button
                onClick={() => {
                  setEditingPostId(null);
                  setShowEditor(true);
                }}
                className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                <Plus className="w-5 h-5" />
                Nouveau post
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <PostsList />
      </div>

      {/* Modal d'édition */}
      {showEditor && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full">
            <div className="p-6">
              <PostEditor
                mode={editingPostId ? 'edit' : 'create'}
                postId={editingPostId}
                defaultPlatforms={[]}
                onSuccess={() => {
                  setShowEditor(false);
                  setEditingPostId(null);
                }}
                onClose={() => {
                  setShowEditor(false);
                  setEditingPostId(null);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* AI Settings Panel */}
      {showAISettings && (
        <GlobalAISettings
          isOpen={showAISettings}
          onClose={() => setShowAISettings(false)}
          settings={settings}
          onUpdate={updateSettings}
          onSave={() => setShowAISettings(false)}
        />
      )}
    </div>
  );
}