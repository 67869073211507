import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';
import { SocialPlatform } from '../lib/socialMedia';

export function useSocialAccounts() {
  const queryClient = useQueryClient();
  const { user, token } = useAuthStore();

  // Récupération des comptes connectés
  const useAccounts = () => {
    return useQuery({
      queryKey: ['social', 'accounts'],
      queryFn: async () => {
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/social/accounts`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (!response.ok) throw new Error('Failed to fetch accounts');
        return response.json();
      },
      enabled: !!token && !!user?.id,
    });
  };

  // Connexion d'une plateforme
  const connect = useMutation({
    mutationFn: async (platform: SocialPlatform) => {
      if (!user?.id) {
        throw new Error('User not authenticated');
      }

      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/social/connect/${platform}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ userId: user.id }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to connect platform');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['social', 'accounts'] });
    },
  });

  // Déconnexion d'une plateforme
  const disconnect = useMutation({
    mutationFn: async ({ platform, accountId }: { platform: SocialPlatform; accountId: string }) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/social/disconnect/${platform}/${accountId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to disconnect platform');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['social', 'accounts'] });
    },
  });

  return {
    useAccounts,
    connect,
    disconnect,
  };
}