import React from 'react';
import { Search, Calendar, RotateCcw, ArrowUpDown, MessageCircle, Bot, Filter } from 'lucide-react';
import { DateRangePicker } from '../DateRangePicker';
import { useSocialAccounts } from '../../hooks/useSocialAccounts';
import { DateRange, formatDateForUrl, normalizeDateRange } from '../../utils/dateUtils';

interface CommentFiltersProps {
  filters: {
    platform: string;
    pageId?: string | null;
    sentiment: string;
    status: string;
    search: string;
    sortBy: string;
    sortOrder: string;
    hasReplies?: boolean;
    isAiGenerated?: boolean;
    startDate: string | null;
    endDate: string | null;
    page?: number;
  };
  onChange: (key: string, value: any) => void;
  onReset: () => void;
}

export function CommentFilters({ filters, onChange, onReset }: CommentFiltersProps) {
  const { useAccounts } = useSocialAccounts();
  const { data: accounts = [] } = useAccounts();

  const availablePages = React.useMemo(() => {
    if (filters.platform === 'facebook') {
      const account = accounts.find(a => a.platform === 'facebook');
      return account?.pages || [];
    }
    return [];
  }, [accounts, filters.platform]);

  const handleDateRangeChange = (range: DateRange) => {
    // Normaliser la plage de dates
    const normalizedRange = normalizeDateRange(range);
    
    // Réinitialiser la page
    handleFilterChange('page', 1);
    
    // Mettre à jour les dates avec le format ISO
    const startDateString = normalizedRange.start ? formatDateForUrl(normalizedRange.start) : null;
    const endDateString = normalizedRange.end ? formatDateForUrl(normalizedRange.end) : null;

    // Appliquer les changements
    handleFilterChange('startDate', startDateString);
    handleFilterChange('endDate', endDateString);
  };

  const handleFilterChange = (key: string, value: any) => {
    // Réinitialiser la page lors du changement de filtre
    if (key !== 'page') {
      onChange('page', 1);
    }

    // Si on change de plateforme et que ce n'est pas Facebook, réinitialiser pageId
    if (key === 'platform' && value !== 'facebook') {
      onChange('pageId', null);
    }

    onChange(key, value);
  };

  const handleReset = () => {
    onReset();
    onChange('page', 1);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Filter className="w-4 h-4 text-indigo-500" />
          <h2 className="font-medium">Filtres</h2>
        </div>
        <button
          onClick={handleReset}
          className="text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-700 flex items-center gap-1"
        >
          <RotateCcw className="w-4 h-4" />
          Réinitialiser
        </button>
      </div>

      {/* Search */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Recherche
        </label>
        <div className="relative">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
          <input
            type="text"
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target.value)}
            placeholder="Rechercher..."
            className="w-full pl-9 pr-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          />
        </div>
      </div>

      {/* Date Range */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center gap-2">
          <Calendar className="w-4 h-4" />
          Période
        </label>
        <DateRangePicker
          startDate={filters.startDate}
          endDate={filters.endDate}
          onChange={handleDateRangeChange}
        />
      </div>

      {/* Platform */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Plateforme
        </label>
        <select
          value={filters.platform}
          onChange={(e) => handleFilterChange('platform', e.target.value)}
          className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
        >
          <option value="all">Toutes les plateformes</option>
          {accounts.map(account => (
            <option key={account._id} value={account.platform}>
              {account.platform.charAt(0).toUpperCase() + account.platform.slice(1)}
            </option>
          ))}
        </select>
      </div>

      {/* Facebook Pages */}
      {filters.platform === 'facebook' && availablePages.length > 0 && (
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Page Facebook
          </label>
          <select
            value={filters.pageId || ''}
            onChange={(e) => handleFilterChange('pageId', e.target.value || null)}
            className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="">Toutes les pages</option>
            {availablePages.map((page) => (
              <option key={page.id} value={page.id}>
                {page.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Sentiment */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Sentiment
        </label>
        <select
          value={filters.sentiment}
          onChange={(e) => handleFilterChange('sentiment', e.target.value)}
          className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
        >
          <option value="all">Tous les sentiments</option>
          <option value="positive">Positif</option>
          <option value="neutral">Neutre</option>
          <option value="negative">Négatif</option>
        </select>
      </div>

      {/* Status */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Statut
        </label>
        <select
          value={filters.status}
          onChange={(e) => handleFilterChange('status', e.target.value)}
          className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
        >
          <option value="all">Tous les statuts</option>
          <option value="pending">En attente</option>
          <option value="replied">Répondu</option>
          <option value="flagged">Signalé</option>
          <option value="hidden">Masqué</option>
        </select>
      </div>

      {/* Sort */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center gap-2">
          <ArrowUpDown className="w-4 h-4" />
          Trier par
        </label>
        <div className="flex gap-2">
          <select
            value={filters.sortBy}
            onChange={(e) => handleFilterChange('sortBy', e.target.value)}
            className="flex-1 px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="date">Date</option>
            <option value="engagement">Engagement</option>
            <option value="sentiment">Sentiment</option>
          </select>
          <select
            value={filters.sortOrder}
            onChange={(e) => handleFilterChange('sortOrder', e.target.value)}
            className="w-24 px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="desc">↓ Desc</option>
            <option value="asc">↑ Asc</option>
          </select>
        </div>
      </div>
    </div>
  );
}