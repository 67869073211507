import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TeamState {
  selectedTeamId: string | null;
  setSelectedTeam: (teamId: string | null) => void;
}

export const useTeamStore = create<TeamState>()(
  persist(
    (set) => ({
      selectedTeamId: null,
      setSelectedTeam: (teamId) => set({ selectedTeamId: teamId }),
    }),
    {
      name: 'team-storage',
    }
  )
);