import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

export function useTranslatedToast() {
  const { t } = useTranslation('common');

  return {
    success: (key: string, options = {}) => {
      toast.success(t(`toasts.${key}`, options));
    },
    error: (key: string, options = {}) => {
      toast.error(t(`toasts.${key}`, options));
    },
    loading: (key: string, options = {}) => {
      return toast.loading(t(`toasts.${key}`, options));
    }
  };
}