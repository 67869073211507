import { AIResponse, AISettings } from '../types/AITypes';

interface GenerateOptions {
  type: 'post' | 'reply' | 'campaign' | 'analysis';
  platform?: string;
  platforms?: string[];
  context?: string;
  settings?: Partial<AISettings>;
}

export const aiService = {
  async generate(prompt: string, options: GenerateOptions, token: string): Promise<AIResponse> {
    try {
      // Build system prompt based on type and settings
      const systemPrompt = buildSystemPrompt(options);
      const userPrompt = buildUserPrompt(prompt, options);

      const response = await fetch(`${import.meta.env.VITE_API_URL}/ai/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ 
          prompt: userPrompt,
          options: {
            ...options,
            systemPrompt
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate AI response');
      }

      const data = await response.json();

      // Parse suggestions based on type
      let suggestions: string[] = [];
      if (data.content) {
        if (options.type === 'campaign') {
          suggestions = parseCampaignContent(data.content);
        } else {
          suggestions = data.content
            .split(/\d+\.\s+/)
            .map((s: string) => s.trim())
            .filter(Boolean);
        }
      }

      return {
        content: data.content,
        suggestions: suggestions.length > 0 ? suggestions : [data.content],
        confidence: data.confidence || 0.95,
        sentiment: data.sentiment
      };
    } catch (error) {
      console.error('AI generation error:', error);
      throw error;
    }
  },

  async analyzeSentiment(content: string, token: string) {
    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/ai/analyze/sentiment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ content }),
      });

      if (!response.ok) {
        throw new Error('Sentiment analysis failed');
      }

      const data = await response.json();
      return {
        score: data.score,
        label: data.label,
        confidence: data.confidence
      };
    } catch (error) {
      console.error('Sentiment analysis error:', error);
      return {
        score: 0.5,
        label: 'neutral',
        confidence: 0.5
      };
    }
  }
};

function buildSystemPrompt(options: GenerateOptions): string {
  const settings = options.settings || {};
  
  const basePrompt = `Tu es un expert en ${
    options.type === 'campaign' ? 'marketing digital et création de campagnes' :
    options.type === 'reply' ? 'service client et gestion de communauté' :
    'création de contenu'
  } sur les réseaux sociaux.`;

  const styleGuide = `
- Ton: ${settings.tone || 'professional'}
- Style: ${settings.style || options.type}
- Longueur: ${settings.length || 'medium'}
- Créativité: ${settings.creativity ? `${settings.creativity * 100}%` : '70%'}
- Utilisation des hashtags: ${settings.useHashtags ? 'Oui' : 'Non'}
- Utilisation des mentions: ${settings.useMentions ? 'Oui' : 'Non'}
- Utilisation des emojis: ${settings.useEmojis ? 'Oui' : 'Non'}`;

  switch (options.type) {
    case 'campaign':
      return `${basePrompt}${styleGuide}

Format requis pour chaque post:
{
  "content": "Le texte du post",
  "platform": "${options.platform}",
  "type": "image/video/text",
  "mediaPrompt": "Description du visuel à créer (optionnel)",
  "scheduledDate": "Date de publication suggérée"
}`;

    case 'reply':
      return `${basePrompt}${styleGuide}

La réponse doit:
1. Être personnalisée et authentique
2. Répondre directement au problème/question
3. Maintenir un ton positif et constructif
4. Inclure un appel à l'action si approprié`;

    default:
      return `${basePrompt}${styleGuide}`;
  }
}

function buildUserPrompt(prompt: string, options: GenerateOptions): string {
  switch (options.type) {
    case 'campaign':
      return prompt;
    case 'reply':
      return `Génère une réponse à ce message:
"${prompt}"

${options.context ? `\nContexte:\n${options.context}` : ''}`;
    default:
      return prompt;
  }
}

function parseCampaignContent(content: string): string[] {
  try {
    // Extract JSON objects from the content
    const jsonRegex = /\{[\s\S]*?\}/g;
    const matches = content.match(jsonRegex);
    
    if (!matches) return [];

    return matches
      .map(jsonStr => {
        try {
          const post = JSON.parse(jsonStr);
          return post.content;
        } catch (e) {
          console.warn('Failed to parse post:', jsonStr);
          return null;
        }
      })
      .filter(Boolean);
  } catch (error) {
    console.error('Error parsing campaign content:', error);
    return [];
  }
}