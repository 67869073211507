// useAISettings.ts
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AISettings } from '../types/AITypes';

interface AISettingsStore {
  settings: AISettings;
  updateSettings: (updates: Partial<AISettings>) => void;
  resetSettings: () => void;
}

const defaultSettings: AISettings = {
  enabled: true,
  tone: 'professional',
  style: 'informative',
  length: 'short', // Changé à 'short' par défaut
  creativity: 0.7,
  useHashtags: true,
  useMentions: true,
  useEmojis: true,
  autoModerate: true,
  autoRespond: false,
  confidenceThreshold: 0.8
};

export const useAISettings = create<AISettingsStore>()(
  persist(
    (set) => ({
      settings: defaultSettings,
      updateSettings: (updates) => 
        set((state) => ({
          settings: { ...state.settings, ...updates }
        })),
      resetSettings: () => 
        set({ settings: defaultSettings })
    }),
    {
      name: 'ai-settings-storage'
    }
  )
);