import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  LayoutDashboard, 
  FileText,
  Calendar, 
  BarChart2, 
  Settings, 
  LogOut, 
  MessageSquare,
  MessageCircle,
  Globe,
  Sun,
  Moon,
  Users,
  Loader2
} from 'lucide-react';
import { useAuthStore } from '../stores/authStore';
import { useThemeStore } from '../stores/themeStore';
import { useTeamStore } from '../stores/teamStores';
import { NotificationsCenter } from './NotificationsCenter';
import { TeamSelector } from './teams/TeamSelector';
import { useNotifications } from '../hooks/useNotifications';
import { useProtectedRoute } from '../hooks/useProtectedRoute';
import { Toaster } from 'react-hot-toast';
import { getImageUrl } from '../utils/imageUtils';

export function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n, ready } = useTranslation(['app', 'common', 'auth'], {
    useSuspense: false
  });
  const { user, logout } = useAuthStore();
  const { isDark, toggleTheme } = useThemeStore();
  const { selectedTeamId, setSelectedTeam } = useTeamStore();
  const { notifications, markAsRead, clearAll } = useNotifications();

  useProtectedRoute();

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        await i18n.loadNamespaces(['app', 'common', 'auth']);
        document.documentElement.lang = i18n.language;
      } catch (error) {
        console.error('Translation loading error:', error);
      }
    };

    loadTranslations();
  }, [i18n.language]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleLanguage = async () => {
    const newLang = i18n.language === 'fr' ? 'en' : 'fr';
    await i18n.changeLanguage(newLang);
  };

  if (!ready || !user) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-indigo-500" />
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900 transition-colors duration-200">
      <aside className="w-64 border-r border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-800">
        <div className="flex flex-col h-full">
          <div className="p-6 border-b border-gray-200 dark:border-gray-800">
            <div className="flex items-center gap-4">
              {user?.avatar ? (
                <img 
                  src={getImageUrl(user.avatar)} 
                  alt={user.name}
                  className="w-10 h-10 rounded-full object-cover"
                />
              ) : (
                <div className="w-10 h-10 bg-indigo-100 dark:bg-indigo-900/30 rounded-full flex items-center justify-center">
                  <span className="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                    {user.name?.charAt(0)}
                  </span>
                </div>
              )}
              <div>
                <h1 className="text-xl font-bold text-gray-900 dark:text-white">
                  {user.name}
                </h1>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {user.email}
                </p>
              </div>
            </div>
          </div>

          <Toaster position="top-right" />

          <div className="px-4 mb-4">
            <TeamSelector
              selectedTeamId={selectedTeamId}
              onTeamSelect={setSelectedTeam}
            />
          </div>

          <nav className="flex-1 px-4 space-y-1">
            <NavLink to="/app" icon={<LayoutDashboard />} label={t('app:dashboard.title')} active={location.pathname === '/app'} />
            <NavLink to="/app/posts" icon={<FileText />} label={t('app:posts.title')} active={location.pathname === '/app/posts'} />
            <NavLink to="/app/comments" icon={<MessageCircle />} label={t('app:comments.title')} active={location.pathname === '/app/comments'} />
            <NavLink to="/app/messages" icon={<MessageSquare />} label={t('app:messages.title')} active={location.pathname === '/app/messages'} />
            <NavLink to="/app/calendar" icon={<Calendar />} label={t('app:calendar.title')} active={location.pathname === '/app/calendar'} />
            <NavLink to="/app/analytics" icon={<BarChart2 />} label={t('app:analytics.title')} active={location.pathname === '/app/analytics'} />
            <NavLink to="/app/teams" icon={<Users />} label={t('app:teams.title')} active={location.pathname === '/app/teams'} />
            <NavLink to="/app/settings" icon={<Settings />} label={t('app:settings.title')} active={location.pathname === '/app/settings'} />
          </nav>

          <div className="p-4 border-t dark:border-gray-700">
            <button
              onClick={toggleTheme}
              className="flex items-center w-full px-3 py-2 text-sm rounded-md transition-colors duration-200 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <span className="w-5 h-5 mr-3">
                {isDark ? <Sun className="text-yellow-400" /> : <Moon />}
              </span>
              {isDark ? t('common:theme.lightMode') : t('common:theme.darkMode')}
            </button>

            <button
              onClick={toggleLanguage}
              className="flex items-center w-full px-3 py-2 mt-2 text-sm rounded-md transition-colors duration-200 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Globe className="w-5 h-5 mr-3" />
              {i18n.language === 'fr' ? 'English' : 'Français'}
            </button>

            <button
              onClick={handleLogout}
              className="flex items-center w-full px-3 py-2 mt-2 text-sm rounded-md transition-colors duration-200 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <LogOut className="w-5 h-5 mr-3" />
              {t('common:auth.logout')}
            </button>
          </div>
        </div>
      </aside>

      <main className="flex-1 overflow-auto">
        <div className="sticky top-0 z-10 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-end">
            <NotificationsCenter
              notifications={notifications}
              onMarkAsRead={markAsRead}
              onClearAll={clearAll}
            />
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

interface NavLinkProps {
  to: string;
  icon: React.ReactNode;
  label: string;
  active: boolean;
}

function NavLink({ to, icon, label, active }: NavLinkProps) {
  return (
    <Link
      to={to}
      className={`flex items-center px-3 py-2 text-sm rounded-md transition-colors duration-200 ${
        active
          ? 'bg-indigo-50 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400'
          : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
      }`}
    >
      <span className="w-5 h-5 mr-3">{icon}</span>
      {label}
    </Link>
  );
}