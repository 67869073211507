import React, { useState } from 'react';
import { Brain, Calendar, Target, X, Loader2 } from 'lucide-react';
import { CampaignSuggestions } from '../suggestions/CampaignSuggestions';
import { DateRangePicker } from '../DateRangePicker';
import { PlatformSelector } from '../PlatformSelector';
import { PageSelector } from '../PageSelector';
import { useSocialAccounts } from '../../hooks/useSocialAccounts';
import { toast } from 'react-hot-toast';

interface CampaignPlannerProps {
  platforms: string[];
  onPlan: (posts: Array<{
    content: string;
    scheduledDate: Date;
    platform: string;
    pageId?: string;
    media?: Array<{
      type: 'image' | 'video';
      prompt: string;
    }>;
  }>) => Promise<void>;
  onClose: () => void;
}

export function CampaignPlanner({ platforms, onPlan, onClose }: CampaignPlannerProps) {
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>(platforms);
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<{ start: Date | null; end: Date | null }>({
    start: null,
    end: null
  });
  const [frequency, setFrequency] = useState<'low' | 'medium' | 'high'>('medium');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { useAccounts } = useSocialAccounts();
  const { data: accounts = [] } = useAccounts();

  // Get available Facebook pages
  const facebookPages = React.useMemo(() => {
    const fbAccount = accounts.find(a => a.platform === 'facebook');
    return fbAccount?.pages || [];
  }, [accounts]);

  const handlePlan = async (suggestions: any[]) => {
    if (!dateRange.start || !dateRange.end) {
      toast.error('Veuillez sélectionner une période');
      return;
    }

    setIsSubmitting(true);
    try {
      await onPlan(suggestions);
      toast.success('Campagne planifiée avec succès');
      onClose();
    } catch (error) {
      console.error('Campaign planning error:', error);
      toast.error('Erreur lors de la planification de la campagne');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b dark:border-gray-700">
          <h2 className="text-xl font-semibold flex items-center gap-2">
            <Brain className="w-5 h-5 text-indigo-500" />
            Planification de campagne
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {/* Platforms */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Plateformes</label>
            <PlatformSelector
              selectedPlatforms={selectedPlatforms}
              onPlatformChange={setSelectedPlatforms}
            />
          </div>

          {/* Facebook Page */}
          {selectedPlatforms.includes('facebook') && facebookPages.length > 0 && (
            <div className="space-y-2">
              <label className="block text-sm font-medium">Page Facebook</label>
              <PageSelector
                pages={facebookPages}
                selectedPageId={selectedPageId}
                onPageSelect={setSelectedPageId}
              />
            </div>
          )}

          {/* Date Range */}
          <div className="space-y-2">
            <label className="block text-sm font-medium flex items-center gap-2">
              <Calendar className="w-4 h-4" />
              Période de la campagne
            </label>
            <DateRangePicker
              startDate={dateRange.start}
              endDate={dateRange.end}
              onChange={setDateRange}
              minDate={new Date()}
            />
          </div>

          {/* Frequency */}
          <div className="space-y-2">
            <label className="block text-sm font-medium flex items-center gap-2">
              <Target className="w-4 h-4" />
              Fréquence de publication
            </label>
            <div className="grid grid-cols-3 gap-4">
              {[
                { value: 'low', label: 'Faible', desc: '1 post/jour' },
                { value: 'medium', label: 'Moyenne', desc: '2 posts/jour' },
                { value: 'high', label: 'Élevée', desc: '3+ posts/jour' }
              ].map((option) => (
                <button
                  key={option.value}
                  onClick={() => setFrequency(option.value as typeof frequency)}
                  className={`p-4 rounded-lg border-2 text-left ${
                    frequency === option.value
                      ? 'border-indigo-500 bg-indigo-50 dark:bg-indigo-900/30'
                      : 'border-gray-200 dark:border-gray-700'
                  }`}
                >
                  <div className="font-medium">{option.label}</div>
                  <div className="text-sm text-gray-500">{option.desc}</div>
                </button>
              ))}
            </div>
          </div>

          {/* Campaign Suggestions */}
          <CampaignSuggestions
            content=""
            platforms={selectedPlatforms}
            onSelect={() => {}}
            frequency={frequency}
            schedule={dateRange}
            context={[
              selectedPageId ? `Page: ${facebookPages.find(p => p.id === selectedPageId)?.name}` : null,
              `Frequency: ${frequency}`,
              dateRange.start ? `Start: ${dateRange.start.toISOString()}` : null,
              dateRange.end ? `End: ${dateRange.end.toISOString()}` : null
            ].filter(Boolean)}
          />
        </div>

        {/* Footer */}
        <div className="p-6 border-t dark:border-gray-700">
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
            >
              Annuler
            </button>
            <button
              onClick={() => handlePlan([])}
              disabled={isSubmitting || !dateRange.start || !dateRange.end}
              className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isSubmitting ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Calendar className="w-5 h-5" />
              )}
              Planifier
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}