import React from 'react';
import { motion } from 'framer-motion';
import { 
  BookOpen, 
  Layout, 
  MessageSquare, 
  Calendar,
  BarChart2,
  Users,
  Settings,
  Bot,
  Globe,
  Shield
} from 'lucide-react';

export function Documentation() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4 py-12">
        {/* En-tête */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-3xl mx-auto mb-16"
        >
          <h1 className="text-4xl font-bold mb-6 dark:text-white">
            Documentation Acerfi Social Hub
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            Guide complet pour maîtriser la gestion de vos réseaux sociaux
          </p>
        </motion.div>

        {/* Navigation rapide */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm"
          >
            <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
              <Layout className="w-5 h-5 text-indigo-500" />
              Démarrage rapide
            </h2>
            <ul className="space-y-2 text-gray-600 dark:text-gray-300">
              <li>• Configuration initiale</li>
              <li>• Connexion des comptes</li>
              <li>• Premiers pas</li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm"
          >
            <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
              <Bot className="w-5 h-5 text-indigo-500" />
              Fonctionnalités IA
            </h2>
            <ul className="space-y-2 text-gray-600 dark:text-gray-300">
              <li>• Suggestions de contenu</li>
              <li>• Réponses automatiques</li>
              <li>• Analyse de sentiment</li>
            </ul>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm"
          >
            <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
              <Shield className="w-5 h-5 text-indigo-500" />
              Sécurité
            </h2>
            <ul className="space-y-2 text-gray-600 dark:text-gray-300">
              <li>• Gestion des accès</li>
              <li>• Protection des données</li>
              <li>• Bonnes pratiques</li>
            </ul>
          </motion.div>
        </div>

        {/* Documentation détaillée */}
        <div className="space-y-12">
          {/* Section Tableau de bord */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-sm"
          >
            <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
              <Layout className="w-6 h-6 text-indigo-500" />
              Tableau de bord
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p>
                Le tableau de bord est votre centre de contrôle principal. Il vous permet de :
              </p>
              <ul>
                <li>Visualiser les métriques clés de tous vos réseaux sociaux</li>
                <li>Accéder rapidement à vos dernières publications</li>
                <li>Suivre l'engagement de votre audience</li>
                <li>Gérer vos tâches en attente</li>
              </ul>
              <p>
                Les widgets sont personnalisables et peuvent être réorganisés selon vos préférences.
              </p>
            </div>
          </motion.section>

          {/* Section Publications */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-sm"
          >
            <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
              <MessageSquare className="w-6 h-6 text-indigo-500" />
              Gestion des publications
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p>
                Créez et gérez vos publications sur toutes les plateformes depuis une interface unique :
              </p>
              <ul>
                <li>Éditeur multi-plateformes avec prévisualisation en temps réel</li>
                <li>Planification avancée avec suggestions d'horaires optimaux</li>
                <li>Gestion des médias avec bibliothèque centralisée</li>
                <li>Suggestions de contenu alimentées par l'IA</li>
              </ul>
              <p>
                L'IA analyse votre audience et suggère le meilleur contenu pour maximiser l'engagement.
              </p>
            </div>
          </motion.section>

          {/* Section Calendrier */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-sm"
          >
            <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
              <Calendar className="w-6 h-6 text-indigo-500" />
              Planification et calendrier
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p>
                Le calendrier éditorial vous offre une vue d'ensemble de votre stratégie de contenu :
              </p>
              <ul>
                <li>Vue mensuelle, hebdomadaire et quotidienne</li>
                <li>Planification par glisser-déposer</li>
                <li>Analyse des meilleurs moments pour publier</li>
                <li>Gestion des campagnes marketing</li>
              </ul>
              <p>
                L'IA vous aide à optimiser vos horaires de publication en fonction de l'engagement historique.
              </p>
            </div>
          </motion.section>

          {/* Section Analytics */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
            className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-sm"
          >
            <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
              <BarChart2 className="w-6 h-6 text-indigo-500" />
              Analyses et rapports
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p>
                Suivez vos performances avec des analyses détaillées :
              </p>
              <ul>
                <li>Métriques d'engagement par plateforme</li>
                <li>Analyse du sentiment de votre audience</li>
                <li>Rapports personnalisables et exportables</li>
                <li>Suggestions d'amélioration basées sur l'IA</li>
              </ul>
              <p>
                Les rapports automatisés vous permettent de suivre facilement vos KPIs.
              </p>
            </div>
          </motion.section>

          {/* Section Équipes */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-sm"
          >
            <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
              <Users className="w-6 h-6 text-indigo-500" />
              Gestion d'équipe
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p>
                Collaborez efficacement avec votre équipe :
              </p>
              <ul>
                <li>Gestion des rôles et permissions</li>
                <li>Workflow de validation</li>
                <li>Commentaires et annotations</li>
                <li>Historique des modifications</li>
              </ul>
              <p>
                Le système de permissions flexible s'adapte à votre structure organisationnelle.
              </p>
            </div>
          </motion.section>

          {/* Section Paramètres */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.9 }}
            className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-sm"
          >
            <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
              <Settings className="w-6 h-6 text-indigo-500" />
              Configuration et intégrations
            </h2>
            <div className="prose dark:prose-invert max-w-none">
              <p>
                Personnalisez la plateforme selon vos besoins :
              </p>
              <ul>
                <li>Connexion des comptes sociaux</li>
                <li>Configuration des notifications</li>
                <li>Paramètres de l'IA</li>
                <li>Intégrations tierces</li>
              </ul>
              <p>
                L'interface intuitive facilite la configuration de tous les aspects de la plateforme.
              </p>
            </div>
          </motion.section>
        </div>

        {/* FAQ */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
          className="mt-12"
        >
          <h2 className="text-2xl font-bold mb-8 text-center">Questions fréquentes</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <h3 className="font-semibold mb-2">Comment connecter mes comptes sociaux ?</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Accédez aux paramètres, sélectionnez "Comptes sociaux" et suivez les étapes d'authentification pour chaque plateforme.
              </p>
            </div>

            <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <h3 className="font-semibold mb-2">Comment fonctionne l'IA ?</h3>
              <p className="text-gray-600 dark:text-gray-300">
                L'IA analyse vos données historiques et les tendances pour suggérer du contenu pertinent et optimiser vos publications.
              </p>
            </div>

            <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <h3 className="font-semibold mb-2">Comment gérer les permissions ?</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Dans la section Équipes, vous pouvez définir des rôles personnalisés et attribuer des permissions spécifiques à chaque membre.
              </p>
            </div>

            <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-sm">
              <h3 className="font-semibold mb-2">Comment exporter mes données ?</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Utilisez la fonction d'export dans la section Analytics pour télécharger vos rapports au format CSV ou PDF.
              </p>
            </div>
          </div>
        </motion.section>

        {/* Support */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.1 }}
          className="mt-12 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Besoin d'aide ?</h2>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Notre équipe de support est là pour vous aider
          </p>
          <div className="flex justify-center gap-4">
            <a
              href="mailto:support@acerfi.net"
              className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Contacter le support
            </a>
          </div>
        </motion.section>
      </div>
    </div>
  );
}