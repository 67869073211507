import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Activity, CheckCircle, AlertTriangle, XCircle, RefreshCw, Loader2 } from 'lucide-react';

interface ServiceStatus {
  name: string;
  status: 'operational' | 'degraded' | 'down';
  uptime: number;
  latency: number;
  lastIncident?: string;
}

export function Status() {
  const { t } = useTranslation('pages');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [lastUpdate] = useState(new Date());
  const [services] = useState<ServiceStatus[]>([
    {
      name: 'API',
      status: 'operational',
      uptime: 99.99,
      latency: 45,
    },
    {
      name: 'Web Application',
      status: 'operational',
      uptime: 99.95,
      latency: 120,
    },
    {
      name: 'Database',
      status: 'operational',
      uptime: 99.99,
      latency: 15,
    },
    {
      name: 'Social Media Integrations',
      status: 'operational',
      uptime: 99.90,
      latency: 250,
    }
  ]);

  const handleRefresh = () => {
    setIsRefreshing(true);
    setTimeout(() => setIsRefreshing(false), 1000);
  };

  const getStatusConfig = (status: ServiceStatus['status']) => {
    switch (status) {
      case 'operational':
        return {
          icon: CheckCircle,
          color: 'text-green-500',
          bg: 'bg-green-100 dark:bg-green-900/30',
          text: t('status.status.operational')
        };
      case 'degraded':
        return {
          icon: AlertTriangle,
          color: 'text-yellow-500',
          bg: 'bg-yellow-100 dark:bg-yellow-900/30',
          text: t('status.status.degraded')
        };
      case 'down':
        return {
          icon: XCircle,
          color: 'text-red-500',
          bg: 'bg-red-100 dark:bg-red-900/30',
          text: t('status.status.down')
        };
      default:
        return {
          icon: CheckCircle,
          color: 'text-gray-500',
          bg: 'bg-gray-100 dark:bg-gray-900/30',
          text: 'Unknown'
        };
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-3xl mx-auto mb-16"
        >
          <h1 className="text-4xl font-bold mb-6 dark:text-white">
            {t('status.title')}
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            {t('status.subtitle')}
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="max-w-4xl mx-auto"
        >
          {/* Header with refresh button */}
          <div className="flex justify-between items-center mb-8">
            <p className="text-gray-600 dark:text-gray-400">
              {t('status.lastUpdate')}: {lastUpdate.toLocaleTimeString()}
            </p>
            <button
              onClick={handleRefresh}
              disabled={isRefreshing}
              className="flex items-center gap-2 px-4 py-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
            >
              {isRefreshing ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <RefreshCw className="w-5 h-5" />
              )}
              {t('status.refresh')}
            </button>
          </div>

          {/* Services Grid */}
          <div className="grid gap-6 mb-12">
            {services.map((service) => {
              const config = getStatusConfig(service.status);
              const Icon = config.icon;

              return (
                <div
                  key={service.name}
                  className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6"
                >
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="font-semibold">{service.name}</h3>
                    <span className={`px-3 py-1 rounded-full text-sm ${config.bg} ${config.color}`}>
                      <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {config.text}
                      </div>
                    </span>
                  </div>

                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                        {t('status.metrics.uptime')}
                      </p>
                      <p className="font-semibold">{service.uptime}%</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                        {t('status.metrics.latency')}
                      </p>
                      <p className="font-semibold">{service.latency}ms</p>
                    </div>
                    {service.lastIncident && (
                      <div>
                        <p className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                          {t('status.metrics.lastIncident')}
                        </p>
                        <p className="font-semibold">{service.lastIncident}</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Incident History */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
            <h2 className="text-xl font-semibold mb-6">
              {t('status.incidents.title')}
            </h2>
            <div className="text-center py-8 text-gray-500 dark:text-gray-400">
              {t('status.incidents.noIncidents')}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}