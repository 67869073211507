import React, { useState, useCallback, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import { Post } from '../../lib/posts';
import { PostEditor } from '../PostEditor';
import { OptimalTimesAnalysis } from '../OptimalTimesAnalysis';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Calendar as CalendarIcon, Clock, Grid, Calendar } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface CalendarViewProps {
  posts: Post[];
  platforms: string[];
  selectedPlatform?: string;
  selectedPageId?: string;
  onPostUpdate: (postId: string, updates: Partial<Post>) => Promise<void>;
  onPostCreate: (post: Partial<Post>) => Promise<void>;
}

type CalendarView = 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay' | 'multiMonthYear' | 'multiMonth3';

export function CalendarView({
  posts,
  platforms,
  selectedPlatform,
  selectedPageId,
  onPostUpdate,
  onPostCreate
}: CalendarViewProps) {
  const [showEditor, setShowEditor] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [editingPost, setEditingPost] = useState<Post | null>(null);
  const [currentView, setCurrentView] = useState<CalendarView>('dayGridMonth');
  const [showOptimalTimes, setShowOptimalTimes] = useState(false);
  const calendarRef = useRef<any>(null);

  // Convertir les posts en événements pour le calendrier
  const events = posts.map(post => ({
    id: post._id,
    title: post.content.substring(0, 50) + (post.content.length > 50 ? '...' : ''),
    start: post.scheduledDate,
    end: new Date(new Date(post.scheduledDate!).getTime() + 30 * 60000), // +30 minutes
    extendedProps: {
      post,
      platform: post.platform,
      pageId: post.metadata?.pageId
    },
    className: `platform-${post.platform} ${post.status === 'failed' ? 'bg-red-500' : 'bg-indigo-500'}`
  }));

  const handleViewChange = useCallback((viewInfo: any) => {
    setCurrentView(viewInfo.view.type as CalendarView);
    localStorage.setItem('preferredCalendarView', viewInfo.view.type);
  }, []);

  const handleDateSelect = useCallback((selectInfo: any) => {
    setSelectedDate(selectInfo.start);
    setShowEditor(true);
  }, []);

  const handleEventClick = useCallback((clickInfo: any) => {
    const post = clickInfo.event.extendedProps.post;
    setEditingPost(post);
    setShowEditor(true);
  }, []);

  const handleEventDrop = useCallback(async (dropInfo: any) => {
    const postId = dropInfo.event.id;
    const newDate = dropInfo.event.start;

    try {
      await onPostUpdate(postId, { scheduledDate: newDate });
      toast.success('Publication reprogrammée');
    } catch (error) {
      console.error('Failed to reschedule post:', error);
      toast.error('Erreur lors de la reprogrammation');
      dropInfo.revert();
    }
  }, [onPostUpdate]);

  const handleEditorClose = () => {
    setShowEditor(false);
    setEditingPost(null);
    setSelectedDate(null);
  };

  const handleEditorSuccess = () => {
    handleEditorClose();
  };

  const handleOptimalTimeSelect = (selectedDate: Date) => {
    const selectInfo = {
      start: selectedDate,
      end: new Date(selectedDate.getTime() + 30 * 60000), // +30 minutes
    };
    handleDateSelect(selectInfo);
  };

  const changeView = (viewType: CalendarView) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.changeView(viewType);
      setCurrentView(viewType);
    }
  };

  const viewButtons = [
    { type: 'timeGridDay', label: 'Jour', icon: <Clock className="w-4 h-4" /> },
    { type: 'timeGridWeek', label: 'Semaine', icon: <Grid className="w-4 h-4" /> },
    { type: 'dayGridMonth', label: 'Mois', icon: <Calendar className="w-4 h-4" /> },
    { type: 'multiMonth3', label: 'Trimestre', icon: <CalendarIcon className="w-4 h-4" /> },
    { type: 'multiMonthYear', label: 'Année', icon: <CalendarIcon className="w-4 h-4" /> },
  ] as const;

  return (
    <div className="h-full">
      {/* Barre d'outils */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2 bg-white dark:bg-gray-800 p-1 rounded-lg shadow-sm">
          {viewButtons.map(({ type, label, icon }) => (
            <button
              key={type}
              onClick={() => changeView(type)}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                currentView === type
                  ? 'bg-indigo-500 text-white'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              {icon}
              <span className="hidden sm:inline">{label}</span>
            </button>
          ))}
        </div>

        {/* Bouton horaires optimaux */}
        <button
          onClick={() => setShowOptimalTimes(true)}
          className="flex items-center gap-2 px-4 py-2 text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/30 rounded-lg"
        >
          <Clock className="w-4 h-4" />
          Horaires optimaux
        </button>
      </div>

      {/* Calendrier */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
        <div className="h-[calc(100vh-300px)]">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin, interactionPlugin]}
            initialView={localStorage.getItem('preferredCalendarView') as CalendarView || 'dayGridMonth'}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: ''
            }}
            views={{
              timeGridWeek: {
                titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
              },
              timeGridDay: {
                titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
              },
              multiMonthYear: {
                duration: { years: 1 },
                multiMonthMaxColumns: 3,
                multiMonthMinWidth: 350
              },
              multiMonth3: {
                type: 'multiMonth',
                duration: { months: 3 },
                multiMonthMaxColumns: 3,
                multiMonthMinWidth: 350
              }
            }}
            locale="fr"
            events={events}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            select={handleDateSelect}
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            viewDidMount={handleViewChange}
            allDaySlot={false}
            slotMinTime="06:00:00"
            slotMaxTime="22:00:00"
            slotDuration="00:30:00"
            scrollTime="08:00:00"
            height="100%"
            eventContent={(eventInfo) => (
              <div className="p-1">
                <div className="text-xs font-medium">
                  {format(eventInfo.event.start!, 'HH:mm')}
                </div>
                <div className="text-xs truncate">
                  {eventInfo.event.title}
                </div>
                <div className="text-xs opacity-75 capitalize">
                  {eventInfo.event.extendedProps.platform}
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {/* Éditeur de post */}
      {showEditor && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-hidden flex flex-col">
            <div className="p-6 overflow-y-auto flex-1">
              <PostEditor
                mode="schedule"
                postId={editingPost?._id}
                defaultPlatforms={editingPost ? [editingPost.platform] : selectedPlatform ? [selectedPlatform] : platforms}
                defaultScheduledDate={selectedDate || undefined}
                onSuccess={handleEditorSuccess}
                onClose={handleEditorClose}
              />
            </div>
          </div>
        </div>
      )}

      {/* Analyse des horaires optimaux */}
      {showOptimalTimes && (
        <OptimalTimesAnalysis
          platforms={platforms}
          onClose={() => setShowOptimalTimes(false)}
          onTimeSelect={handleOptimalTimeSelect}
        />
      )}
    </div>
  );
}