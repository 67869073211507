import api from '../lib/apiClient';
import { useAuthStore } from '../stores/authStore';

interface LoginCredentials {
  email: string;
  password: string;
}

interface RegisterData extends LoginCredentials {
  name: string;
}

interface AuthResponse {
  token: string;
  user: {
    id: string;
    email: string;
    name: string;
    avatar?: string;
    facebookId?: string;
  };
}

class AuthService {
  private static instance: AuthService;
  private store = useAuthStore;

  private constructor() {}

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  async login(credentials: LoginCredentials): Promise<void> {
    try {
      const { data } = await api.post<AuthResponse>('/auth/login', credentials);
      await this.store.getState().setAuth(data.user, data.token);
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async register(userData: RegisterData): Promise<void> {
    try {
      const { data } = await api.post<AuthResponse>('/auth/register', userData);
      await this.store.getState().setAuth(data.user, data.token);
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  }

  async validateToken(): Promise<boolean> {
    try {
      const token = this.store.getState().token;
      if (!token) return false;

      const { data } = await api.get('/auth/validate');
      return data.valid;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  }

  logout(): void {
    this.store.getState().logout();
  }

  getAuthHeaders(): Record<string, string> {
    const token = this.store.getState().token;
    return token ? {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    } : {
      'Content-Type': 'application/json'
    };
  }
}

export const authService = AuthService.getInstance();