import React from 'react';
import { motion } from 'framer-motion';
import { FileText } from 'lucide-react';

export function Terms() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4 py-20">
        <div className="max-w-3xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <FileText className="w-12 h-12 text-indigo-600 mx-auto mb-4" />
            <h1 className="text-4xl font-bold mb-4 dark:text-white">
              Conditions générales d'utilisation
            </h1>
            <p className="text-gray-600 dark:text-gray-300">
              Dernière mise à jour : {new Date().toLocaleDateString()}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="prose prose-lg max-w-none dark:prose-invert"
          >
            <h2>1. Acceptation des conditions</h2>
            <p>
              En accédant et en utilisant Acerfi Social Hub, vous acceptez d'être lié par ces conditions d'utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre service.
            </p>

            <h2>2. Description du service</h2>
            <p>
              Acerfi Social Hub est une plateforme de gestion des réseaux sociaux qui permet aux utilisateurs de planifier, publier et analyser leur contenu sur différentes plateformes sociales.
            </p>

            <h2>3. Comptes utilisateurs</h2>
            <p>
              Pour utiliser nos services, vous devez :
            </p>
            <ul>
              <li>Créer un compte avec des informations exactes</li>
              <li>Maintenir la sécurité de votre compte</li>
              <li>Notifier immédiatement toute violation de sécurité</li>
            </ul>

            <h2>4. Abonnements et paiements</h2>
            <p>
              Les conditions suivantes s'appliquent aux abonnements :
            </p>
            <ul>
              <li>Les prix sont facturés mensuellement ou annuellement</li>
              <li>Le renouvellement est automatique</li>
              <li>L'annulation est possible à tout moment</li>
              <li>Les remboursements sont traités au cas par cas</li>
            </ul>

            <h2>5. Propriété intellectuelle</h2>
            <p>
              Tous les droits de propriété intellectuelle liés à notre service sont la propriété d'Acerfi Social Hub. Vous ne pouvez pas copier, modifier, distribuer ou utiliser notre contenu sans autorisation.
            </p>

            <h2>6. Limitation de responsabilité</h2>
            <p>
              Acerfi Social Hub n'est pas responsable :
            </p>
            <ul>
              <li>Des interruptions de service</li>
              <li>Des pertes de données</li>
              <li>Des dommages indirects</li>
              <li>Du contenu publié par les utilisateurs</li>
            </ul>

            <h2>7. Modifications des conditions</h2>
            <p>
              Nous nous réservons le droit de modifier ces conditions à tout moment. Les modifications entrent en vigueur dès leur publication sur notre site.
            </p>

            <h2>8. Contact</h2>
            <p>
              Pour toute question concernant ces conditions, contactez-nous à :
            </p>
            <ul>
              <li>Email : info@acerfi.net</li>
              <li>Téléphone : +237 695 08 08 08</li>
            </ul>
          </motion.div>
        </div>
      </div>
    </div>
  );
}