import { AIContentAnalysis, AIContentPolicy, AIContentSuggestion } from './types';
import { generateAIResponse } from '../ai';

class ContentManager {
  private policy: AIContentPolicy;

  constructor(policy: AIContentPolicy) {
    this.policy = policy;
  }

  async analyzeContent(content: string): Promise<AIContentAnalysis> {
    try {
      const response = await generateAIResponse(content, {
        type: 'analysis',
        context: 'content_moderation'
      });

      return {
        sentiment: response.sentiment,
        topics: response.topics,
        keywords: response.keywords,
        toxicity: response.toxicity,
        engagement: response.engagement
      };
    } catch (error) {
      console.error('Content analysis error:', error);
      throw error;
    }
  }

  async shouldModerate(analysis: AIContentAnalysis): Promise<{
    shouldRemove: boolean;
    shouldHide: boolean;
    reasons: string[];
  }> {
    const reasons: string[] = [];

    // Vérifier la toxicité
    if (analysis.toxicity.score > this.policy.toxicityThreshold) {
      reasons.push('Contenu potentiellement toxique');
    }

    // Vérifier le sentiment
    if (analysis.sentiment.score < this.policy.sentimentThreshold) {
      reasons.push('Sentiment trop négatif');
    }

    // Vérifier les sujets interdits
    const hasBannedTopic = analysis.topics.some(topic => 
      this.policy.bannedTopics.includes(topic.toLowerCase())
    );
    if (hasBannedTopic) {
      reasons.push('Sujet interdit détecté');
    }

    // Décision de modération
    const shouldRemove = analysis.toxicity.score > this.policy.toxicityThreshold * 1.5;
    const shouldHide = reasons.length > 0 && !shouldRemove;

    return {
      shouldRemove,
      shouldHide,
      reasons
    };
  }

  async generateResponse(
    content: string,
    context: string[],
    platform: string
  ): Promise<AIContentSuggestion> {
    try {
      const response = await generateAIResponse(content, {
        type: 'reply',
        context: context.join('\n'),
        platform
      });

      return {
        content: response.content,
        confidence: response.confidence,
        platform,
        hashtags: response.hashtags
      };
    } catch (error) {
      console.error('Response generation error:', error);
      throw error;
    }
  }
}

export const contentManager = new ContentManager({
  toxicityThreshold: 0.7,
  sentimentThreshold: 0.3,
  bannedTopics: ['spam', 'hate', 'violence', 'nsfw'],
  requiredDisclosures: ['sponsored', 'affiliate'],
  autoModeration: true
});