import React from 'react';
import { Save, Loader2 } from 'lucide-react';

interface EmailSettingsProps {
  emailSettings: {
    provider: string;
    fromEmail: string;
    sendgridApiKey: string;
    gmailUser: string;
    gmailAppPassword: string;
    office365User: string;
    office365Password: string;
  };
  isLoading: boolean;
  onUpdate: (data: any) => Promise<void>;
  onChange: (data: any) => void;
}

export function EmailSettings({ emailSettings, isLoading, onUpdate, onChange }: EmailSettingsProps) {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onUpdate(emailSettings);
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <h2 className="text-lg font-medium">Configuration Email</h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-2">
            Service d'envoi d'email
          </label>
          <select
            value={emailSettings.provider}
            onChange={(e) => onChange({ ...emailSettings, provider: e.target.value })}
            className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="sendgrid">SendGrid</option>
            <option value="gmail">Gmail</option>
            <option value="office365">Office 365</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">
            Email d'envoi
          </label>
          <input
            type="email"
            value={emailSettings.fromEmail}
            onChange={(e) => onChange({ ...emailSettings, fromEmail: e.target.value })}
            className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
          />
        </div>

        {emailSettings.provider === 'sendgrid' && (
          <div>
            <label className="block text-sm font-medium mb-2">
              Clé API SendGrid
            </label>
            <input
              type="password"
              value={emailSettings.sendgridApiKey}
              onChange={(e) => onChange({ ...emailSettings, sendgridApiKey: e.target.value })}
              className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
            />
          </div>
        )}

        {emailSettings.provider === 'gmail' && (
          <>
            <div>
              <label className="block text-sm font-medium mb-2">
                Compte Gmail
              </label>
              <input
                type="email"
                value={emailSettings.gmailUser}
                onChange={(e) => onChange({ ...emailSettings, gmailUser: e.target.value })}
                className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Mot de passe d'application
              </label>
              <input
                type="password"
                value={emailSettings.gmailAppPassword}
                onChange={(e) => onChange({ ...emailSettings, gmailAppPassword: e.target.value })}
                className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
              />
            </div>
          </>
        )}

        {emailSettings.provider === 'office365' && (
          <>
            <div>
              <label className="block text-sm font-medium mb-2">
                Compte Office 365
              </label>
              <input
                type="email"
                value={emailSettings.office365User}
                onChange={(e) => onChange({ ...emailSettings, office365User: e.target.value })}
                className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">
                Mot de passe
              </label>
              <input
                type="password"
                value={emailSettings.office365Password}
                onChange={(e) => onChange({ ...emailSettings, office365Password: e.target.value })}
                className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
              />
            </div>
          </>
        )}
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isLoading ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          Enregistrer
        </button>
      </div>
    </form>
  );
}