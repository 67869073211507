import React from 'react';
import { Check, HelpCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const plans = [
  {
    name: 'Starter',
    price: '19',
    description: 'Parfait pour les indépendants et les petites entreprises',
    features: [
      '3 réseaux sociaux',
      '100 posts programmés',
      'Analytics de base',
      'Support email',
      'IA basique'
    ],
    cta: 'Commencer gratuitement',
    popular: false
  },
  {
    name: 'Professional',
    price: '49',
    description: 'Idéal pour les entreprises en croissance',
    features: [
      '10 réseaux sociaux',
      'Posts illimités',
      'Analytics avancés',
      'Support prioritaire',
      'IA avancée',
      'Réponses automatiques',
      'Collaboration en équipe'
    ],
    cta: 'Commencer l\'essai gratuit',
    popular: true
  },
  {
    name: 'Enterprise',
    price: '99',
    description: 'Pour les grandes entreprises avec des besoins spécifiques',
    features: [
      'Réseaux sociaux illimités',
      'Posts illimités',
      'Analytics personnalisés',
      'Support dédié 24/7',
      'IA personnalisée',
      'API access',
      'Formation personnalisée',
      'SLA garanti'
    ],
    cta: 'Contacter les ventes',
    popular: false
  }
];

export function Pricing() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 py-20">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-16">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4 dark:text-white"
          >
            Des prix simples et transparents
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-xl text-gray-600 dark:text-gray-300"
          >
            Choisissez le plan qui correspond à vos besoins
          </motion.p>
        </div>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {plans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              className={`relative rounded-2xl bg-white dark:bg-gray-800 p-8 shadow-lg ${
                plan.popular ? 'ring-2 ring-indigo-600' : ''
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                  <span className="bg-indigo-600 text-white text-sm font-medium px-3 py-1 rounded-full">
                    Le plus populaire
                  </span>
                </div>
              )}

              <div className="text-center">
                <h3 className="text-xl font-semibold mb-2 dark:text-white">{plan.name}</h3>
                <div className="flex items-baseline justify-center gap-1">
                  <span className="text-4xl font-bold dark:text-white">{plan.price}</span>
                  <span className="text-gray-600 dark:text-gray-400">€/mois</span>
                </div>
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{plan.description}</p>
              </div>

              <ul className="mt-8 space-y-4">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-start gap-3">
                    <Check className="w-5 h-5 text-green-500 flex-shrink-0" />
                    <span className="text-gray-700 dark:text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>

              <div className="mt-8">
                <Link
                  to={plan.name === 'Enterprise' ? '/contact' : '/signup'}
                  className={`block w-full text-center py-3 px-6 rounded-lg font-medium ${
                    plan.popular
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600'
                  }`}
                >
                  {plan.cta}
                </Link>
              </div>
            </motion.div>
          ))}
        </div>

        {/* FAQ Section */}
        <div className="mt-20">
          <h2 className="text-3xl font-bold text-center mb-12 dark:text-white">
            Questions fréquentes
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm"
            >
              <div className="flex items-center gap-2 mb-4">
                <HelpCircle className="w-5 h-5 text-indigo-600" />
                <h3 className="font-semibold dark:text-white">
                  Puis-je changer de plan à tout moment ?
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                Oui, vous pouvez changer de plan à tout moment. La différence sera calculée au prorata.
              </p>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm"
            >
              <div className="flex items-center gap-2 mb-4">
                <HelpCircle className="w-5 h-5 text-indigo-600" />
                <h3 className="font-semibold dark:text-white">
                  Y a-t-il un engagement minimum ?
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                Non, tous nos plans sont sans engagement. Vous pouvez annuler à tout moment.
              </p>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm"
            >
              <div className="flex items-center gap-2 mb-4">
                <HelpCircle className="w-5 h-5 text-indigo-600" />
                <h3 className="font-semibold dark:text-white">
                  Comment fonctionne l'essai gratuit ?
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                Vous bénéficiez de 14 jours d'essai gratuit avec toutes les fonctionnalités du plan Professional.
              </p>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm"
            >
              <div className="flex items-center gap-2 mb-4">
                <HelpCircle className="w-5 h-5 text-indigo-600" />
                <h3 className="font-semibold dark:text-white">
                  Proposez-vous des remises pour les ONG ?
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-300">
                Oui, nous proposons des remises spéciales pour les ONG et les organisations à but non lucratif.
              </p>
            </motion.div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-20 text-center">
          <h2 className="text-2xl font-bold mb-4 dark:text-white">
            Vous avez d'autres questions ?
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-8">
            Notre équipe est là pour vous aider
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center justify-center px-8 py-3 text-base font-semibold text-white duration-300 bg-indigo-600 rounded-lg hover:bg-indigo-700"
          >
            Contactez-nous
          </Link>
        </div>
      </div>
    </div>
  );
}