import { useEffect, useCallback } from 'react';
import { useAuthStore } from '../stores/authStore';
import { toast } from 'react-hot-toast';

export function useModerationWebhook() {
  const { user, token } = useAuthStore();

  const moderateContent = useCallback(async (type: 'post' | 'comment', id: string) => {
    if (!user || !token) return;

    const ws = new WebSocket(`${import.meta.env.VITE_API_URL.replace('http', 'ws')}/ws/moderation`);
    
    ws.onopen = () => {
      ws.send(JSON.stringify({
        type: `moderate_${type}`,
        [`${type}Id`]: id
      }));
    };

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        
        if (data.type === 'moderation_result' && data.wasModerated) {
          const action = data.status === 'removed' ? 'supprimé' : 'masqué';
          toast.error(`Le contenu a été ${action} pour non-respect des règles de la communauté`);
        }
      } catch (error) {
        console.error('Moderation webhook error:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('Moderation WebSocket error:', error);
      toast.error('Erreur lors de la modération du contenu');
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, [user, token]);

  return { moderateContent };
}