import React, { useState } from 'react';
import { Search, Loader2 } from 'lucide-react';

interface PromptInputProps {
  onSubmit: (prompt: string) => Promise<void>;
  placeholder?: string;
  disabled?: boolean;
}

export function PromptInput({ onSubmit, placeholder, disabled }: PromptInputProps) {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!prompt.trim() || isLoading || disabled) return;

    setIsLoading(true);
    try {
      await onSubmit(prompt);
      setPrompt('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative flex items-center">
      <input
        type="text"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder={placeholder || "Entrez votre prompt..."}
        className="w-full pl-4 pr-12 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
        disabled={isLoading || disabled}
      />
      <button
        type="submit"
        disabled={isLoading || !prompt.trim() || disabled}
        className="absolute right-2 p-2 text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/30 rounded-md disabled:opacity-50"
      >
        {isLoading ? (
          <Loader2 className="w-5 h-5 animate-spin" />
        ) : (
          <Search className="w-5 h-5" />
        )}
      </button>
    </form>
  );
}