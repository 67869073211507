import React, { useState, useRef } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../ui/tabs';
import { PlatformIcon } from './PlatformIcon';
import { Loader2, Send, Sparkles, Smile } from 'lucide-react';
import { MediaUploader } from '../../MediaUploader';
import { EmojiPicker } from '../../EmojiPicker';
import { cn } from '../../../lib/utils';

interface PlatformContent {
  platform: string;
  content: string;
  hashtags: string[];
}

interface MultiPlatformEditorProps {
  platforms: string[];
  contents: PlatformContent[];
  media: Array<{ url: string; type: 'image' | 'video' }>;
  onMediaUpload: (media: Array<{ url: string; type: 'image' | 'video' }>) => void;
  onContentChange: (platform: string, content: string) => void;
  onPublish: (platform?: string) => Promise<void>;
  isPublishing?: boolean;
  onRequestSuggestions?: () => void;
}

export function MultiPlatformEditor({
  platforms,
  contents = [],
  media,
  onMediaUpload,
  onContentChange,
  onPublish,
  isPublishing = false,
  onRequestSuggestions
}: MultiPlatformEditorProps) {
  const [activeTab, setActiveTab] = useState(platforms[0] || '');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const textareaRefs = useRef<{ [key: string]: HTMLTextAreaElement | null }>({});

  const handleContentChange = (platform: string, newContent: string) => {
    onContentChange(platform, newContent);
  };

  const handleEmojiSelect = (emoji: string) => {
    const textareaRef = textareaRefs.current[activeTab];
    if (textareaRef) {
      const start = textareaRef.selectionStart;
      const end = textareaRef.selectionEnd;
      const platformContent = contents.find(c => c.platform === activeTab) || {
        platform: activeTab,
        content: '',
        hashtags: []
      };
      const newContent = platformContent.content.substring(0, start) + emoji + platformContent.content.substring(end);
      
      handleContentChange(activeTab, newContent);
      
      // Restore cursor position after emoji insertion
      setTimeout(() => {
        textareaRef.focus();
        const newPosition = start + emoji.length;
        textareaRef.setSelectionRange(newPosition, newPosition);
      }, 0);
    }
    setShowEmojiPicker(false);
  };

  const getPlatformMaxLength = (platform: string): number => {
    const limits: Record<string, number> = {
      twitter: 280,
      facebook: 63206,
      instagram: 2200,
      linkedin: 3000
    };
    return limits[platform.toLowerCase()] || 1000;
  };

  if (!platforms.length) {
    return (
      <div className="p-4 text-center text-gray-500">
        Sélectionnez au moins une plateforme
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Sélecteur de média commun */}
      <div className="border rounded-lg p-4">
        <MediaUploader
          value={media}
          onUpload={onMediaUpload}
          maxFiles={4}
        />
      </div>

      {/* Éditeurs par plateforme */}
      <Tabs
        value={activeTab}
        onValueChange={setActiveTab}
        className="w-full"
      >
        <TabsList className="w-full justify-start gap-2">
          {platforms.map(platform => (
            <TabsTrigger
              key={platform}
              value={platform}
              className={cn(
                "flex items-center gap-2",
                activeTab === platform && "bg-white shadow-sm"
              )}
            >
              <PlatformIcon platform={platform} className="w-4 h-4" />
              {platform.charAt(0).toUpperCase() + platform.slice(1)}
            </TabsTrigger>
          ))}
        </TabsList>

        {platforms.map(platform => {
          const platformContent = contents.find(c => c.platform === platform) || {
            platform,
            content: '',
            hashtags: []
          };

          const maxLength = getPlatformMaxLength(platform);

          return (
            <TabsContent
              key={platform}
              value={platform}
              className="space-y-4 mt-4"
            >
              <div className="relative">
                <div className="flex items-center gap-2 mb-2">
                  <button
                    onClick={onRequestSuggestions}
                    className="p-2 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 rounded-lg"
                    title="Obtenir des suggestions"
                  >
                    <Sparkles className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg"
                    title="Ajouter un emoji"
                  >
                    <Smile className="w-4 h-4" />
                  </button>
                </div>
                <textarea
                  ref={el => textareaRefs.current[platform] = el}
                  value={platformContent.content}
                  onChange={(e) => handleContentChange(platform, e.target.value)}
                  className="w-full h-32 p-3 border rounded-lg resize-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                  placeholder={`Contenu pour ${platform}...`}
                />
                {maxLength && (
                  <div className={cn(
                    "absolute bottom-2 right-2 text-xs",
                    platformContent.content.length > maxLength
                      ? "text-red-500"
                      : "text-gray-500"
                  )}>
                    {platformContent.content.length}/{maxLength}
                  </div>
                )}

                {/* Emoji Picker */}
                {showEmojiPicker && platform === activeTab && (
                  <div className="absolute top-12 right-0 z-50">
                    <EmojiPicker
                      onSelect={handleEmojiSelect}
                      onClose={() => setShowEmojiPicker(false)}
                    />
                  </div>
                )}
              </div>

              {/* Hashtags */}
              {platformContent.hashtags.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {platformContent.hashtags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-gray-100 dark:bg-gray-800 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              )}

              {/* Bouton de publication par plateforme */}
              <button
                onClick={() => onPublish(platform)}
                disabled={isPublishing || !platformContent.content.trim()}
                className={cn(
                  "w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50",
                  "flex items-center justify-center gap-2 transition-colors"
                )}
              >
                {isPublishing ? (
                  <Loader2 className="w-4 h-4 animate-spin" />
                ) : (
                  <Send className="w-4 h-4" />
                )}
                Publier sur {platform}
              </button>
            </TabsContent>
          );
        })}
      </Tabs>

      {/* Bouton de publication multi-plateformes */}
      <button
        onClick={() => onPublish()}
        disabled={isPublishing || !contents.some(c => c.content.trim())}
        className={cn(
          "w-full px-4 py-2 bg-gray-100 text-gray-700 hover:bg-gray-200 rounded-lg disabled:opacity-50",
          "flex items-center justify-center gap-2 transition-colors"
        )}
      >
        {isPublishing ? (
          <Loader2 className="w-4 h-4 animate-spin" />
        ) : (
          <Send className="w-4 h-4" />
        )}
        Publier sur toutes les plateformes
      </button>
    </div>
  );
}