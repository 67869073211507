import React from 'react';
import { Tag, Plus, X } from 'lucide-react';

interface Label {
  id: string;
  name: string;
  color: string;
}

interface MessageLabelsProps {
  labels: Label[];
  selectedLabels: string[];
  onToggleLabel: (labelId: string) => void;
  onCreateLabel?: () => void;
}

export function MessageLabels({ labels, selectedLabels, onToggleLabel, onCreateLabel }: MessageLabelsProps) {
  return (
    <div className="flex flex-wrap gap-2">
      {labels.map((label) => (
        <button
          key={label.id}
          onClick={() => onToggleLabel(label.id)}
          className={`flex items-center gap-1 px-2 py-1 rounded-full text-sm transition-colors ${
            selectedLabels.includes(label.id)
              ? `bg-${label.color}-100 text-${label.color}-700 dark:bg-${label.color}-900/30 dark:text-${label.color}-400`
              : 'bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400'
          }`}
        >
          <Tag className="w-3 h-3" />
          {label.name}
          {selectedLabels.includes(label.id) && (
            <X className="w-3 h-3 hover:text-red-500" />
          )}
        </button>
      ))}
      
      {onCreateLabel && (
        <button
          onClick={onCreateLabel}
          className="flex items-center gap-1 px-2 py-1 rounded-full text-sm bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700"
        >
          <Plus className="w-3 h-3" />
          Nouvelle étiquette
        </button>
      )}
    </div>
  );
}