import React, { useState, useRef } from 'react';
import { 
  X, 
  MessageCircle, 
  ThumbsUp, 
  Share2, 
  Calendar, 
  Reply, 
  Bot, 
  Loader2, 
  Flag, 
  EyeOff, 
  Send, 
  Pencil, 
  Trash2,
  Brain,
  Smile 
} from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Post } from '../../hooks/usePosts';
import { useComments } from '../../hooks/useComments';
import { EmojiPicker } from '../EmojiPicker';
import { useAuthStore } from '../../stores/authStore';
import { toast } from 'react-hot-toast';
import { PostSuggestions } from '../suggestions/PostSuggestions';
import { useAISettings } from '../suggestions/hooks/useAISettings';

interface PostPreviewDialogProps {
  post: Post | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  onAutoResponse: (commentId: string) => Promise<void>;
}

export function PostPreviewDialog({ post, isOpen, onClose, onAutoResponse }: PostPreviewDialogProps) {
  // États
  const [replyingTo, setReplyingTo] = useState<string | null>(null);
  const [replyContent, setReplyContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [editContent, setEditContent] = useState('');
  const [deletingCommentId, setDeletingCommentId] = useState<string | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showAISuggestions, setShowAISuggestions] = useState(false);
  const [showCommentAISuggestions, setShowCommentAISuggestions] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const commentTextareaRef = useRef<HTMLTextAreaElement>(null);

  const { user } = useAuthStore();
  const { useCommentsList, replyToComment, createComment, updateComment, deleteComment } = useComments();
  const { settings } = useAISettings();

  const { data: commentsData, isLoading: isLoadingComments } = useCommentsList(
    isOpen && post?._id ? {
      postId: post._id,
      limit: 1000,
      sortBy: 'date',
      sortOrder: 'asc',
      page: 1
    } : null
  );

  if (!isOpen || !post) return null;

  const getMediaUrl = (url: string | undefined) => {
    if (!url) return '';
    if (url.startsWith('http')) return url;
    return `${import.meta.env.VITE_API_URL}${url}`;
  };

  // Gestion des emojis
  const handleEmojiSelect = (emoji: string, isComment = false) => {
    const targetRef = isComment ? commentTextareaRef : textareaRef;
    const targetContent = isComment ? newComment : replyContent;
    const setContent = isComment ? setNewComment : setReplyContent;

    if (targetRef.current) {
      const start = targetRef.current.selectionStart;
      const end = targetRef.current.selectionEnd;
      const newContent = targetContent.substring(0, start) + emoji + targetContent.substring(end);
      setContent(newContent);
      
      setTimeout(() => {
        if (targetRef.current) {
          targetRef.current.focus();
          targetRef.current.selectionStart = start + emoji.length;
          targetRef.current.selectionEnd = start + emoji.length;
        }
      }, 0);
    }
    setShowEmojiPicker(false);
  };

  const handleSubmitReply = async (commentId: string) => {
    if (!replyContent.trim()) return;

    setIsSubmitting(true);
    try {
      await replyToComment.mutateAsync({
        commentId,
        content: replyContent,
        useAI: false
      });
      toast.success('Réponse publiée avec succès');
      setReplyContent('');
      setReplyingTo(null);
      setShowAISuggestions(false);
    } catch (error) {
      console.error('Reply error:', error);
      toast.error('Erreur lors de la publication de la réponse');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitNewComment = async () => {
    if (!newComment.trim()) return;

    setIsSubmitting(true);
    try {
      await createComment.mutateAsync({
        postId: post._id,
        content: newComment,
        platform: post.platform,
        pageId: post.metadata?.pageId
      });
      toast.success('Commentaire publié avec succès');
      setNewComment('');
      setShowCommentAISuggestions(false);
    } catch (error) {
      console.error('Comment creation error:', error);
      toast.error('Erreur lors de la publication du commentaire');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = async (commentId: string) => {
    if (!editContent.trim()) {
      toast.error('Le commentaire ne peut pas être vide');
      return;
    }

    try {
      await updateComment.mutateAsync({
        commentId,
        content: editContent,
      });
      toast.success('Commentaire mis à jour');
      setEditingCommentId(null);
      setEditContent('');
    } catch (error) {
      console.error('Edit error:', error);
      toast.error('Erreur lors de la mise à jour');
    }
  };

  const handleDelete = async (commentId: string) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce commentaire ?')) {
      return;
    }

    setDeletingCommentId(commentId);
    const toastId = toast.loading('Suppression du commentaire...');
    
    try {
      await deleteComment.mutateAsync(commentId);
      toast.success('Commentaire supprimé avec succès', { id: toastId });
    } catch (error) {
      console.error('Delete error:', error);
      toast.error('Erreur lors de la suppression', { id: toastId });
    } finally {
      setDeletingCommentId(null);
    }
  };

  const canModifyComment = (comment: any) => {
    if (user?.id === comment.userId) return true;
    if (comment.platform === 'facebook' && comment.metadata?.pageId) {
      const userPages = user?.facebookPages || [];
      return userPages.some(page => page.id === comment.metadata?.pageId);
    }
    return false;
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-3xl w-full max-h-[90vh] flex flex-col">
        {/* En-tête */}
        <div className="sticky top-0 bg-white dark:bg-gray-800 p-4 border-b dark:border-gray-700 flex items-center justify-between z-10">
          <div className="flex items-center gap-2">
            <span className="capitalize">{post.platform}</span>
            {post.metadata?.pageName && (
              <>
                <span>•</span>
                <span>{post.metadata.pageName}</span>
              </>
            )}
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Contenu défilable */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-6">
            {/* Date */}
            <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400 mb-4">
              <Calendar className="w-4 h-4" />
              {format(new Date(post.createdAt), 'dd MMMM yyyy HH:mm', { locale: fr })}
            </div>

            {/* Texte */}
            <p className="text-gray-900 dark:text-gray-100 whitespace-pre-wrap mb-6">
              {post.content}
            </p>

            {/* Médias */}
            {post.media && post.media.length > 0 && (
              <div className="grid grid-cols-2 gap-4 mb-6">
                {post.media.map((media, index) => (
                  <div
                    key={index}
                    className="relative aspect-square rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700"
                  >
                    {media.type === 'image' ? (
                      <img
                        src={getMediaUrl(media.url)}
                        alt={media.alt || ''}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <video
                        src={getMediaUrl(media.url)}
                        controls
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Métriques */}
            {post.analytics && (
              <div className="flex items-center gap-6 text-sm text-gray-500 dark:text-gray-400 mb-6">
                <div className="flex items-center gap-1">
                  <ThumbsUp className="w-4 h-4" />
                  <span>{post.analytics.likes}</span>
                </div>
                <div className="flex items-center gap-1">
                  <MessageCircle className="w-4 h-4" />
                  <span>{post.analytics.comments}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Share2 className="w-4 h-4" />
                  <span>{post.analytics.shares}</span>
                </div>
              </div>
            )}

            {/* Séparateur */}
            <div className="border-t dark:border-gray-700 -mx-6 mb-6"></div>

            {/* Section des commentaires */}
            <div className="space-y-6">
              <h3 className="font-medium flex items-center gap-2">
                <MessageCircle className="w-5 h-5 text-indigo-500" />
                Commentaires ({commentsData?.total || 0})
              </h3>

              {/* Nouveau commentaire */}
              <div className="space-y-4">
                <div className="relative">
                  <div className="flex items-center gap-2 mb-2">
                    <div className="flex-1">
                      <textarea
                        ref={commentTextareaRef}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="Ajouter un commentaire..."
                        className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                        rows={1}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => setShowCommentAISuggestions(!showCommentAISuggestions)}
                        className="p-2 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 rounded-lg"
                        title="Suggestions IA"
                      >
                        <Brain className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg"
                        title="Ajouter un emoji"
                      >
                        <Smile className="w-4 h-4" />
                      </button>
                      <button
                        onClick={handleSubmitNewComment}
                        disabled={!newComment.trim() || isSubmitting}
                        className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
                      >
                        {isSubmitting ? (
                          <Loader2 className="w-4 h-4 animate-spin" />
                        ) : (
                          <Send className="w-4 h-4" />
                        )}
                        Commenter
                      </button>
                    </div>
                  </div>

                  {showEmojiPicker && (
                    <div className="absolute top-full right-0 mt-2 z-50">
                      <EmojiPicker
                        onSelect={(emoji) => handleEmojiSelect(emoji, true)}
                        onClose={() => setShowEmojiPicker(false)}
                      />
                    </div>
                  )}
                </div>

                {/* Suggestions IA pour le nouveau commentaire */}
                {showCommentAISuggestions && (
                  <div className="border dark:border-gray-700 rounded-lg overflow-hidden">
                    <div className="bg-gray-50 dark:bg-gray-800 px-4 py-2 border-b dark:border-gray-700">
                      <h3 className="font-medium flex items-center gap-2">
                        <Bot className="w-4 h-4 text-indigo-500" />
                        Suggestions de commentaires
                      </h3>
                    </div>
                    <div className="p-4">
                      <PostSuggestions
                        content={post.content}
                        platforms={[post.platform]}
                        onSelect={setNewComment}
                        settings={settings}
                        context={[
                          post.metadata?.pageName && `Page: ${post.metadata.pageName}`,
                          'Type: comment'
                        ].filter(Boolean)}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* Liste des commentaires */}
              {isLoadingComments ? (
                <div className="flex items-center justify-center py-8">
                  <Loader2 className="w-6 h-6 animate-spin text-indigo-500" />
                </div>
              ) : commentsData?.comments && commentsData.comments.length > 0 ? (
                <div className="space-y-4">
                  {commentsData.comments.map((comment) => (
                    <div
                      key={comment._id}
                      className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4"
                    >
                      {/* En-tête du commentaire */}
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center gap-3">
                          {comment.author.avatar ? (
                            <img
                              src={comment.author.avatar}
                              alt={comment.author.name}
                              className="w-8 h-8 rounded-full"
                            />
                          ) : (
                            <div className="w-8 h-8 bg-indigo-100 dark:bg-indigo-900/30 rounded-full flex items-center justify-center">
                              <span className="text-sm font-semibold text-indigo-600 dark:text-indigo-400">
                                {comment.author.name?.charAt(0) || '?'}
                              </span>
                            </div>
                          )}
                          <div>
                            <p className="font-medium text-sm">{comment.author.name || 'Utilisateur anonyme'}</p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              {format(new Date(comment.createdAt), 'dd MMM yyyy HH:mm', { locale: fr })}
                            </p>
                          </div>
                        </div>
                        {canModifyComment(comment) && (
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => {
                                setEditingCommentId(comment._id);
                                setEditContent(comment.content);
                              }}
                              className="p-1 text-gray-500 hover:text-indigo-500 rounded"
                              title="Modifier"
                            >
                              <Pencil className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => handleDelete(comment._id)}
                              disabled={deletingCommentId === comment._id}
                              className={`p-1 rounded ${
                                deletingCommentId === comment._id 
                                  ? 'text-gray-400 cursor-not-allowed' 
                                  : 'text-gray-500 hover:text-red-500'
                              }`}
                              title="Supprimer"
                            >
                              {deletingCommentId === comment._id ? (
                                <Loader2 className="w-4 h-4 animate-spin" />
                              ) : (
                                <Trash2 className="w-4 h-4" />
                              )}
                            </button>
                          </div>
                        )}
                      </div>

                      {/* Contenu */}
                      {editingCommentId === comment._id ? (
                        <div className="mt-2 space-y-2">
                          <textarea
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                            className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
                            rows={3}
                          />
                          <div className="flex justify-end gap-2">
                            <button
                              onClick={() => {
                                setEditingCommentId(null);
                                setEditContent('');
                              }}
                              className="px-3 py-1.5 text-xs text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                            >
                              Annuler
                            </button>
                            <button
                              onClick={() => handleEdit(comment._id)}
                              className="px-3 py-1.5 text-xs bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                            >
                              Enregistrer
                            </button>
                          </div>
                        </div>
                      ) : (
                        <p className="text-sm text-gray-900 dark:text-gray-100 mb-3">{comment.content}</p>
                      )}

                      {/* Actions */}
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => {
                            setReplyingTo(comment._id);
                            setShowAISuggestions(false);
                          }}
                          className="flex items-center gap-1 text-xs text-gray-600 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400"
                        >
                          <Reply className="w-4 h-4" />
                          Répondre
                        </button>
                        <button
                          onClick={() => onAutoResponse(comment._id)}
                          className="flex items-center gap-1 text-xs text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/30 rounded-lg transition-colors"
                        >
                          <Bot className="w-4 h-4" />
                          Réponse IA
                        </button>
                        <button
                          onClick={() => handleDelete(comment._id)}
                          className="flex items-center gap-1 text-xs text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/30 rounded-lg transition-colors"
                        >
                          <Flag className="w-4 h-4" />
                          Signaler
                        </button>
                        <button
                          onClick={() => handleDelete(comment._id)}
                          className="flex items-center gap-1 text-xs text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/30 rounded-lg transition-colors"
                        >
                          <EyeOff className="w-4 h-4" />
                          Masquer
                        </button>
                      </div>

                      {/* Zone de réponse */}
                      {replyingTo === comment._id && (
                        <div className="mt-4 space-y-4">
                          <div className="relative">
                            <div className="flex items-center gap-2 mb-2">
                              <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                Votre réponse
                              </label>
                              <div className="flex items-center gap-2">
                                <button
                                  onClick={() => setShowAISuggestions(!showAISuggestions)}
                                  className="p-1 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 rounded-lg"
                                  title="Suggestions IA"
                                >
                                  <Brain className="w-4 h-4" />
                                </button>
                                <button
                                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                  className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg"
                                  title="Ajouter un emoji"
                                >
                                  <Smile className="w-4 h-4" />
                                </button>
                              </div>
                            </div>

                            <textarea
                              ref={textareaRef}
                              value={replyContent}
                              onChange={(e) => setReplyContent(e.target.value)}
                              placeholder="Votre réponse..."
                              className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                              rows={3}
                            />

                            {showEmojiPicker && (
                              <div className="absolute top-full right-0 mt-2 z-50">
                                <EmojiPicker
                                  onSelect={(emoji) => handleEmojiSelect(emoji, false)}
                                  onClose={() => setShowEmojiPicker(false)}
                                />
                              </div>
                            )}
                          </div>

                          {/* Suggestions IA */}
                          {showAISuggestions && (
                            <div className="space-y-4">
                              <PostSuggestions
                                content={comment.content}
                                platforms={[post.platform]}
                                onSelect={setReplyContent}
                                settings={settings}
                                context={[
                                  `Platform: ${post.platform}`,
                                  post.metadata?.pageName && `Page: ${post.metadata.pageName}`,
                                  'Type: reply',
                                  `Original Comment: ${comment.content}`
                                ].filter(Boolean)}
                              />
                            </div>
                          )}

                          <div className="flex justify-end gap-2">
                            <button
                              onClick={() => {
                                setReplyingTo(null);
                                setReplyContent('');
                                setShowAISuggestions(false);
                              }}
                              className="px-3 py-1.5 text-xs text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                            >
                              Annuler
                            </button>
                            <button
                              onClick={() => handleSubmitReply(comment._id)}
                              disabled={!replyContent.trim() || isSubmitting}
                              className="flex items-center gap-1 px-3 py-1.5 text-xs bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
                            >
                              {isSubmitting ? (
                                <Loader2 className="w-3 h-3 animate-spin" />
                              ) : (
                                <Reply className="w-3 h-3" />
                              )}
                              Répondre
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                  Aucun commentaire
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}