import React, { useState, useEffect } from 'react';
import { Loader2, RefreshCw } from 'lucide-react';
import { useAIGenerator, useAISettings } from './hooks';
import { SuggestionCard, PromptInput } from './ui';
import { toast } from 'react-hot-toast';
import type { AISettings } from './types/AITypes';

interface CampaignSuggestionsProps {
  objective: string;
  platforms: string[];
  onSelect: (suggestions: Array<{
    content: string;
    platform: string;
    scheduledDate?: Date;
    type?: 'image' | 'video';
    mediaPrompt?: string;
  }>) => void;
  settings?: Partial<AISettings>;
  schedule?: {
    start: Date;
    end: Date;
    frequency: 'daily' | 'weekly' | 'monthly';
  };
  pageId?: string;
  pageName?: string;
}

export function CampaignSuggestions({
  objective,
  platforms,
  onSelect,
  settings: initialSettings,
  schedule,
  pageId,
  pageName
}: CampaignSuggestionsProps) {
  const [campaignPlan, setCampaignPlan] = useState<any[]>([]);
  const [customPrompt, setCustomPrompt] = useState('');
  
  // Utiliser le store Zustand correctement
  const { settings } = useAISettings();

  // Mettre à jour les settings avec les valeurs initiales
  useEffect(() => {
    if (initialSettings) {
      useAISettings.getState().updateSettings({
        ...settings,
        ...initialSettings,
      });
    }
  }, [initialSettings]);

  const { generate, isLoading, error } = useAIGenerator({
    type: 'campaign',
    onError: (error) => {
      toast.error('Erreur lors de la génération du plan de campagne');
      console.error('Campaign generation error:', error);
    }
  });

  const generateCampaign = async (customPrompt?: string) => {
    const campaignObjective = customPrompt || objective;
    
    if (!campaignObjective) {
      toast.error("Veuillez définir l'objectif de la campagne");
      return;
    }

    try {
      // Calculer le nombre de posts requis
      const days = Math.ceil((schedule.end.getTime() - schedule.start.getTime()) / (1000 * 60 * 60 * 24));
      const postsPerDay = schedule.frequency === 'low' ? 1 : schedule.frequency === 'medium' ? 2 : 3;
      const totalPosts = days * postsPerDay;

      // Construire le prompt avec les spécifications exactes
      const prompt = `Crée une campagne marketing avec les spécifications suivantes:

Type de campagne: Campagne sur les réseaux sociaux
Objectif: ${campaignObjective}
Plateformes: ${platforms.join(', ')}
Fréquence: ${schedule.frequency}
Début: ${schedule.start.toLocaleDateString()}
Fin: ${schedule.end.toLocaleDateString()}
Page ID: ${pageId}
Page: ${pageName}
Nombre de posts requis: ${totalPosts}

Instructions spécifiques:
1. Chaque post doit être adapté à la plateforme cible
2. Le contenu doit être engageant et optimisé pour le partage
3. Inclure des suggestions de visuels pertinents
4. Maintenir une cohérence dans le message et le ton
5. Respecter la fréquence de publication demandée`;

      console.log('Starting campaign generation:', {
        objective: campaignObjective,
        platform: platforms[0],
        settings
      });

      const contextInfo = [
        `Type de campagne: Campagne sur les réseaux sociaux`,
        `Objectif: ${campaignObjective}`,
        `Plateformes: ${platforms.join(', ')}`,
        `Fréquence: ${schedule.frequency}`,
        `Début: ${schedule.start.toLocaleDateString()}`,
        `Fin: ${schedule.end.toLocaleDateString()}`,
        pageId && `Page ID: ${pageId}`,
        pageName && `Page: ${pageName}`,
        `Nombre de posts requis: ${totalPosts}`
      ].filter(Boolean).join('\n');

      const aiResponse = await generate(prompt, {
        type: 'campaign',
        platform: platforms[0],
        settings: {
          ...settings,
          tone: settings.tone,
          style: settings.style,
          length: settings.length,
          creativity: settings.creativity,
          useHashtags: settings.useHashtags,
          useMentions: settings.useMentions,
          useEmojis: settings.useEmojis,
          systemPrompt: `Tu es un expert en marketing digital spécialisé dans la création de campagnes sur les réseaux sociaux.
Génère exactement ${totalPosts} posts optimisés pour une campagne marketing en respectant ces critères:

- Ton: ${settings.tone}
- Style: ${settings.style}
- Longueur: ${settings.length}
- Créativité: ${settings.creativity * 100}%
- Utilisation des hashtags: ${settings.useHashtags ? 'Oui' : 'Non'}
- Utilisation des mentions: ${settings.useMentions ? 'Oui' : 'Non'}
- Utilisation des emojis: ${settings.useEmojis ? 'Oui' : 'Non'}

Format requis pour chaque post:
{
  "content": "Le texte du post",
  "platform": "${platforms[0]}",
  "type": "image/video/text",
  "mediaPrompt": "Description du visuel à créer (optionnel)"
}`
        },
        context: contextInfo
      });

      if (aiResponse?.content) {
        const posts = aiResponse.content
          .split(/Post \d+:/)
          .slice(1)
          .map((postStr, index) => {
            try {
              const jsonStr = postStr.substring(
                postStr.indexOf('{'), 
                postStr.lastIndexOf('}') + 1
              );
              console.log('Processing post JSON:', jsonStr);
              
              const post = JSON.parse(jsonStr);
              return {
                ...post,
                platform: platforms[0].toLowerCase(),
                postIndex: index.toString(),
                scheduledDate: calculateScheduledDate(
                  { ...post, postIndex: index.toString() },
                  schedule
                )
              };
            } catch (error) {
              console.error('Error parsing post:', error);
              return null;
            }
          })
          .filter(post => post !== null);

        if (posts.length > 0) {
          console.log('Generated campaign posts:', posts);
          const sortedPosts = posts.sort((a, b) => 
            (a.scheduledDate?.getTime() || 0) - (b.scheduledDate?.getTime() || 0)
          );
          setCampaignPlan(sortedPosts);
          onSelect(sortedPosts);
        } else {
          toast.error('Aucun post généré');
        }
      }
    } catch (error) {
      console.error('Campaign generation error:', error);
      toast.error('Erreur lors de la génération de la campagne');
    }
  };

  // Générer le plan initial
  useEffect(() => {
    if (objective && platforms.length > 0) {
      generateCampaign();
    }
  }, [objective, platforms.join(','), schedule?.frequency]);

  return (
    <div className="space-y-6 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 p-4">
      {/* En-tête */}
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium">Plan de campagne</h3>
          <button
            onClick={() => generateCampaign()}
            disabled={isLoading || !objective}
            className="text-sm text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300 flex items-center gap-2"
          >
            <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
            Régénérer
          </button>
        </div>

        {/* Prompt personnalisé */}
        <PromptInput
          onSubmit={generateCampaign}
          placeholder="Personnalisez votre campagne (optionnel)..."
          disabled={isLoading || !objective || platforms.length === 0}
        />

        {/* État de chargement */}
        {isLoading ? (
          <div className="flex items-center justify-center py-8">
            <Loader2 className="w-6 h-6 animate-spin text-indigo-500" />
          </div>
        ) : (
          <div className="space-y-4">
            {campaignPlan.map((post, index) => (
              <SuggestionCard
                key={index}
                suggestion={post.content}
                onSelect={() => onSelect([post])}
                context={{
                  platform: post.platform,
                  type: 'campaign',
                  prompt: objective
                }}
              />
            ))}

            {!isLoading && campaignPlan.length === 0 && (
              <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                {objective 
                  ? "Cliquez sur Générer pour créer un plan de campagne"
                  : "Définissez d'abord l'objectif de votre campagne"}
              </div>
            )}
          </div>
        )}

        {error && (
          <div className="text-center py-4 text-red-600 dark:text-red-400">
            Une erreur est survenue lors de la génération du plan de campagne
          </div>
        )}
      </div>
    </div>
  );
}

function calculateScheduledDate(post: any, schedule?: { 
  start: Date; 
  end: Date;
  frequency: 'low' | 'medium' | 'high';
}): Date | undefined {
  if (!schedule?.start || !schedule?.end) return undefined;

  const startTime = schedule.start.getTime();
  const endTime = schedule.end.getTime();
  const duration = endTime - startTime;
  
  // Calculer le nombre total de jours
  const totalDays = Math.ceil(duration / (24 * 60 * 60 * 1000));
  
  // Calculer le nombre total de posts basé sur la fréquence
  const postsPerDay = schedule.frequency === 'high' ? 3 : 
                      schedule.frequency === 'medium' ? 2 : 1;
  const totalPosts = totalDays * postsPerDay;

  // Trouver l'index de ce post dans la séquence
  const postIndex = parseInt(post.postIndex || '0');
  
  // Calculer l'intervalle entre les posts
  const interval = duration / totalPosts;
  
  // Calculer l'heure de base pour chaque post dans la journée
  const baseHours = schedule.frequency === 'high' ? [9, 13, 17] :
                   schedule.frequency === 'medium' ? [10, 15] :
                   [12];
  
  // Calculer le jour et l'heure du post
  const dayIndex = Math.floor(postIndex / postsPerDay);
  const timeSlotIndex = postIndex % postsPerDay;
  
  // Créer la date du post
  const postDate = new Date(startTime + (dayIndex * 24 * 60 * 60 * 1000));
  
  // Définir l'heure du post
  postDate.setHours(baseHours[timeSlotIndex], 0, 0, 0);
  
  // Ajouter une légère variation aléatoire (±30 minutes)
  const randomMinutes = Math.floor(Math.random() * 61) - 30;
  postDate.setMinutes(randomMinutes);

  return postDate;
}