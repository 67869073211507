import { useAuthStore } from '../stores/authStore';

export type AIPromptType = 'post' | 'reply' | 'campaign' | 'analysis';

interface AIPromptOptions {
  type: AIPromptType;
  context?: string;
  tone?: string;
  length?: string;
  language?: string;
  platform?: string;
}

interface AIResponse {
  content: string;
  suggestions?: string[];
  confidence: number;
  sentiment?: {
    score: number;
    label: 'positive' | 'neutral' | 'negative';
  };
}

export async function generateAIResponse(prompt: string, options: AIPromptOptions): Promise<AIResponse> {
  const { token } = useAuthStore.getState();
  
  try {
    const response = await fetch(`${import.meta.env.VITE_API_URL}/ai/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ 
        prompt,
        options
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'AI generation failed');
    }

    return await response.json();
  } catch (error) {
    console.error('AI generation error:', error);
    throw error;
  }
}