import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';
import { toast } from 'react-hot-toast';

interface CommentFilters {
  page?: number;
  limit?: number;
  platform?: string;
  pageId?: string | null;
  postId?: string;
  sentiment?: string;
  status?: string;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  hasReplies?: boolean;
  isAiGenerated?: boolean;
  startDate?: string | null;
  endDate?: string | null;
}

export function useComments() {
  const { token } = useAuthStore();
  const queryClient = useQueryClient();
  const apiUrl = import.meta.env.VITE_API_URL;

  const useCommentsList = (filters: CommentFilters | null = null) => {
    const params = new URLSearchParams();
    
    if (filters) {
      // Paramètres de pagination
      if (filters.page) params.append('page', filters.page.toString());
      if (filters.limit) params.append('limit', filters.limit.toString());
      
      // Autres paramètres
      if (filters.platform && filters.platform !== 'all') params.append('platform', filters.platform);
      if (filters.pageId) params.append('pageId', filters.pageId);
      if (filters.postId) params.append('postId', filters.postId);
      if (filters.sentiment && filters.sentiment !== 'all') params.append('sentiment', filters.sentiment);
      if (filters.status && filters.status !== 'all') params.append('status', filters.status);
      if (filters.search) params.append('search', filters.search);
      if (filters.sortBy) params.append('sortBy', filters.sortBy);
      if (filters.sortOrder) params.append('sortOrder', filters.sortOrder);
      if (filters.hasReplies !== undefined) params.append('hasReplies', filters.hasReplies.toString());
      if (filters.isAiGenerated !== undefined) params.append('isAiGenerated', filters.isAiGenerated.toString());
      
      // Paramètres de date
      if (filters.startDate) {
        params.append('startDate', filters.startDate);
      }
      if (filters.endDate) {
        params.append('endDate', filters.endDate);
      }
    }

    console.log('Query params:', Object.fromEntries(params.entries()));

    return useQuery({
      queryKey: ['comments', Object.fromEntries(params.entries())],
      queryFn: async () => {
        const response = await fetch(
          `${apiUrl}/comments?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || 'Failed to fetch comments');
        }

        return response.json();
      },
      enabled: !!token && !!filters,
    });
  };

  const useAnalytics = () => {
    return useQuery({
      queryKey: ['comments', 'analytics'],
      queryFn: async () => {
        const response = await fetch(
          `${apiUrl}/comments/analytics`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || 'Failed to fetch analytics');
        }

        return response.json();
      },
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
    });
  };

// Mise à jour de la mutation updateComment
const updateComment = useMutation({
  mutationFn: async ({ commentId, content }: { commentId: string; content: string }) => {
    const loadingToast = toast.loading('Mise à jour du commentaire...');
    
    const response = await fetch(
      `${apiUrl}/comments/${commentId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ content }),
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to update comment');
    }

    toast.dismiss(loadingToast);
    toast.success('Commentaire mis à jour avec succès');
    return response.json();
  },
  onError: (error: Error) => {
    toast.error(`Erreur: ${error.message}`);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['comments'] });
  },
});

const deleteComment = useMutation({
  mutationFn: async (commentId: string) => {
    const loadingToast = toast.loading('Suppression du commentaire...');
    
    const response = await fetch(
      `${apiUrl}/comments/${commentId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to delete comment');
    }

    toast.dismiss(loadingToast);
    toast.success('Commentaire supprimé avec succès');
    return response.json();
  },
  onError: (error: Error) => {
    toast.error(`Erreur: ${error.message}`);
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['comments'] });
  },
});

  const replyToComment = useMutation({
    mutationFn: async ({ commentId, content, useAI }: { commentId: string; content: string; useAI: boolean }) => {
      toast.loading('Envoi de la réponse...');
      
      const response = await fetch(
        `${apiUrl}/comments/${commentId}/reply`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ content, useAI }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to reply to comment');
      }

      const result = await response.json();
      toast.success('Réponse envoyée avec succès');
      return result;
    },
    onError: (error: Error) => {
      toast.error(`Erreur: ${error.message}`);
    },
    onSettled: () => {
      toast.dismiss();
      queryClient.invalidateQueries({ queryKey: ['comments'] });
    },
  });

  const createComment = useMutation({
    mutationFn: async ({ postId, content }: { postId: string; content: string }) => {
      const loadingToast = toast.loading('Création du commentaire...');
      
      const response = await fetch(
        `${apiUrl}/comments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ postId, content }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create comment');
      }

      toast.dismiss(loadingToast);
      toast.success('Commentaire créé avec succès');
      return response.json();
    },
    onError: (error: Error) => {
      toast.error(`Erreur: ${error.message}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments'] });
    },
  });

  return {
    useCommentsList,
    useAnalytics,
    updateComment,
    deleteComment,
    replyToComment,
    createComment,
  };
}