import React, { useState, useEffect } from 'react';
import { Loader2, RefreshCw, Bot, Settings2 } from 'lucide-react';
import { useAIGenerator } from './hooks/useAIGenerator';
import { useAISettings } from './hooks/useAISettings';
import { SuggestionCard } from './ui/SuggestionCard';
import { PromptInput } from './ui/PromptInput';
import { AISettingsPanel } from './ui/AISettings';
import { toast } from 'react-hot-toast';

interface ReplySuggestionsProps {
  content: string;
  platforms: string[];
  onSelect: (suggestion: string) => void;
  settings?: {
    tone?: string;
    style?: string;
    length?: string;
    useEmojis?: boolean;
  };
  context?: string[];
}

export function ReplySuggestions({
  content,
  platforms,
  onSelect,
  settings: componentSettings,
  context = []
}: ReplySuggestionsProps) {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showAISettings, setShowAISettings] = useState(false);
  const [localSettings, setLocalSettings] = useState(componentSettings);
  const { settings: globalSettings } = useAISettings();
  const { generate, isLoading, error } = useAIGenerator({
    type: 'reply',
    onError: (error) => {
      console.error('Suggestions generation error:', error);
      toast.error('Erreur lors de la génération des suggestions');
    }
  });

  // Merge settings with priority to local settings
  const mergedSettings = {
    ...globalSettings,
    ...localSettings,
    enabled: globalSettings.enabled // Always respect global enabled state
  };

  const generateSuggestions = async (customPrompt?: string) => {
    if (!content?.trim() && !customPrompt?.trim()) {
      console.warn('ReplySuggestions - Missing required comment content');
      return;
    }

    if (!mergedSettings.enabled) {
      toast.error("L'IA est désactivée dans les paramètres");
      return;
    }

    try {
      const response = await generate(customPrompt || content, {
        platform: platforms[0],
        settings: mergedSettings,
        context
      });

      if (response.suggestions?.length) {
        setSuggestions(response.suggestions);
      } else if (response.content) {
        // If no explicit suggestions, split content into suggestions
        const parsedSuggestions = response.content
          .split(/\d+\.\s+/)
          .map(s => s.trim())
          .filter(Boolean);
        
        if (parsedSuggestions.length) {
          setSuggestions(parsedSuggestions);
        } else {
          setSuggestions([response.content]);
        }
      }
    } catch (error) {
      console.error('Suggestions generation error:', error);
    }
  };

  // Generate suggestions only if AI is enabled and autoRespond is true
  useEffect(() => {
    if (content?.trim() && mergedSettings.enabled && mergedSettings.autoRespond) {
      generateSuggestions();
    }
  }, [content, platforms.join(','), mergedSettings.enabled, mergedSettings.autoRespond]);

  const handleFeedback = async (suggestion: string, isPositive: boolean) => {
    toast.success(
      isPositive ? 'Merci pour votre retour positif !' : 'Merci pour votre retour !'
    );
  };

  const handleSettingsUpdate = (newSettings: Partial<typeof localSettings>) => {
    setLocalSettings(prev => ({
      ...prev,
      ...newSettings
    }));
    // Regenerate suggestions with new settings
    generateSuggestions();
  };

  if (!content?.trim()) {
    return (
      <div className="text-center py-8 text-gray-500">
        <Bot className="w-8 h-8 mx-auto mb-2" />
        Impossible de générer des suggestions sans contenu
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Header with settings button */}
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-sm font-medium flex items-center gap-2">
          <Bot className="w-4 h-4 text-indigo-500" />
          Suggestions de réponses
        </h3>
        <button
          onClick={() => setShowAISettings(true)}
          className="p-1 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 rounded-lg"
          title="Paramètres IA"
        >
          <Settings2 className="w-4 h-4" />
        </button>
      </div>

      {/* Suggestions list */}
      <div className="space-y-3">
        {isLoading ? (
          <div className="flex items-center justify-center py-8">
            <Loader2 className="w-6 h-6 animate-spin text-indigo-500" />
          </div>
        ) : suggestions.length > 0 ? (
          <>
            {suggestions.map((suggestion, index) => (
              <SuggestionCard
                key={index}
                suggestion={suggestion}
                onSelect={onSelect}
                onFeedback={handleFeedback}
                context={{
                  platform: platforms[0],
                  type: 'reply',
                  prompt: content
                }}
              />
            ))}
            <button
              onClick={() => generateSuggestions()}
              disabled={isLoading}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm text-indigo-600 hover:text-indigo-700"
            >
              <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
              Régénérer
            </button>
          </>
        ) : error ? (
          <div className="text-center py-8 text-red-500">
            Une erreur est survenue lors de la génération des suggestions
          </div>
        ) : (
          <div className="text-center py-8 text-gray-500">
            <Bot className="w-8 h-8 mx-auto mb-2" />
            Aucune suggestion disponible
          </div>
        )}
      </div>

      {/* Custom prompt input */}
      <PromptInput
        onSubmit={generateSuggestions}
        placeholder="Personnalisez votre réponse..."
        disabled={isLoading || !mergedSettings.enabled}
      />

      {/* AI Settings panel */}
      <AISettingsPanel
        isOpen={showAISettings}
        onClose={() => setShowAISettings(false)}
        settings={mergedSettings}
        onUpdate={handleSettingsUpdate}
        onSave={() => setShowAISettings(false)}
      />
    </div>
  );
}