import React from 'react';
import { AlertTriangle, Shield, Eye, EyeOff } from 'lucide-react';

interface ModerationBadgeProps {
  status: 'flagged' | 'hidden' | 'removed' | 'active';
  score?: number;
}

export function ModerationBadge({ status, score }: ModerationBadgeProps) {
  const getStatusConfig = () => {
    switch (status) {
      case 'flagged':
        return {
          icon: AlertTriangle,
          text: 'Signalé',
          className: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-400'
        };
      case 'hidden':
        return {
          icon: EyeOff,
          text: 'Masqué',
          className: 'bg-orange-100 text-orange-800 dark:bg-orange-900/30 dark:text-orange-400'
        };
      case 'removed':
        return {
          icon: Shield,
          text: 'Supprimé',
          className: 'bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-400'
        };
      default:
        return {
          icon: Eye,
          text: 'Visible',
          className: 'bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-400'
        };
    }
  };

  const config = getStatusConfig();
  const Icon = config.icon;

  return (
    <div className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs ${config.className}`}>
      <Icon className="w-3 h-3" />
      <span>{config.text}</span>
      {score !== undefined && (
        <span className="ml-1">({Math.round(score * 100)}%)</span>
      )}
    </div>
  );
}