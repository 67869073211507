import React from 'react';
import { Brain, Sparkles } from 'lucide-react';

interface SuggestionTabsProps {
  activeTab: 'content' | 'prompt';
  onChange: (tab: 'content' | 'prompt') => void;
}

export function SuggestionTabs({ activeTab, onChange }: SuggestionTabsProps) {
  return (
    <div className="flex border-b border-gray-200 dark:border-gray-700">
      <button
        onClick={() => onChange('prompt')}
        className={`flex-1 px-4 py-3 text-sm font-medium ${
          activeTab === 'prompt'
            ? 'border-b-2 border-indigo-500 text-indigo-600 dark:text-indigo-400'
            : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
        }`}
      >
        <div className="flex items-center justify-center gap-2">
          <Sparkles className="w-4 h-4" />
          Suggestions depuis un prompt
        </div>
      </button>
      <button
        onClick={() => onChange('content')}
        className={`flex-1 px-4 py-3 text-sm font-medium ${
          activeTab === 'content'
            ? 'border-b-2 border-indigo-500 text-indigo-600 dark:text-indigo-400'
            : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
        }`}
      >
        <div className="flex items-center justify-center gap-2">
          <Brain className="w-4 h-4" />
          Suggestions basées sur le contenu
        </div>
      </button>
    </div>
  );
}