// src/components/suggestions/ui/PlatformIcon.tsx
import React from 'react';
import { 
  Facebook, 
  Twitter, 
  Instagram, 
  Linkedin, 
  Globe 
} from 'lucide-react';

interface PlatformIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  platform: string;
}

const PLATFORM_ICONS = {
  facebook: Facebook,
  twitter: Twitter,
  instagram: Instagram,
  linkedin: Linkedin,
  default: Globe
};

export function PlatformIcon({ platform, className, ...props }: PlatformIconProps) {
  const Icon = PLATFORM_ICONS[platform.toLowerCase() as keyof typeof PLATFORM_ICONS] || PLATFORM_ICONS.default;

  const getIconColor = (platform: string) => {
    switch (platform.toLowerCase()) {
      case 'facebook':
        return 'text-blue-600 dark:text-blue-400';
      case 'twitter':
        return 'text-sky-500 dark:text-sky-400';
      case 'instagram':
        return 'text-pink-600 dark:text-pink-400';
      case 'linkedin':
        return 'text-blue-700 dark:text-blue-500';
      default:
        return 'text-gray-600 dark:text-gray-400';
    }
  };

  return (
    <Icon 
      className={`${className} ${getIconColor(platform)}`} 
      {...props} 
    />
  );
}