import React from 'react';

interface Platform {
  id: string;
  name: string;
}

interface PlatformFilterProps {
  platforms: Platform[];
  selected: string[];
  onChange: (platforms: string[]) => void;
}

export function PlatformFilter({ platforms, selected, onChange }: PlatformFilterProps) {
  const togglePlatform = (platformId: string) => {
    if (selected.includes(platformId)) {
      onChange(selected.filter(id => id !== platformId));
    } else {
      onChange([...selected, platformId]);
    }
  };

  return (
    <div className="flex flex-wrap gap-2">
      {platforms.map(platform => (
        <button
          key={platform.id}
          onClick={() => togglePlatform(platform.id)}
          className={`px-3 py-1.5 rounded-full text-sm transition-colors ${
            selected.includes(platform.id)
              ? 'bg-indigo-100 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400'
              : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300'
          }`}
        >
          {platform.name}
        </button>
      ))}
    </div>
  );
}