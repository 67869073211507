import { useCallback } from 'react';
import apiClient from '../lib/apiClient';
import { useAuthStore } from '../stores/authStore';
import { useNavigate } from 'react-router-dom';

export function useApi() {
  const navigate = useNavigate();
  const { logout } = useAuthStore();

  const handleError = useCallback((error: any) => {
    if (error.response?.status === 401) {
      logout();
      navigate('/login');
    }
    throw error;
  }, [logout, navigate]);

  const get = useCallback(async <T = any>(url: string) => {
    try {
      const response = await apiClient.get<T>(url);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }, [handleError]);

  const post = useCallback(async <T = any>(url: string, data?: any) => {
    try {
      const response = await apiClient.post<T>(url, data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }, [handleError]);

  const put = useCallback(async <T = any>(url: string, data?: any) => {
    try {
      const response = await apiClient.put<T>(url, data);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }, [handleError]);

  const del = useCallback(async <T = any>(url: string) => {
    try {
      const response = await apiClient.delete<T>(url);
      return response.data;
    } catch (error) {
      return handleError(error);
    }
  }, [handleError]);

  return {
    get,
    post,
    put,
    delete: del
  };
}