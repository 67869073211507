import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Code, Copy, Check, FileText, Terminal, Package } from 'lucide-react';

export function ApiDocs() {
  const { t } = useTranslation('pages');
  const [copiedEndpoint, setCopiedEndpoint] = useState<string | null>(null);

  const handleCopyCode = (code: string, endpoint: string) => {
    navigator.clipboard.writeText(code);
    setCopiedEndpoint(endpoint);
    setTimeout(() => setCopiedEndpoint(null), 2000);
  };

  const endpoints = [
    {
      method: 'GET',
      path: '/api/posts',
      description: 'Get all posts',
      parameters: [
        { name: 'platform', type: 'string', description: 'Filter by platform' },
        { name: 'status', type: 'string', description: 'Filter by status' },
        { name: 'page', type: 'number', description: 'Page number' },
        { name: 'limit', type: 'number', description: 'Items per page' }
      ],
      response: {
        posts: [{ id: 'string', content: 'string', platform: 'string' }],
        total: 'number',
        page: 'number'
      }
    },
    {
      method: 'POST',
      path: '/api/posts',
      description: 'Create a new post',
      parameters: [
        { name: 'content', type: 'string', description: 'Post content' },
        { name: 'platform', type: 'string', description: 'Target platform' },
        { name: 'scheduledDate', type: 'string', description: 'Optional scheduling date' }
      ],
      response: {
        id: 'string',
        content: 'string',
        platform: 'string',
        status: 'string'
      }
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      {/* Hero Section */}
      <div className="container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center max-w-3xl mx-auto mb-16"
        >
          <h1 className="text-4xl font-bold mb-6 dark:text-white">
            {t('api.title')}
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">
            {t('api.subtitle')}
          </p>
        </motion.div>

        {/* Quick Start */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8">
            <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
              <Terminal className="w-6 h-6 text-indigo-500" />
              {t('api.quickstart.title')}
            </h2>
            <p className="text-gray-600 dark:text-gray-300 mb-6">
              {t('api.quickstart.description')}
            </p>
            <div className="bg-gray-900 rounded-lg p-4 relative">
              <button
                onClick={() => handleCopyCode('npm install @acerfi/social-hub-sdk', 'install')}
                className="absolute top-4 right-4 text-gray-400 hover:text-white"
              >
                {copiedEndpoint === 'install' ? (
                  <Check className="w-5 h-5 text-green-500" />
                ) : (
                  <Copy className="w-5 h-5" />
                )}
              </button>
              <code className="text-green-400">$ npm install @acerfi/social-hub-sdk</code>
            </div>
          </div>
        </motion.div>

        {/* Endpoints */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-2xl font-bold mb-8 flex items-center gap-2">
            <Code className="w-6 h-6 text-indigo-500" />
            {t('api.endpoints.title')}
          </h2>

          <div className="space-y-8">
            {endpoints.map((endpoint, index) => (
              <div
                key={`${endpoint.method}-${endpoint.path}`}
                className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden"
              >
                <div className="p-6 border-b dark:border-gray-700">
                  <div className="flex items-start justify-between">
                    <div>
                      <div className="flex items-center gap-2 mb-2">
                        <span className={`px-2 py-1 rounded text-xs font-medium ${
                          endpoint.method === 'GET' 
                            ? 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400'
                            : 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400'
                        }`}>
                          {endpoint.method}
                        </span>
                        <code className="text-sm">{endpoint.path}</code>
                      </div>
                      <p className="text-gray-600 dark:text-gray-300">
                        {endpoint.description}
                      </p>
                    </div>
                    <button
                      onClick={() => handleCopyCode(endpoint.path, endpoint.path)}
                      className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-200"
                    >
                      {copiedEndpoint === endpoint.path ? (
                        <Check className="w-5 h-5 text-green-500" />
                      ) : (
                        <Copy className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>

                <div className="p-6 bg-gray-50 dark:bg-gray-800/50">
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <h4 className="text-sm font-medium mb-2">
                        {t('api.endpoints.parameters')}
                      </h4>
                      <div className="space-y-2">
                        {endpoint.parameters.map(param => (
                          <div key={param.name} className="text-sm">
                            <code className="text-indigo-600 dark:text-indigo-400">
                              {param.name}
                            </code>
                            <span className="text-gray-500 dark:text-gray-400">
                              : {param.type}
                            </span>
                            <p className="text-gray-600 dark:text-gray-300 text-xs mt-1">
                              {param.description}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium mb-2">
                        {t('api.endpoints.response')}
                      </h4>
                      <pre className="bg-gray-900 rounded p-4 text-xs">
                        <code className="text-green-400">
                          {JSON.stringify(endpoint.response, null, 2)}
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </motion.div>

        {/* SDKs */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="max-w-4xl mx-auto mt-16"
        >
          <h2 className="text-2xl font-bold mb-8 flex items-center gap-2">
            <Package className="w-6 h-6 text-indigo-500" />
            {t('api.sdks.title')}
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
              <h3 className="text-lg font-semibold mb-4">JavaScript/TypeScript</h3>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                {t('api.sdks.javascript')}
              </p>
              <div className="bg-gray-900 rounded p-4">
                <code className="text-green-400">
                  npm install @acerfi/social-hub-js
                </code>
              </div>
            </div>

            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
              <h3 className="text-lg font-semibold mb-4">Python</h3>
              <p className="text-gray-600 dark:text-gray-300 mb-4">
                {t('api.sdks.python')}
              </p>
              <div className="bg-gray-900 rounded p-4">
                <code className="text-green-400">
                  pip install acerfi-social-hub
                </code>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}