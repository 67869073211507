import React, { useState, useRef } from 'react';
import { usePosts } from '../hooks/usePosts';
import { useQueryClient } from '@tanstack/react-query';
import { 
  Smile, 
  Send, 
  X, 
  Loader2, 
  AlertCircle,
  Brain,
  MessageSquare
} from 'lucide-react';
import { EmojiPicker } from './EmojiPicker';
import { PostSuggestions } from './suggestions/PostSuggestions';
import { AISettingsPanel } from './suggestions/ui/AISettings';
import { useAISettings } from './suggestions/hooks/useAISettings';
import { toast } from 'react-hot-toast';

interface PostUpdateDialogProps {
  post: {
    _id: string;
    content: string;
    platform: string;
    metadata?: {
      pageId?: string;
      pageName?: string;
    }
  };
  isOpen: boolean;
  onClose: () => void;
}

export function PostUpdateDialog({ post, isOpen, onClose }: PostUpdateDialogProps) {
  const [content, setContent] = useState(post.content);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showAISettings, setShowAISettings] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const queryClient = useQueryClient();
  const { update } = usePosts();
  const { settings, updateSettings } = useAISettings();

  const handleEmojiSelect = (emoji: string) => {
    if (textareaRef.current) {
      const start = textareaRef.current.selectionStart;
      const end = textareaRef.current.selectionEnd;
      const newContent = content.substring(0, start) + emoji + content.substring(end);
      setContent(newContent);
      
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
          textareaRef.current.selectionStart = start + emoji.length;
          textareaRef.current.selectionEnd = start + emoji.length;
        }
      }, 0);
    }
    setShowEmojiPicker(false);
  };

  const handleUpdate = async () => {
    if (!content.trim()) {
      setError('Le contenu est requis');
      return;
    }

    if (!window.confirm('Êtes-vous sûr de vouloir mettre à jour cette publication ?')) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      await update.mutateAsync({
        id: post._id,
        data: { content }
      });

      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['post', post._id] });

      toast.success('Publication mise à jour avec succès');
      onClose();
    } catch (error) {
      console.error('Update error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Une erreur est survenue';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <h2 className="text-xl font-semibold flex items-center gap-2">
            <MessageSquare className="w-5 h-5 text-indigo-500" />
            Modifier la publication
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-500 hover:text-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Scrollable content */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {error && (
            <div className="bg-red-50 dark:bg-red-900/30 text-red-600 dark:text-red-400 p-3 rounded-lg flex items-center gap-2">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              {error}
            </div>
          )}

          {/* Content editor */}
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Contenu
              </label>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setShowAISettings(true)}
                  className="p-2 text-gray-500 hover:text-indigo-600 dark:hover:text-indigo-400 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  title="Paramètres IA"
                >
                  <Brain className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="p-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-200 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <Smile className="w-5 h-5" />
                </button>
              </div>
            </div>
            
            <div className="relative">
              <textarea
                ref={textareaRef}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                rows={6}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-none"
                placeholder="Que souhaitez-vous partager ?"
              />
              {showEmojiPicker && (
                <div className="absolute top-full right-0 mt-2 z-50">
                  <EmojiPicker
                    onSelect={handleEmojiSelect}
                    onClose={() => setShowEmojiPicker(false)}
                  />
                </div>
              )}
            </div>
          </div>

          {/* AI Suggestions */}
          {showSuggestions && (
            <div className="space-y-4">
              <PostSuggestions
                content={content}
                platforms={[post.platform]}
                onSelect={setContent}
                settings={settings}
                context={[
                  `Platform: ${post.platform}`,
                  post.metadata?.pageName && `Page: ${post.metadata.pageName}`,
                  'Type: update'
                ].filter(Boolean)}
              />
            </div>
          )}
        </div>

        {/* Actions */}
        <div className="p-4 border-t dark:border-gray-700 bg-gray-50 dark:bg-gray-800/50">
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
            >
              Annuler
            </button>
            <button
              onClick={handleUpdate}
              disabled={isLoading}
              className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isLoading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Send className="w-5 h-5" />
              )}
              Mettre à jour
            </button>
          </div>
        </div>
      </div>

      {/* AI Settings Panel */}
      <AISettingsPanel
        isOpen={showAISettings}
        onClose={() => setShowAISettings(false)}
        settings={settings}
        onUpdate={updateSettings}
        onSave={() => setShowAISettings(false)}
      />
    </div>
  );
}