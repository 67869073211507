import React from 'react';
import { Calendar } from '../../components/ui/calendar';
import { Button } from '../../components/ui/button';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';

interface DateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: { from: Date | null; to: Date | null }) => void;
}

export const PostDateRangePicker = ({
  startDate,
  endDate,
  onChange
}: DateRangePickerProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const formatDate = (date: Date | null) => {
    if (!date) return '';
    return format(date, 'dd MMM yyyy', { locale: fr });
  };

  const handleSelect = (range: { from: Date | null; to: Date | null }) => {
    onChange(range);
    if (range.to) {
      setIsOpen(false);
    }
  };

  const clearDates = () => {
    onChange({ from: null, to: null });
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <Button 
        variant="outline" 
        className="w-full justify-start text-left font-normal"
        onClick={() => setIsOpen(!isOpen)}
      >
        <CalendarIcon className="mr-2 h-4 w-4" />
        {startDate ? (
          endDate ? (
            <>
              {formatDate(startDate)} - {formatDate(endDate)}
            </>
          ) : (
            formatDate(startDate)
          )
        ) : (
          "Sélectionner les dates"
        )}
      </Button>

      {isOpen && (
        <div className="absolute z-50 mt-2">
          <Calendar
            mode="range"
            selected={{
              from: startDate,
              to: endDate
            }}
            onSelect={handleSelect}
            initialFocus
          />
          <div className="p-3 border-t border-gray-200 dark:border-gray-700">
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={clearDates}
              className="text-sm"
            >
              Réinitialiser
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};