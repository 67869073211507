import React, { useState } from 'react';
import { Calendar as CalendarIcon, Clock, Brain } from 'lucide-react';
import { CalendarView } from '../components/calendar/CalendarView';
import { OptimalTimesAnalysis } from '../components/OptimalTimesAnalysis';
import { PlatformFilter } from '../components/PlatformFilter';
import { CampaignPlanner } from '../components/calendar/CampaignPlanner';
import { useSocialAccounts } from '../hooks/useSocialAccounts';
import { usePosts } from '../hooks/usePosts';
import { Post } from '../lib/posts';
import { toast } from 'react-hot-toast';

export function Calendar() {
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [showOptimalTimes, setShowOptimalTimes] = useState(false);
  const [showCampaignPlanner, setShowCampaignPlanner] = useState(false);

  const { useAccounts } = useSocialAccounts();
  const { data: accounts = [] } = useAccounts();
  const { usePostsList, update, create } = usePosts();

  // Get scheduled posts
  const { data: postsData, isLoading } = usePostsList({
    status: 'scheduled',
    platform: selectedPlatforms.length === 1 ? selectedPlatforms[0] : undefined,
    pageId: selectedPageId
  });

  // Get Facebook pages if Facebook is selected
  const availablePages = React.useMemo(() => {
    if (selectedPlatforms.length === 1 && selectedPlatforms[0] === 'facebook') {
      const account = accounts.find(a => a.platform === 'facebook');
      return account?.pages || [];
    }
    return [];
  }, [accounts, selectedPlatforms]);

  const handlePostUpdate = async (postId: string, updates: Partial<Post>) => {
    try {
      await update.mutateAsync({ id: postId, data: updates });
      toast.success('Publication mise à jour');
    } catch (error) {
      console.error('Update error:', error);
      toast.error('Erreur lors de la mise à jour');
      throw error;
    }
  };

  const handlePostCreate = async (data: Partial<Post>) => {
    try {
      await create.mutateAsync(data);
      toast.success('Publication créée');
    } catch (error) {
      console.error('Create error:', error);
      toast.error('Erreur lors de la création');
      throw error;
    }
  };

  const handleCampaignPlan = async (posts: Array<{
    content: string;
    scheduledDate: Date;
    platform: string;
    pageId?: string;
    media?: Array<{
      type: 'image' | 'video';
      prompt: string;
    }>;
  }>) => {
    try {
      console.log('Creating campaign posts:', posts);
      
      // Format posts for creation
      const formattedPosts = posts.map(post => ({
        content: post.content,
        platform: post.platform,
        scheduledDate: post.scheduledDate,
        pageId: post.platform === 'facebook' ? post.pageId : undefined,
        media: post.media,
        status: 'scheduled'
      }));

      console.log('Formatted posts to create:', formattedPosts);

      // Create each post
      await Promise.all(
        formattedPosts.map(async (post) => {
          console.log('Creating individual post:', post);
          await create.mutateAsync(post);
        })
      );

      toast.success(`${posts.length} publications planifiées`);
      setShowCampaignPlanner(false);
    } catch (error) {
      console.error('Campaign creation error:', error);
      toast.error('Erreur lors de la création de la campagne');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Header */}
      <div className="sticky top-0 z-20 bg-white dark:bg-gray-800 border-b dark:border-gray-700 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold flex items-center gap-2">
                <CalendarIcon className="w-6 h-6 text-indigo-500" />
                Calendrier de publication
              </h1>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Planifiez et organisez vos publications
              </p>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={() => setShowOptimalTimes(true)}
                className="flex items-center gap-2 px-3 py-2 text-sm text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/30 rounded-lg"
              >
                <Clock className="w-4 h-4" />
                Horaires optimaux
              </button>
              <button
                onClick={() => setShowCampaignPlanner(true)}
                className="flex items-center gap-2 px-3 py-2 text-sm text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/30 rounded-lg"
              >
                <Brain className="w-4 h-4" />
                Planification IA
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Filters */}
        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm mb-6">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <PlatformFilter
                platforms={accounts.map(a => ({ id: a.platform, name: a.platform }))}
                selected={selectedPlatforms}
                onChange={setSelectedPlatforms}
              />
            </div>
            {availablePages.length > 0 && (
              <div className="sm:w-64">
                <select
                  value={selectedPageId || ''}
                  onChange={(e) => setSelectedPageId(e.target.value || null)}
                  className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
                >
                  <option value="">Toutes les pages</option>
                  {availablePages.map(page => (
                    <option key={page.id} value={page.id}>
                      {page.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Calendar */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
          <CalendarView
            posts={postsData?.posts || []}
            platforms={accounts.map(a => a.platform)}
            selectedPlatform={selectedPlatforms[0]}
            selectedPageId={selectedPageId}
            onPostUpdate={handlePostUpdate}
            onPostCreate={handlePostCreate}
          />
        </div>
      </div>

      {/* Optimal times analysis */}
      {showOptimalTimes && (
        <OptimalTimesAnalysis
          platforms={selectedPlatforms}
          onClose={() => setShowOptimalTimes(false)}
        />
      )}

      {/* Campaign planner */}
      {showCampaignPlanner && (
        <CampaignPlanner
          platforms={selectedPlatforms.length > 0 ? selectedPlatforms : accounts.map(a => a.platform)}
          onPlan={handleCampaignPlan}
          onClose={() => setShowCampaignPlanner(false)}
        />
      )}
    </div>
  );
}