import React, { useState, useCallback } from 'react';
import { MessageSquare, Filter, Brain, BarChart2, Loader2 } from 'lucide-react';
import { CommentsList } from '../components/comments/CommentsList';
import { CommentFilters } from '../components/comments/CommentFilters';
import { CommentAnalytics } from '../components/comments/CommentAnalytics';
import { GlobalAISettings } from '../components/suggestions/ui/GlobalAISettings';
import { useComments } from '../hooks/useComments';
import { useSearchParams } from 'react-router-dom';
import { useAISettings } from '../components/suggestions/hooks/useAISettings';
import { toast } from 'react-hot-toast';
import { debounce } from 'lodash';

const COMMENTS_PER_PAGE = 20;

export function Comments() {
  // États locaux
  const [showFilters, setShowFilters] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [showAISettings, setShowAISettings] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Hooks IA
  const { settings, updateSettings } = useAISettings();

  // Récupération des filtres depuis l'URL
  const filters = {
    platform: searchParams.get('platform') || 'all',
    pageId: searchParams.get('pageId'),
    sentiment: searchParams.get('sentiment') || 'all',
    status: searchParams.get('status') || 'all',
    search: searchParams.get('search') || '',
    sortBy: searchParams.get('sortBy') || 'date',
    sortOrder: searchParams.get('sortOrder') || 'desc',
    hasReplies: searchParams.get('hasReplies') === 'true' ? true : 
                searchParams.get('hasReplies') === 'false' ? false : undefined,
    isAiGenerated: searchParams.get('isAiGenerated') === 'true' ? true :
                   searchParams.get('isAiGenerated') === 'false' ? false : undefined,
    startDate: searchParams.get('startDate'),
    endDate: searchParams.get('endDate')
  };

  const currentPage = Number(searchParams.get('page')) || 1;

  // Hooks
  const { useCommentsList, replyToComment } = useComments();
  const { data, isLoading, error } = useCommentsList({
    ...filters,
    page: currentPage,
    limit: COMMENTS_PER_PAGE
  });

  // Gestion des filtres
  const updateSearchParams = useCallback((updates: Record<string, any>) => {
    const newParams = new URLSearchParams(searchParams);
    
    Object.entries(updates).forEach(([key, value]) => {
      if (key === 'startDate' || key === 'endDate') {
        if (value) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      } else {
        if (value === null || value === undefined || value === '' || value === 'all') {
          newParams.delete(key);
        } else {
          newParams.set(key, String(value));
        }
      }
    });

    setSearchParams(newParams);
  }, [searchParams, setSearchParams]);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      updateSearchParams({ search: value, page: 1 });
    }, 300),
    [updateSearchParams]
  );

  const handleFilterChange = useCallback((key: string, value: any) => {
    if (key === 'search') {
      debouncedSearch(value);
    } else if (key === 'platform' && value !== 'facebook') {
      updateSearchParams({ [key]: value, pageId: null, page: 1 });
    } else {
      updateSearchParams({ [key]: value, page: 1 });
    }
  }, [updateSearchParams, debouncedSearch]);

  const handleResetFilters = useCallback(() => {
    setSearchParams(new URLSearchParams());
  }, [setSearchParams]);

  // Gestion des réponses automatiques
  const handleAutoResponse = async (commentId: string) => {
    if (!settings.enabled) {
      toast.error("L'IA est désactivée dans les paramètres");
      return;
    }

    try {
      await replyToComment.mutateAsync({
        commentId,
        content: '',
        useAI: true
      });
      toast.success('Réponse automatique envoyée');
    } catch (error) {
      console.error('Auto-response error:', error);
      toast.error('Erreur lors de la génération de la réponse');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Header */}
      <div className="bg-white dark:bg-gray-800 border-b dark:border-gray-700 sticky top-0 z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-2xl font-bold flex items-center gap-2">
                <MessageSquare className="w-6 h-6 text-indigo-500" />
                Gestion des commentaires
              </h1>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {data?.filteredTotal || 0} commentaires trouvés sur {data?.total || 0} au total
              </p>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className={`p-2 rounded-lg transition-colors ${
                  showFilters
                    ? 'bg-indigo-100 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                    : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                <Filter className="w-5 h-5" />
              </button>
              <button
                onClick={() => setShowAnalytics(!showAnalytics)}
                className={`p-2 rounded-lg transition-colors ${
                  showAnalytics
                    ? 'bg-indigo-100 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                    : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                <BarChart2 className="w-5 h-5" />
              </button>
              <button
                onClick={() => setShowAISettings(!showAISettings)}
                className={`p-2 rounded-lg transition-colors ${
                  showAISettings
                    ? 'bg-indigo-100 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                    : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                <Brain className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Filtres */}
          {showFilters && (
            <div className="lg:col-span-1">
              <CommentFilters
                filters={filters}
                onChange={handleFilterChange}
                onReset={handleResetFilters}
              />
            </div>
          )}

          {/* Liste des commentaires */}
          <div className={showFilters ? 'lg:col-span-3' : 'lg:col-span-4'}>
            {isLoading ? (
              <div className="flex items-center justify-center py-12">
                <Loader2 className="w-8 h-8 animate-spin text-indigo-500" />
              </div>
            ) : error ? (
              <div className="text-center py-12 text-red-500">
                Une erreur est survenue lors du chargement des commentaires
              </div>
            ) : data?.comments && data.comments.length > 0 ? (
              <div className="space-y-6">
                <CommentsList
                  comments={data.comments}
                  onAutoResponse={handleAutoResponse}
                />
                
                {/* Pagination */}
                {data.totalPages > 1 && (
                  <div className="flex items-center justify-between pt-4 border-t dark:border-gray-700">
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-600 dark:text-gray-400">
                        Page {currentPage} sur {data.totalPages}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => updateSearchParams({ 
                          page: Math.max(1, currentPage - 1) 
                        })}
                        disabled={currentPage === 1}
                        className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg disabled:opacity-50"
                      >
                        Précédent
                      </button>
                      <button
                        onClick={() => updateSearchParams({ 
                          page: Math.min(data.totalPages, currentPage + 1) 
                        })}
                        disabled={currentPage === data.totalPages}
                        className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg disabled:opacity-50"
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center py-12 text-gray-500 dark:text-gray-400">
                Aucun commentaire trouvé
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Composants modaux */}
      {showAnalytics && (
        <CommentAnalytics onClose={() => setShowAnalytics(false)} />
      )}

      <GlobalAISettings
        isOpen={showAISettings}
        onClose={() => setShowAISettings(false)}
        settings={settings}
        onUpdate={updateSettings}
        onSave={() => setShowAISettings(false)}
      />
    </div>
  );
}