import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthStore } from './stores/authStore';
import { Layout } from './components/Layout';
import { MarketingLayout } from './components/marketing/MarketingLayout';

// Marketing Pages
import { Home } from './pages/marketing/Home';
import { Features } from './pages/marketing/Features';
import { Pricing } from './pages/marketing/Pricing';
import { About } from './pages/marketing/About';
import { Contact } from './pages/marketing/Contact';
import { Blog } from './pages/marketing/Blog';
import { ApiDocs } from './pages/marketing/ApiDocs';
import { Status } from './pages/marketing/Status';
import { Privacy } from './pages/marketing/Privacy';
import { Terms } from './pages/marketing/Terms';
import { Documentation } from './pages/Documentation';

// Auth Pages
import { Login } from './pages/Login';
import { Signup } from './pages/Signup';

// App Pages
import { Dashboard } from './pages/Dashboard';
import { Posts } from './pages/Posts';
import { Comments } from './pages/Comments';
import { Messages } from './pages/Messages';
import { Calendar } from './pages/Calendar';
import { Analytics } from './pages/Analytics';
import { Settings } from './pages/Settings';
import { Teams } from './pages/Teams';

export default function App() {
  const { isAuthenticated } = useAuthStore();

  return (
    <Router>
      <Routes>
        {/* Marketing Routes */}
        <Route element={<MarketingLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/api" element={<ApiDocs />} />
          <Route path="/status" element={<Status />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/documentation" element={<Documentation />} />
        </Route>

        {/* Auth Routes */}
        <Route path="/login" element={
          isAuthenticated ? <Navigate to="/app" /> : <Login />
        } />
        <Route path="/signup" element={
          isAuthenticated ? <Navigate to="/app" /> : <Signup />
        } />

        {/* Protected App Routes */}
        <Route path="/app" element={
          isAuthenticated ? <Layout /> : <Navigate to="/login" />
        }>
          <Route index element={<Dashboard />} />
          <Route path="posts" element={<Posts />} />
          <Route path="comments" element={<Comments />} />
          <Route path="messages" element={<Messages />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="settings" element={<Settings />} />
          <Route path="teams" element={<Teams />} />
        </Route>

        {/* Catch-all redirect */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}