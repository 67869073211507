import React from 'react';
import { Brain, X, MessageCircle, Settings2, Save } from 'lucide-react';
import { AISettings } from '../types/AITypes';
import { toast } from 'react-hot-toast';

interface GlobalAISettingsProps {
  isOpen: boolean;
  onClose: () => void;
  settings: AISettings;
  onUpdate: (settings: Partial<AISettings>) => void;
  onSave: () => void;
}

export function GlobalAISettings({
  isOpen,
  onClose,
  settings,
  onUpdate,
  onSave
}: GlobalAISettingsProps) {
  if (!isOpen) return null;

  const handleSave = () => {
    onSave();
    toast.success('Paramètres IA sauvegardés');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center p-4 border-b dark:border-gray-700">
          <h2 className="text-lg font-medium flex items-center gap-2">
            <Brain className="w-5 h-5 text-indigo-500" />
            Paramètres globaux de l'IA
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4 space-y-4">
          {/* Activation générale */}
          <div className="flex items-center justify-between">
            <label className="flex items-center gap-2">
              <Brain className="w-5 h-5 text-indigo-500" />
              Activer l'IA
            </label>
            <input
              type="checkbox"
              checked={settings.enabled}
              onChange={(e) => onUpdate({ enabled: e.target.checked })}
              className="toggle"
            />
          </div>

          {/* Modération automatique */}
          <div className="flex items-center justify-between">
            <label className="flex items-center gap-2">
              <Settings2 className="w-5 h-5 text-indigo-500" />
              Modération automatique
            </label>
            <input
              type="checkbox"
              checked={settings.autoModerate}
              onChange={(e) => onUpdate({ autoModerate: e.target.checked })}
              className="toggle"
              disabled={!settings.enabled}
            />
          </div>

          {/* Réponses automatiques */}
          <div className="flex items-center justify-between">
            <label className="flex items-center gap-2">
              <MessageCircle className="w-5 h-5 text-indigo-500" />
              Suggestions automatiques
            </label>
            <input
              type="checkbox"
              checked={settings.autoRespond}
              onChange={(e) => onUpdate({ autoRespond: e.target.checked })}
              className="toggle"
              disabled={!settings.enabled}
            />
          </div>

          {/* Ton */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Ton</label>
            <select
              value={settings.tone}
              onChange={(e) => onUpdate({ tone: e.target.value })}
              className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
              disabled={!settings.enabled}
            >
              <option value="professional">Professionnel</option>
              <option value="casual">Décontracté</option>
              <option value="friendly">Amical</option>
              <option value="formal">Formel</option>
              <option value="empathetic">Empathique</option>
            </select>
          </div>

          {/* Style */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Style</label>
            <select
              value={settings.style}
              onChange={(e) => onUpdate({ style: e.target.value })}
              className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
              disabled={!settings.enabled}
            >
              <option value="informative">Informatif</option>
              <option value="conversational">Conversationnel</option>
              <option value="persuasive">Persuasif</option>
              <option value="engaging">Engageant</option>
            </select>
          </div>

          {/* Longueur */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Longueur</label>
            <select
              value={settings.length}
              onChange={(e) => onUpdate({ length: e.target.value })}
              className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
              disabled={!settings.enabled}
            >
              <option value="short">Court</option>
              <option value="medium">Moyen</option>
              <option value="long">Long</option>
            </select>
          </div>

          {/* Créativité */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">
              Créativité: {Math.round(settings.creativity * 100)}%
            </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={settings.creativity}
              onChange={(e) => onUpdate({ creativity: parseFloat(e.target.value) })}
              className="w-full"
              disabled={!settings.enabled}
            />
            <div className="flex justify-between text-xs text-gray-500">
              <span>Conservateur</span>
              <span>Créatif</span>
            </div>
          </div>

          {/* Seuil de confiance */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">
              Seuil de confiance minimum
            </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={settings.confidenceThreshold}
              onChange={(e) => onUpdate({ confidenceThreshold: parseFloat(e.target.value) })}
              className="w-full"
              disabled={!settings.enabled}
            />
            <div className="flex justify-between text-xs text-gray-500">
              <span>0% (Permissif)</span>
              <span>100% (Strict)</span>
            </div>
          </div>

          {/* Options */}
          <div className="space-y-2">
            <label className="block text-sm font-medium">Options</label>
            <div className="space-y-2">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={settings.useHashtags}
                  onChange={(e) => onUpdate({ useHashtags: e.target.checked })}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  disabled={!settings.enabled}
                />
                <span className="text-sm">Utiliser des hashtags</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={settings.useMentions}
                  onChange={(e) => onUpdate({ useMentions: e.target.checked })}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  disabled={!settings.enabled}
                />
                <span className="text-sm">Utiliser des mentions</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={settings.useEmojis}
                  onChange={(e) => onUpdate({ useEmojis: e.target.checked })}
                  className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  disabled={!settings.enabled}
                />
                <span className="text-sm">Utiliser des emojis</span>
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3 p-4 border-t dark:border-gray-700">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
          >
            Annuler
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            Sauvegarder
          </button>
        </div>
      </div>
    </div>
  );
}