import React from 'react';
import { motion } from 'framer-motion';
import { Shield } from 'lucide-react';

export function Privacy() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <div className="container mx-auto px-4 py-20">
        <div className="max-w-3xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <Shield className="w-12 h-12 text-indigo-600 mx-auto mb-4" />
            <h1 className="text-4xl font-bold mb-4 dark:text-white">
              Politique de confidentialité
            </h1>
            <p className="text-gray-600 dark:text-gray-300">
              Dernière mise à jour : {new Date().toLocaleDateString()}
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="prose prose-lg max-w-none dark:prose-invert"
          >
            <h2>Introduction</h2>
            <p>
              Acerfi Social Hub s'engage à protéger la vie privée des utilisateurs de notre plateforme. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations personnelles.
            </p>

            <h2>Collecte des données</h2>
            <p>
              Nous collectons les informations suivantes :
            </p>
            <ul>
              <li>Informations de compte (nom, email, etc.)</li>
              <li>Données d'utilisation de la plateforme</li>
              <li>Informations de paiement</li>
              <li>Données des réseaux sociaux connectés</li>
            </ul>

            <h2>Utilisation des données</h2>
            <p>
              Nous utilisons vos données pour :
            </p>
            <ul>
              <li>Fournir et améliorer nos services</li>
              <li>Personnaliser votre expérience</li>
              <li>Communiquer avec vous</li>
              <li>Assurer la sécurité de votre compte</li>
            </ul>

            <h2>Protection des données</h2>
            <p>
              Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos données personnelles contre tout accès non autorisé, modification, divulgation ou destruction.
            </p>

            <h2>Vos droits</h2>
            <p>
              Vous avez le droit de :
            </p>
            <ul>
              <li>Accéder à vos données personnelles</li>
              <li>Rectifier vos données</li>
              <li>Supprimer vos données</li>
              <li>Limiter le traitement</li>
              <li>Porter vos données</li>
              <li>Vous opposer au traitement</li>
            </ul>

            <h2>Contact</h2>
            <p>
              Pour toute question concernant cette politique de confidentialité, vous pouvez nous contacter à :
            </p>
            <ul>
              <li>Email : info@acerfi.net</li>
              <li>Téléphone : +237 695 08 08 08</li>
            </ul>
          </motion.div>
        </div>
      </div>
    </div>
  );
}