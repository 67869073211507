import React, { useState } from 'react';
import { MessageSquare, Filter } from 'lucide-react';
import { MessagesList } from './MessagesList';
import { MessageFilters } from './MessageFilters';
import { MessageLabels } from './MessageLabels';
import { AutoResponse } from './AutoResponse';
import { useMessages } from '../hooks/useMessages';

const DEFAULT_LABELS = [
  { id: 'urgent', name: 'Urgent', color: 'red' },
  { id: 'support', name: 'Support', color: 'blue' },
  { id: 'feedback', name: 'Feedback', color: 'green' },
  { id: 'bug', name: 'Bug', color: 'orange' },
];

export function UnifiedInbox() {
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    labels: [],
    date: 'all',
    priority: 'all',
  });
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);

  const handleFilterChange = (key: string, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const toggleLabel = (labelId: string) => {
    setSelectedLabels((prev) =>
      prev.includes(labelId)
        ? prev.filter((id) => id !== labelId)
        : [...prev, labelId]
    );
  };

  return (
    <div className="h-full flex">
      {/* Filtres */}
      {showFilters && (
        <div className="w-64 border-r border-gray-200 dark:border-gray-700 p-4">
          <MessageFilters
            filters={filters}
            onFilterChange={handleFilterChange}
          />
        </div>
      )}

      {/* Messages */}
      <div className="flex-1 overflow-auto">
        <div className="p-4">
          {/* En-tête */}
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-bold flex items-center gap-2">
              <MessageSquare className="w-6 h-6 text-indigo-500" />
              Messages
            </h1>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className={`p-2 rounded-lg transition-colors ${
                showFilters
                  ? 'bg-indigo-100 text-indigo-600 dark:bg-indigo-900/30 dark:text-indigo-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
              }`}
            >
              <Filter className="w-5 h-5" />
            </button>
          </div>

          {/* Étiquettes */}
          <div className="mb-4">
            <MessageLabels
              labels={DEFAULT_LABELS}
              selectedLabels={selectedLabels}
              onToggleLabel={toggleLabel}
              onCreateLabel={() => {
                // TODO: Implémenter la création d'étiquettes
              }}
            />
          </div>

          {/* Liste des messages */}
          <MessagesList
            filters={filters}
            selectedLabels={selectedLabels}
          />
        </div>
      </div>
    </div>
  );
}