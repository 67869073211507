import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';

interface MessagePriority {
  score: number;
  factors: {
    sentiment: number;
    urgency: number;
    engagement: number;
    customerValue: number;
    responseTime: number;
  };
}

interface Message {
  id: string;
  content: string;
  platform: string;
  status: string;
  createdAt: string;
  priority: MessagePriority;
}

export function useMessages(platform?: string) {
  const { token } = useAuthStore();
  const queryClient = useQueryClient();
  const apiUrl = import.meta.env.VITE_API_URL;

  const useMessagesList = () => {
    return useQuery({
      queryKey: ['messages', platform],
      queryFn: async () => {
        const response = await fetch(
          `${apiUrl}/messaging/messages${platform ? `?platform=${platform}` : ''}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) throw new Error('Failed to fetch messages');
        return response.json();
      },
      refetchInterval: 30000, // Rafraîchir toutes les 30 secondes
    });
  };

  return {
    useMessagesList,
  };
}