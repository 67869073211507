import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../stores/authStore';
import apiClient from '../lib/apiClient';

export function useProtectedRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, token, logout } = useAuthStore();

  useEffect(() => {
    const validateAuth = async () => {
      if (!token) {
        logout();
        navigate('/login', { 
          state: { from: location },
          replace: true 
        });
        return;
      }

      if (!isAuthenticated) {
        try {
          await apiClient.get('/auth/validate');
        } catch (error) {
          console.error('Auth validation error:', error);
          logout();
          navigate('/login', { 
            state: { from: location },
            replace: true 
          });
        }
      }
    };

    validateAuth();
  }, [token, isAuthenticated, logout, navigate, location]);

  return { isAuthenticated };
}