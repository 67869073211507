import React from 'react';
import { Save, Loader2 } from 'lucide-react';

interface SecuritySettingsProps {
  securityData: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
  isLoading: boolean;
  onUpdate: (data: any) => Promise<void>;
  onChange: (data: any) => void;
}

export function SecuritySettings({ securityData, isLoading, onUpdate, onChange }: SecuritySettingsProps) {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (securityData.newPassword !== securityData.confirmPassword) {
      return; // Handle error
    }
    await onUpdate(securityData);
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <h2 className="text-lg font-medium">Sécurité</h2>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-2">
            Mot de passe actuel
          </label>
          <input
            type="password"
            value={securityData.currentPassword}
            onChange={(e) => onChange({ ...securityData, currentPassword: e.target.value })}
            className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">
            Nouveau mot de passe
          </label>
          <input
            type="password"
            value={securityData.newPassword}
            onChange={(e) => onChange({ ...securityData, newPassword: e.target.value })}
            className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-2">
            Confirmer le nouveau mot de passe
          </label>
          <input
            type="password"
            value={securityData.confirmPassword}
            onChange={(e) => onChange({ ...securityData, confirmPassword: e.target.value })}
            className="w-full px-3 py-2 rounded-lg border dark:border-gray-600 bg-white dark:bg-gray-700"
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isLoading ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          Mettre à jour
        </button>
      </div>
    </form>
  );
}