import React from 'react';
import { X, TrendingUp, MessageCircle, Clock, Target } from 'lucide-react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { useComments } from '../../hooks/useComments';

interface CommentAnalyticsProps {
  onClose: () => void;
}

export function CommentAnalytics({ onClose }: CommentAnalyticsProps) {
  const { useAnalytics } = useComments();
  const { data: analytics, isLoading } = useAnalytics();

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="p-6 text-center">
            Chargement des analyses...
          </div>
        </div>
      </div>
    );
  }

  if (!analytics) {
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="p-6 text-center text-red-500">
            Erreur lors du chargement des analyses
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white dark:bg-gray-800 border-b dark:border-gray-700 p-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold flex items-center gap-2">
            <TrendingUp className="w-5 h-5 text-indigo-500" />
            Analyse des commentaires
          </h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Sentiment */}
          <div className="space-y-4">
            <h3 className="font-medium flex items-center gap-2">
              <Target className="w-4 h-4 text-indigo-500" />
              Sentiment
            </h3>
            <div className="aspect-square">
              <Pie
                data={{
                  labels: ['Positif', 'Neutre', 'Négatif'],
                  datasets: [
                    {
                      data: [
                        analytics.sentiment.positive,
                        analytics.sentiment.neutral,
                        analytics.sentiment.negative,
                      ],
                      backgroundColor: [
                        'rgba(34, 197, 94, 0.8)',
                        'rgba(234, 179, 8, 0.8)',
                        'rgba(239, 68, 68, 0.8)',
                      ],
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'bottom',
                    },
                  },
                }}
              />
            </div>
          </div>

          {/* Engagement */}
          <div className="space-y-4">
            <h3 className="font-medium flex items-center gap-2">
              <MessageCircle className="w-4 h-4 text-indigo-500" />
              Engagement
            </h3>
            <div className="space-y-4">
              <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg">
                <div className="text-2xl font-bold">
                  {analytics.engagement.totalComments}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Commentaires totaux
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg">
                <div className="text-2xl font-bold">
                  {analytics.engagement.uniqueUsers}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Utilisateurs uniques
                </div>
              </div>
              <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg">
                <div className="text-2xl font-bold">
                  {(analytics.engagement.responseRate * 100).toFixed(1)}%
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Taux de réponse
                </div>
              </div>
            </div>
          </div>

          {/* Topics */}
          <div className="space-y-4">
            <h3 className="font-medium">Sujets principaux</h3>
            <div className="space-y-2">
              {analytics.topics.map((topic) => (
                <div
                  key={topic.name}
                  className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700/50 rounded-lg"
                >
                  <span>{topic.name}</span>
                  <span className="px-2 py-1 bg-indigo-100 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400 rounded text-sm">
                    {topic.count}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Suggestions */}
          <div className="space-y-4">
            <h3 className="font-medium">Suggestions d'amélioration</h3>
            <div className="space-y-2">
              {analytics.suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg text-sm"
                >
                  {suggestion}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}