import React from 'react';
import { Save, Loader2 } from 'lucide-react';

interface NotificationSettingsProps {
  notificationSettings: {
    emailNotifications: boolean;
    pushNotifications: boolean;
    commentNotifications: boolean;
    messageNotifications: boolean;
    teamNotifications: boolean;
  };
  isLoading: boolean;
  onUpdate: (data: any) => Promise<void>;
  onChange: (data: any) => void;
}

export function NotificationSettings({ 
  notificationSettings, 
  isLoading, 
  onUpdate, 
  onChange 
}: NotificationSettingsProps) {
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onUpdate(notificationSettings);
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 space-y-6">
      <h2 className="text-lg font-medium">Préférences de notification</h2>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-medium">Notifications par email</p>
            <p className="text-sm text-gray-500">Recevoir les notifications importantes par email</p>
          </div>
          <input
            type="checkbox"
            checked={notificationSettings.emailNotifications}
            onChange={(e) => onChange({ 
              ...notificationSettings, 
              emailNotifications: e.target.checked 
            })}
            className="toggle"
          />
        </div>

        <div className="flex items-center justify-between">
          <div>
            <p className="font-medium">Notifications push</p>
            <p className="text-sm text-gray-500">Recevoir les notifications dans le navigateur</p>
          </div>
          <input
            type="checkbox"
            checked={notificationSettings.pushNotifications}
            onChange={(e) => onChange({ 
              ...notificationSettings, 
              pushNotifications: e.target.checked 
            })}
            className="toggle"
          />
        </div>

        <div className="flex items-center justify-between">
          <div>
            <p className="font-medium">Commentaires</p>
            <p className="text-sm text-gray-500">Notifications des nouveaux commentaires</p>
          </div>
          <input
            type="checkbox"
            checked={notificationSettings.commentNotifications}
            onChange={(e) => onChange({ 
              ...notificationSettings, 
              commentNotifications: e.target.checked 
            })}
            className="toggle"
          />
        </div>

        <div className="flex items-center justify-between">
          <div>
            <p className="font-medium">Messages</p>
            <p className="text-sm text-gray-500">Notifications des nouveaux messages</p>
          </div>
          <input
            type="checkbox"
            checked={notificationSettings.messageNotifications}
            onChange={(e) => onChange({ 
              ...notificationSettings, 
              messageNotifications: e.target.checked 
            })}
            className="toggle"
          />
        </div>

        <div className="flex items-center justify-between">
          <div>
            <p className="font-medium">Équipe</p>
            <p className="text-sm text-gray-500">Notifications des activités d'équipe</p>
          </div>
          <input
            type="checkbox"
            checked={notificationSettings.teamNotifications}
            onChange={(e) => onChange({ 
              ...notificationSettings, 
              teamNotifications: e.target.checked 
            })}
            className="toggle"
          />
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isLoading ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <Save className="w-4 h-4" />
          )}
          Enregistrer
        </button>
      </div>
    </form>
  );
}