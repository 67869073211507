import React, { useState, useRef } from 'react';
import { 
  MessageCircle, 
  ThumbsUp, 
  Reply, 
  Bot, 
  Loader2, 
  Flag, 
  EyeOff, 
  ExternalLink, 
  Settings2, 
  Brain,
  Smile,
  X 
} from 'lucide-react';
import { EmojiPicker } from '../EmojiPicker';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Comment } from '../../hooks/useComments';
import { useComments } from '../../hooks/useComments';
import { usePosts } from '../../hooks/usePosts';
import { PostPreviewDialog } from './PostPreviewDialog';
import { useModerationWebhook } from '../../hooks/useModerationWebhook';
import { contentManager } from '../../lib/ai/contentManager';
import { ModerationBadge } from '../ModerationBadge';
import { toast } from 'react-hot-toast';

import { 
  PostSuggestions,
  ReplySuggestions,
  useAISettings,
  AISettingsPanel 
} from '../suggestions';

interface CommentsListProps {
  comments: Comment[];
  onAutoResponse: (commentId: string) => Promise<void>;
}

interface AISettings {
  enabled: boolean;
  autoModerate: boolean;
  autoRespond: boolean;
  confidenceThreshold: number;
}

// Ajouter la gestion des paramètres IA en haut du composant
const defaultAISettings = {
  tone: 'professional',
  style: 'conversational',
  length: 'medium',
  creativity: 0.7,
  useHashtags: true,
  useMentions: true,
  useEmojis: true
};


export function CommentsList({ comments, onAutoResponse }: CommentsListProps) {
  // États pour la réponse
  const [replyingTo, setReplyingTo] = useState<string | null>(null);
  const [replyContent, setReplyContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState<string | null>(null);
  const [showPostPreview, setShowPostPreview] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showAISuggestions, setShowAISuggestions] = useState(true);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // États pour l'IA
  const [showAISettings, setShowAISettings] = useState(false);
  const [aiSettings, setAISettings] = useState<AISettings>(() => {
    const saved = localStorage.getItem('commentAISettings');
    return saved ? JSON.parse(saved) : {
      enabled: true,
      autoModerate: true,
      autoRespond: true,
      confidenceThreshold: 0.8
    };
  });

  const { replyToComment, updateCommentStatus } = useComments();
  const { usePost } = usePosts();
  const { data: selectedPost } = usePost(selectedPostId);
  const { moderateContent } = useModerationWebhook();

  // Sauvegarder les paramètres IA
  const saveAISettings = (settings: AISettings) => {
    localStorage.setItem('commentAISettings', JSON.stringify(settings));
    setAISettings(settings);
  };

  // Gestion des emojis
  const handleEmojiSelect = (emoji: string) => {
    if (textareaRef.current) {
      const start = textareaRef.current.selectionStart;
      const end = textareaRef.current.selectionEnd;
      const newContent = replyContent.substring(0, start) + emoji + replyContent.substring(end);
      setReplyContent(newContent);
      
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
          textareaRef.current.selectionStart = start + emoji.length;
          textareaRef.current.selectionEnd = start + emoji.length;
        }
      }, 0);
    }
    setShowEmojiPicker(false);
  };

  // Gérer la réponse avec modération IA
  const handleSubmitReply = async (commentId: string) => {
    if (!replyContent.trim()) return;

    setIsSubmitting(true);
    try {
      if (aiSettings.enabled && aiSettings.autoModerate) {
        const analysis = await contentManager.analyzeContent(replyContent);
        const moderation = await contentManager.shouldModerate(analysis);
        
        if (moderation.shouldRemove) {
          toast.error('Cette réponse a été bloquée par la modération automatique');
          setIsSubmitting(false);
          return;
        }
      }

      await replyToComment.mutateAsync({
        commentId,
        content: replyContent,
        useAI: false
      });
      toast.success('Réponse publiée avec succès');
      setReplyContent('');
      setReplyingTo(null);
    } catch (error) {
      console.error('Reply error:', error);
      toast.error('Erreur lors de la publication de la réponse');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Gérer la modération manuelle
  const handleStatusUpdate = async (commentId: string, status: 'flagged' | 'hidden') => {
    try {
      if (aiSettings.enabled && aiSettings.autoModerate) {
        await moderateContent('comment', commentId);
      }
      await updateCommentStatus.mutateAsync({ commentId, status });
      toast.success(
        status === 'flagged' ? 'Commentaire signalé' : 'Commentaire masqué'
      );
    } catch (error) {
      console.error('Status update error:', error);
      toast.error('Erreur lors de la mise à jour du statut');
    }
  };
  const handleViewPost = (comment: Comment) => {
    if (!comment.postId) {
      toast.error('ID du post non disponible');
      return;
    }

    const postId = typeof comment.postId === 'string' ? comment.postId : comment.postId._id;
    setSelectedPostId(postId);
    setShowPostPreview(true);
  };

  // Fonctions utilitaires
  const getSentimentColor = (sentiment?: string) => {
    switch (sentiment) {
      case 'positive':
        return 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400';
      case 'negative':
        return 'bg-red-100 text-red-700 dark:bg-red-900/30 dark:text-red-400';
      default:
        return 'bg-gray-100 text-gray-700 dark:bg-gray-900/30 dark:text-gray-400';
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'replied':
        return 'bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400';
      case 'flagged':
        return 'bg-yellow-100 text-yellow-700 dark:bg-yellow-900/30 dark:text-yellow-400';
      case 'hidden':
        return 'bg-red-100 text-red-700 dark:bg-red-900/30 dark:text-red-400';
      default:
        return 'bg-blue-100 text-blue-700 dark:bg-blue-900/30 dark:text-blue-400';
    }
  };

  return (
    <div className="space-y-6">
      {/* Configuration IA */}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => setShowAISettings(true)}
          className="flex items-center gap-2 px-4 py-2 text-sm bg-gray-100 dark:bg-gray-800 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-700"
        >
          <Bot className="w-4 h-4" />
          <span>Configuration IA</span>
          {aiSettings.enabled && (
            <span className="px-2 py-0.5 text-xs bg-green-100 text-green-700 dark:bg-green-900/30 dark:text-green-400 rounded-full">
              Activé
            </span>
          )}
        </button>
      </div>

      {/* Liste des commentaires */}
      {comments.map((comment) => (
        <div
          key={comment._id}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6"
        >
          {/* En-tête du commentaire */}
          <div className="flex items-start justify-between mb-4">
            <div className="flex items-center gap-3">
              {comment.author.avatar ? (
                <img
                  src={comment.author.avatar}
                  alt={comment.author.name || 'Utilisateur anonyme'}
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <div className="w-10 h-10 bg-indigo-100 dark:bg-indigo-900/30 rounded-full flex items-center justify-center">
                  <span className="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                    {comment.author.name?.charAt(0) || '?'}
                  </span>
                </div>
              )}
              <div>
                <p className="font-medium">{comment.author.name || 'Utilisateur anonyme'}</p>
                <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="capitalize">{comment.platform}</span>
                  {comment.metadata?.pageName && (
                    <>
                      <span>•</span>
                      <span>{comment.metadata.pageName}</span>
                    </>
                  )}
                  <span>•</span>
                  <span>{format(new Date(comment.createdAt), 'dd MMM yyyy HH:mm', { locale: fr })}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {comment.sentiment && (
                <span className={`px-2 py-1 text-xs rounded-full ${getSentimentColor(comment.sentiment)}`}>
                  {comment.sentiment}
                </span>
              )}
              {comment.status && (
                <span className={`px-2 py-1 text-xs rounded-full ${getStatusColor(comment.status)}`}>
                  {comment.status}
                </span>
              )}
              {comment.metadata?.moderation && (
                <ModerationBadge
                  status={comment.metadata.moderation.status}
                  score={comment.metadata.moderation.score}
                />
              )}
            </div>
          </div>

          {/* Lien vers le post */}
          {comment.postId && (
            <button
              onClick={() => handleViewPost(comment)}
              className="mb-4 flex items-center gap-2 text-sm text-indigo-600 dark:text-indigo-400 hover:underline"
            >
              <ExternalLink className="w-4 h-4" />
              <span>Voir le post complet</span>
            </button>
          )}

          {/* Contenu du commentaire */}
          <p className="text-gray-900 dark:text-gray-100 mb-4">
            {comment.content}
          </p>
          {/* Actions */}
          <div className="flex items-center gap-2">
            <button
              onClick={() => setReplyingTo(comment._id)}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <Reply className="w-4 h-4" />
              Répondre
            </button>
            <button
              onClick={() => onAutoResponse(comment._id)}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-indigo-600 dark:text-indigo-400 hover:bg-indigo-50 dark:hover:bg-indigo-900/30 rounded-lg transition-colors"
            >
              <Bot className="w-4 h-4" />
              Réponse IA
            </button>
            <button
              onClick={() => handleStatusUpdate(comment._id, 'flagged')}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-yellow-600 dark:text-yellow-400 hover:bg-yellow-50 dark:hover:bg-yellow-900/30 rounded-lg transition-colors"
            >
              <Flag className="w-4 h-4" />
              Signaler
            </button>
            <button
              onClick={() => handleStatusUpdate(comment._id, 'hidden')}
              className="flex items-center gap-2 px-3 py-1.5 text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/30 rounded-lg transition-colors"
            >
              <EyeOff className="w-4 h-4" />
              Masquer
            </button>
          </div>

          {/* Zone de réponse avec suggestions IA */}
          {replyingTo === comment._id && (
            <div className="mt-4 space-y-4">
              <div className="relative">
                <div className="flex items-center gap-2 mb-2">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Votre réponse
                  </label>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => setShowAISuggestions(!showAISuggestions)}
                      className="p-1 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                      title="Suggestions IA"
                    >
                      <Brain className="w-4 h-4" />
                    </button>
                    <button
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                      title="Ajouter un emoji"
                    >
                      <Smile className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                
                <textarea
                  ref={textareaRef}
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  placeholder="Votre réponse..."
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-700 text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                  rows={3}
                />

                {showEmojiPicker && (
                  <div className="absolute top-full right-0 mt-2 z-50">
                    <EmojiPicker
                      onSelect={handleEmojiSelect}
                      onClose={() => setShowEmojiPicker(false)}
                    />
                  </div>
                )}
              </div>

              {/* Suggestions IA */}
              {showAISuggestions && (
  <div className="border dark:border-gray-700 rounded-lg overflow-hidden">
    <div className="bg-gray-50 dark:bg-gray-800 px-4 py-2 border-b dark:border-gray-700">
      <h3 className="font-medium flex items-center gap-2">
        <Bot className="w-4 h-4 text-indigo-500" />
        Suggestions de réponses
      </h3>
    </div>
    <div className="p-4">
      <ReplySuggestions
        content={comment.content}
        platforms={[comment.platform]}
        onSelect={(suggestion) => {
          setReplyContent(suggestion);
          setShowAISuggestions(false);  // Fermer les suggestions après sélection
        }}
        settings={{
          tone: comment.sentiment === 'negative' ? 'empathetic' : 'professional',
          style: 'conversational',
          length: 'medium',
          useEmojis: comment.platform !== 'linkedin',
          useHashtags: ['twitter', 'instagram'].includes(comment.platform),
          useMentions: ['twitter', 'instagram'].includes(comment.platform)
        }}
        context={[
          `Platform: ${comment.platform}`,
          comment.metadata?.pageName && `Page: ${comment.metadata.pageName}`,
          comment.sentiment && `Sentiment: ${comment.sentiment}`,
          `Original Message: ${comment.content}`
        ].filter(Boolean)}
      />
    </div>
  </div>
)}

              <div className="flex items-center justify-end gap-2">
                <button
                  onClick={() => {
                    setReplyingTo(null);
                    setReplyContent('');
                  }}
                  className="px-4 py-2 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={() => handleSubmitReply(comment._id)}
                  disabled={!replyContent.trim() || isSubmitting}
                  className="flex items-center gap-2 px-4 py-2 text-sm bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <Reply className="w-4 h-4" />
                  )}
                  Envoyer
                </button>
              </div>
            </div>
          )}
        </div>
      ))}

      {/* Dialogue de configuration IA */}
      {showAISettings && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-medium flex items-center gap-2">
                <Bot className="w-5 h-5 text-indigo-500" />
                Configuration de l'IA
              </h3>
              <button
                onClick={() => setShowAISettings(false)}
                className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              {/* Activation générale */}
              <div className="flex items-center justify-between">
                <label className="flex items-center gap-2">
                  <Bot className="w-5 h-5 text-indigo-500" />
                  Activer l'IA
                </label>
                <input
                  type="checkbox"
                  checked={aiSettings.enabled}
                  onChange={(e) => saveAISettings({
                    ...aiSettings,
                    enabled: e.target.checked
                  })}
                  className="toggle"
                />
              </div>

              {/* Modération automatique */}
              <div className="flex items-center justify-between">
                <label className="flex items-center gap-2">
                  <Settings2 className="w-5 h-5 text-indigo-500" />
                  Modération automatique
                </label>
                <input
                  type="checkbox"
                  checked={aiSettings.autoModerate}
                  onChange={(e) => saveAISettings({
                    ...aiSettings,
                    autoModerate: e.target.checked
                  })}
                  className="toggle"
                  disabled={!aiSettings.enabled}
                />
              </div>

              {/* Réponses automatiques */}
              <div className="flex items-center justify-between">
                <label className="flex items-center gap-2">
                  <MessageCircle className="w-5 h-5 text-indigo-500" />
                  Suggestions automatiques
                </label>
                <input
                  type="checkbox"
                  checked={aiSettings.autoRespond}
                  onChange={(e) => saveAISettings({
                    ...aiSettings,
                    autoRespond: e.target.checked
                  })}
                  className="toggle"
                  disabled={!aiSettings.enabled}
                />
              </div>

              {/* Seuil de confiance */}
              <div className="space-y-2">
                <label className="block text-sm font-medium">
                  Seuil de confiance minimum
                </label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={aiSettings.confidenceThreshold}
                  onChange={(e) => saveAISettings({
                    ...aiSettings,
                    confidenceThreshold: parseFloat(e.target.value)
                  })}
                  className="w-full"
                  disabled={!aiSettings.enabled}
                />
                <div className="flex justify-between text-sm text-gray-500">
                  <span>0% (Permissif)</span>
                  <span>100% (Strict)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

       {/* Post preview dialog */}
       {showPostPreview && selectedPost && (
        <PostPreviewDialog
          post={selectedPost}
          isOpen={showPostPreview}
          onClose={() => {
            setShowPostPreview(false);
            setSelectedPostId(null);
          }}
          onAutoResponse={onAutoResponse}
        />
      )}
    </div>
  );
}