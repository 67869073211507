import React from 'react';
import { Filter, Search, Tag, Calendar, Flag } from 'lucide-react';

interface MessageFiltersProps {
  filters: {
    search: string;
    labels: string[];
    date: string;
    priority: string;
  };
  onFilterChange: (key: string, value: any) => void;
}

export function MessageFilters({ filters, onFilterChange }: MessageFiltersProps) {
  return (
    <div className="space-y-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <div className="flex items-center gap-2">
        <Filter className="w-5 h-5 text-gray-500" />
        <h3 className="font-medium">Filtres</h3>
      </div>

      {/* Recherche */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
        <input
          type="text"
          value={filters.search}
          onChange={(e) => onFilterChange('search', e.target.value)}
          placeholder="Rechercher..."
          className="w-full pl-9 pr-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
        />
      </div>

      {/* Étiquettes */}
      <div>
        <label className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-2">
          <Tag className="w-4 h-4" />
          Étiquettes
        </label>
        <select
          multiple
          value={filters.labels}
          onChange={(e) => onFilterChange('labels', Array.from(e.target.selectedOptions, option => option.value))}
          className="w-full p-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
        >
          <option value="urgent">Urgent</option>
          <option value="support">Support</option>
          <option value="feedback">Feedback</option>
          <option value="bug">Bug</option>
        </select>
      </div>

      {/* Date */}
      <div>
        <label className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-2">
          <Calendar className="w-4 h-4" />
          Période
        </label>
        <select
          value={filters.date}
          onChange={(e) => onFilterChange('date', e.target.value)}
          className="w-full p-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
        >
          <option value="today">Aujourd'hui</option>
          <option value="week">Cette semaine</option>
          <option value="month">Ce mois</option>
          <option value="all">Tout</option>
        </select>
      </div>

      {/* Priorité */}
      <div>
        <label className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-2">
          <Flag className="w-4 h-4" />
          Priorité
        </label>
        <select
          value={filters.priority}
          onChange={(e) => onFilterChange('priority', e.target.value)}
          className="w-full p-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
        >
          <option value="all">Toutes</option>
          <option value="high">Haute</option>
          <option value="medium">Moyenne</option>
          <option value="low">Basse</option>
        </select>
      </div>
    </div>
  );
}