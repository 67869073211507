import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import apiClient from '../lib/apiClient';
import { toast } from 'react-hot-toast';

export function useTeams() {
  const queryClient = useQueryClient();

  const useTeamsList = () => {
    return useQuery({
      queryKey: ['teams'],
      queryFn: async () => {
        const response = await apiClient.get('/teams');
        return response.data;
      }
    });
  };

  const createTeam = useMutation({
    mutationFn: async (data: { name: string; description?: string }) => {
      const response = await apiClient.post('/teams', data);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      toast.success('Équipe créée avec succès');
    },
    onError: (error: any) => {
      console.error('Team creation error:', error);
      toast.error(error.response?.data?.message || 'Erreur lors de la création');
    }
  });

  const inviteMember = useMutation({
    mutationFn: async ({ teamId, email, role }: { 
      teamId: string; 
      email: string; 
      role: string;
    }) => {
      const response = await apiClient.post(`/teams/${teamId}/invite`, { email, role });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] });
      toast.success('Invitation envoyée avec succès');
    },
    onError: (error: any) => {
      console.error('Member invite error:', error);
      toast.error(error.response?.data?.message || 'Erreur lors de l\'invitation');
    }
  });

  return {
    useTeamsList,
    createTeam,
    inviteMember
  };
}