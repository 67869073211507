import React, { useCallback, useEffect, useState } from 'react';
import { Calendar } from 'lucide-react';
import { DateRange, formatDateForInput } from '../utils/dateUtils';

interface DateRangePickerProps {
  startDate: string | null;
  endDate: string | null;
  onChange: (range: { start: Date | null; end: Date | null }) => void;
  minDate?: Date;
}

export function DateRangePicker({
  startDate,
  endDate,
  onChange,
  minDate = new Date()
}: DateRangePickerProps) {
  // État local pour suivre les valeurs d'affichage
  const [displayedStartDate, setDisplayedStartDate] = useState(startDate ? new Date(startDate) : null);
  const [displayedEndDate, setDisplayedEndDate] = useState(endDate ? new Date(endDate) : null);

  // Mettre à jour l'affichage quand les props changent
  useEffect(() => {
    setDisplayedStartDate(startDate ? new Date(startDate) : null);
    setDisplayedEndDate(endDate ? new Date(endDate) : null);
  }, [startDate, endDate]);

  const handleStartDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = e.target.value ? new Date(e.target.value) : null;
    
    if (newStartDate) {
      newStartDate.setHours(0, 0, 0, 0);
      setDisplayedStartDate(newStartDate);
      
      if (displayedEndDate && displayedEndDate < newStartDate) {
        setDisplayedEndDate(null);
        onChange({
          start: newStartDate,
          end: null
        });
      } else {
        onChange({
          start: newStartDate,
          end: displayedEndDate
        });
      }
    } else {
      setDisplayedStartDate(null);
      setDisplayedEndDate(null);
      onChange({ start: null, end: null });
    }
  }, [displayedEndDate, onChange]);

  const handleEndDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value ? new Date(e.target.value) : null;
    
    if (newEndDate) {
      newEndDate.setHours(23, 59, 59, 999);
      setDisplayedEndDate(newEndDate);
      
      onChange({
        start: displayedStartDate,
        end: newEndDate
      });
    } else {
      setDisplayedEndDate(null);
      onChange({
        start: displayedStartDate,
        end: null
      });
    }
  }, [displayedStartDate, onChange]);

  return (
    <div className="flex flex-col gap-2">
      <div className="relative">
        <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
        <input
          type="date"
          value={formatDateForInput(displayedStartDate)}
          onChange={handleStartDateChange}
          min={formatDateForInput(minDate)}
          className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          placeholder="Date de début"
        />
      </div>
      <div className="relative">
        <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
        <input
          type="date"
          value={formatDateForInput(displayedEndDate)}
          onChange={handleEndDateChange}
          min={formatDateForInput(displayedStartDate || minDate)}
          className="w-full pl-10 pr-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          placeholder="Date de fin"
          disabled={!displayedStartDate}
        />
      </div>
    </div>
  );
}