import React, { useState, useRef } from 'react';
import { Upload, X, Image as ImageIcon, Video } from 'lucide-react';
import { uploadMedia, getMediaUrl } from '../lib/posts';
import MediaThumbnail from './MediaThumbnail'; // Modifié pour utiliser l'import par défaut
import { toast } from 'react-hot-toast';

interface MediaUploaderProps {
  value: Array<{ url: string; type: 'image' | 'video' }>;
  onUpload: (files: Array<{ url: string; type: 'image' | 'video' }>) => void;
  accept?: string;
  multiple?: boolean;
}

export function MediaUploader({ 
  value = [], 
  onUpload, 
  accept = "image/*,video/*", 
  multiple = true 
}: MediaUploaderProps) {
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) return;

    setIsLoading(true);
    try {
      console.log('Starting upload...');
      const uploadedFiles = await uploadMedia(files);
      console.log('Upload complete:', uploadedFiles);
      onUpload([...value, ...uploadedFiles]);
      toast.success('Médias uploadés avec succès');
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Erreur lors de l\'upload des médias');
    } finally {
      setIsLoading(false);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  };

  const removeMedia = (index: number) => {
    onUpload(value.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-4">
      {/* Zone de preview */}
      {value.length > 0 && (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {value.map((media, index) => (
            <div key={index} className="relative aspect-square rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700 group">
              <MediaThumbnail media={media} className="w-full h-full" />
              <button
                onClick={() => removeMedia(index)}
                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity hover:bg-red-600"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Bouton d'upload */}
      <label className="cursor-pointer block">
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          accept={accept}
          multiple={multiple}
          onChange={handleChange}
          disabled={isLoading}
        />
        <div className="flex items-center justify-center gap-2 p-4 border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg hover:border-indigo-500 dark:hover:border-indigo-400 transition-colors">
          {isLoading ? (
            <span className="text-gray-600 dark:text-gray-300">Upload en cours...</span>
          ) : (
            <>
              <Upload className="w-5 h-5 text-gray-400" />
              <span className="text-gray-600 dark:text-gray-300">
                {value.length === 0 ? 'Ajouter des médias' : 'Ajouter plus de médias'}
              </span>
            </>
          )}
        </div>
      </label>
    </div>
  );
}