import React from 'react';
import { ThumbsUp, ThumbsDown } from 'lucide-react';
import { feedbackService } from '../services';

interface SuggestionCardProps {
  suggestion: string;
  onSelect: (suggestion: string) => void;
  onFeedback?: (suggestion: string, isPositive: boolean) => void;
  context?: {
    platform?: string;
    type?: string;
    prompt?: string;
  };
}

export function SuggestionCard({ 
  suggestion, 
  onSelect, 
  onFeedback,
  context
}: SuggestionCardProps) {
  const handleFeedback = async (isPositive: boolean) => {
    try {
      await feedbackService.submit({
        suggestion,
        isPositive,
        context
      });
      onFeedback?.(suggestion, isPositive);
    } catch (error) {
      console.error('Feedback error:', error);
    }
  };

  return (
    <div className="group relative p-3 bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700 hover:border-indigo-500 dark:hover:border-indigo-500 transition-colors">
      <p className="text-sm pr-24">{suggestion}</p>
      
      <div className="absolute right-2 top-2 flex items-center gap-2">
        <button
          onClick={() => handleFeedback(true)}
          className="p-1 text-gray-400 hover:text-green-500"
        >
          <ThumbsUp className="w-4 h-4" />
        </button>
        <button
          onClick={() => handleFeedback(false)}
          className="p-1 text-gray-400 hover:text-red-500"
        >
          <ThumbsDown className="w-4 h-4" />
        </button>
        <button
          onClick={() => onSelect(suggestion)}
          className="px-2 py-1 text-xs bg-indigo-50 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400 rounded hover:bg-indigo-100 dark:hover:bg-indigo-900/50"
        >
          Utiliser
        </button>
      </div>
    </div>
  );
}