import React, { useState } from 'react';
import { useSocialAccounts } from '../hooks/useSocialAccounts';
import { SocialPlatform } from '../lib/socialMedia';
import { useAuthStore } from '../stores/authStore';
import { 
  Loader2, 
  Facebook, 
  Twitter, 
  Instagram, 
  Linkedin, 
  Youtube,
  Music,
  AlertCircle
} from 'lucide-react';
import { toast } from 'react-hot-toast';

const PLATFORMS: Array<{ id: SocialPlatform; name: string; icon: React.ReactNode }> = [
  { id: 'facebook', name: 'Facebook', icon: <Facebook className="w-6 h-6 text-blue-600" /> },
  { id: 'twitter', name: 'Twitter', icon: <Twitter className="w-6 h-6 text-sky-500" /> },
  { id: 'instagram', name: 'Instagram', icon: <Instagram className="w-6 h-6 text-pink-600" /> },
  { id: 'linkedin', name: 'LinkedIn', icon: <Linkedin className="w-6 h-6 text-blue-700" /> },
  { id: 'tiktok', name: 'TikTok', icon: <Music className="w-6 h-6 text-black dark:text-white" /> },
  { id: 'youtube', name: 'YouTube', icon: <Youtube className="w-6 h-6 text-red-600" /> },
];

export function SocialConnect() {
  const [connecting, setConnecting] = useState<SocialPlatform | null>(null);
  const { user, token } = useAuthStore();

  const handleConnect = async (platform: SocialPlatform) => {
    if (!user?.id || !token) {
      toast.error('Veuillez vous connecter');
      return;
    }

    setConnecting(platform);
    try {
      if (platform === 'facebook') {
        // Pour Facebook, rediriger directement vers l'URL d'authentification
        window.location.href = `${import.meta.env.VITE_API_URL}/auth/facebook`;
      } else {
        // Pour les autres plateformes, utiliser l'API de connexion
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/social/connect/${platform}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ userId: user.id }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to get authorization URL');
        }

        const { url } = await response.json();
        window.location.href = url;
      }
    } catch (error) {
      console.error('Connection error:', error);
      toast.error(error instanceof Error ? error.message : 'Erreur de connexion');
    } finally {
      setConnecting(null);
    }
  };

  return (
    <div className="space-y-6">
      {user?.facebookId && (
        <div className="p-4 bg-blue-50 dark:bg-blue-900/30 rounded-lg flex items-start gap-3">
          <AlertCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
          <div className="text-sm text-blue-700 dark:text-blue-300">
            Vous êtes déjà connecté avec Facebook. La connexion utilisera automatiquement votre compte existant.
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {PLATFORMS.map((platform) => (
          <button
            key={platform.id}
            onClick={() => handleConnect(platform.id)}
            disabled={connecting === platform.id}
            className="flex flex-col items-center gap-3 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all"
          >
            <div className="transition-transform hover:scale-110">
              {platform.icon}
            </div>
            <span className="text-sm font-medium">
              {connecting === platform.id ? (
                <div className="flex items-center gap-2">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Connexion...
                </div>
              ) : (
                `Connecter ${platform.name}`
              )}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}