import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';
import { 
  BarChart2, 
  TrendingUp, 
  Users, 
  MessageSquare,
  Calendar,
  Filter,
  ArrowUp,
  ArrowDown,
  Loader2
} from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { useSocialAccounts } from '../hooks/useSocialAccounts';

// Enregistrer les composants Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export function Analytics() {
  const [dateRange, setDateRange] = useState('7d');
  const [platform, setPlatform] = useState('all');
  const [pageId, setPageId] = useState<string | null>(null);
  const { token } = useAuthStore();

  // Récupérer les comptes et les pages
  const { useAccounts } = useSocialAccounts();
  const { data: accounts = [] } = useAccounts();

  // Récupérer les pages disponibles pour la plateforme sélectionnée
  const availablePages = React.useMemo(() => {
    const account = accounts.find(a => a.platform === platform);
    return account?.pages || [];
  }, [accounts, platform]);

  // Réinitialiser pageId si la plateforme change
  useEffect(() => {
    if (pageId && (platform !== 'facebook' || !availablePages.length)) {
      setPageId(null);
    }
  }, [platform, availablePages.length]);

  // Construire les paramètres de requête
  const queryParams = new URLSearchParams({
    range: dateRange,
    ...(platform !== 'all' && { platform }),
    ...(pageId && platform === 'facebook' && { pageId }),
  });

  // Récupérer les données d'analyse
  const { data: analytics, isLoading } = useQuery({
    queryKey: ['analytics', dateRange, platform, pageId],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/analytics?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error('Failed to fetch analytics');
      return response.json();
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin text-indigo-500" />
      </div>
    );
  }

  // Options communes pour les graphiques
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold flex items-center gap-2">
          <BarChart2 className="w-6 h-6 text-indigo-500" />
          Analyses
        </h1>

        {/* Filtres */}
        <div className="flex items-center gap-4">
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="7d">7 derniers jours</option>
            <option value="30d">30 derniers jours</option>
            <option value="90d">90 derniers jours</option>
          </select>

          <select
            value={platform}
            onChange={(e) => setPlatform(e.target.value)}
            className="px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="all">Toutes les plateformes</option>
            {accounts.map(account => (
              <option key={account._id} value={account.platform}>
                {account.platform}
              </option>
            ))}
          </select>

          {platform === 'facebook' && availablePages.length > 0 && (
            <select
              value={pageId || ''}
              onChange={(e) => setPageId(e.target.value || null)}
              className="px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
            >
              <option value="">Toutes les pages</option>
              {availablePages.map(page => (
                <option key={page.id} value={page.id}>
                  {page.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      {/* Cartes de métriques */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <MetricCard
          title="Engagement total"
          value={analytics?.totalEngagement || 0}
          change={analytics?.engagementChange || 0}
          icon={<TrendingUp className="w-5 h-5" />}
        />
        <MetricCard
          title="Nouveaux abonnés"
          value={analytics?.newFollowers || 0}
          change={analytics?.followersChange || 0}
          icon={<Users className="w-5 h-5" />}
        />
        <MetricCard
          title="Publications"
          value={analytics?.totalPosts || 0}
          change={analytics?.postsChange || 0}
          icon={<Calendar className="w-5 h-5" />}
        />
        <MetricCard
          title="Commentaires"
          value={analytics?.totalComments || 0}
          change={analytics?.commentsChange || 0}
          icon={<MessageSquare className="w-5 h-5" />}
        />
      </div>

      {/* Graphiques */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Engagement au fil du temps */}
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
          <h3 className="text-lg font-semibold mb-4">Engagement au fil du temps</h3>
          <div className="h-80">
            <Line
              data={{
                labels: analytics?.engagementOverTime?.map(d => format(new Date(d.date), 'dd/MM', { locale: fr })) || [],
                datasets: [
                  {
                    label: 'Engagement',
                    data: analytics?.engagementOverTime?.map(d => d.value) || [],
                    borderColor: 'rgb(99, 102, 241)',
                    backgroundColor: 'rgba(99, 102, 241, 0.1)',
                    fill: true,
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>

        {/* Distribution des interactions */}
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
          <h3 className="text-lg font-semibold mb-4">Distribution des interactions</h3>
          <div className="h-80">
            <Bar
              data={{
                labels: ['Likes', 'Commentaires', 'Partages', 'Clics', 'Enregistrements'],
                datasets: [
                  {
                    label: 'Nombre d\'interactions',
                    data: analytics?.interactionDistribution || [],
                    backgroundColor: [
                      'rgba(99, 102, 241, 0.8)',
                      'rgba(139, 92, 246, 0.8)',
                      'rgba(59, 130, 246, 0.8)',
                      'rgba(16, 185, 129, 0.8)',
                      'rgba(245, 158, 11, 0.8)',
                    ],
                  },
                ],
              }}
              options={chartOptions}
            />
          </div>
        </div>
      </div>

      {/* Tableau des meilleures publications */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
        <div className="p-6">
          <h3 className="text-lg font-semibold mb-4">Meilleures publications</h3>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b dark:border-gray-700">
                  <th className="text-left py-3 px-4">Contenu</th>
                  <th className="text-left py-3 px-4">Plateforme</th>
                  <th className="text-left py-3 px-4">Date</th>
                  <th className="text-right py-3 px-4">Engagement</th>
                </tr>
              </thead>
              <tbody>
                {analytics?.topPosts?.map((post: any) => (
                  <tr key={post.id} className="border-b dark:border-gray-700">
                    <td className="py-3 px-4">
                      <p className="line-clamp-2">{post.content}</p>
                    </td>
                    <td className="py-3 px-4 capitalize">{post.platform}</td>
                    <td className="py-3 px-4">
                      {format(new Date(post.createdAt), 'dd/MM/yyyy', { locale: fr })}
                    </td>
                    <td className="py-3 px-4 text-right">
                      {post.engagement.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

interface MetricCardProps {
  title: string;
  value: number;
  change: number;
  icon: React.ReactNode;
}

function MetricCard({ title, value, change, icon }: MetricCardProps) {
  const isPositive = change >= 0;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
      <div className="flex items-center justify-between">
        <div className="p-2 bg-indigo-50 dark:bg-indigo-900/30 rounded-lg">
          <div className="text-indigo-600 dark:text-indigo-400">
            {icon}
          </div>
        </div>
        <div className={`flex items-center gap-1 text-sm ${
          isPositive ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
        }`}>
          {isPositive ? (
            <ArrowUp className="w-4 h-4" />
          ) : (
            <ArrowDown className="w-4 h-4" />
          )}
          <span>{Math.abs(change)}%</span>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</h3>
        <p className="text-2xl font-semibold mt-1">{value.toLocaleString()}</p>
      </div>
    </div>
  );
}