import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';
import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { toast } from 'react-hot-toast';

interface Notification {
  id: string;
  type: string;
  title: string;
  message: string;
  timestamp: string;
  priority: 'low' | 'medium' | 'high';
  read: boolean;
  platform?: string;
}

export function useNotifications() {
  const { token } = useAuthStore();
  const queryClient = useQueryClient();
  const socketRef = useRef<Socket | null>(null);
  const apiUrl = import.meta.env.VITE_API_URL;
  const socketUrl = apiUrl.replace('/api', '');

  // Setup Socket.IO connection
  useEffect(() => {
    if (!token) return;

    // Création de la connexion Socket.IO
    socketRef.current = io(socketUrl, {
      auth: { token },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      transports: ['websocket', 'polling'] // Commencer par WebSocket
    });

    // Gestionnaire d'événements de connexion
    socketRef.current.on('connect', () => {
      console.log('Socket.IO connected successfully');
    });

    // Gestionnaire d'événements de déconnexion
    socketRef.current.on('disconnect', (reason) => {
      console.log('Socket.IO disconnected:', reason);
    });

    // Gestionnaire d'erreurs de connexion
    socketRef.current.on('connect_error', (error) => {
      console.error('Socket.IO connection error:', error);
    });

    // Gestionnaire de notifications
    socketRef.current.on('notification', (notification: Notification) => {
      // Ajouter la notification au cache
      queryClient.setQueryData<Notification[]>(['notifications'], (old = []) => [
        notification,
        ...old
      ]);

      // Afficher la notification toast
      toast(notification.message, {
        duration: notification.priority === 'high' ? 10000 : 5000,
        className: getNotificationClass(notification.priority),
        icon: getNotificationIcon(notification.type),
      });
    });

    // Cleanup à la déconnexion
    return () => {
      if (socketRef.current) {
        console.log('Cleaning up Socket.IO connection');
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [token, socketUrl, queryClient]);

  // Récupération des notifications
  const { data: notifications = [] } = useQuery<Notification[]>({
    queryKey: ['notifications'],
    queryFn: async () => {
      const response = await fetch(`${apiUrl}/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }
      return response.json();
    },
    refetchInterval: 30000, // Refresh toutes les 30 secondes
  });

  // Mutation pour marquer comme lu
  const markAsRead = useMutation({
    mutationFn: async (notificationId: string) => {
      const response = await fetch(
        `${apiUrl}/notifications/${notificationId}/read`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to mark notification as read');
      }
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  // Mutation pour tout effacer
  const clearAll = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${apiUrl}/notifications/clear`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to clear notifications');
      }
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });

  return {
    notifications,
    markAsRead: markAsRead.mutate,
    clearAll: clearAll.mutate,
    isConnected: socketRef.current?.connected || false,
  };
}

// Utilitaires pour le style des notifications
function getNotificationClass(priority?: 'low' | 'medium' | 'high') {
  switch (priority) {
    case 'high':
      return 'bg-red-50 text-red-800 dark:bg-red-900/30 dark:text-red-200';
    case 'medium':
      return 'bg-yellow-50 text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-200';
    default:
      return 'bg-white text-gray-800 dark:bg-gray-800 dark:text-gray-200';
  }
}

function getNotificationIcon(type: string) {
  switch (type) {
    case 'success':
      return '✅';
    case 'error':
      return '❌';
    case 'warning':
      return '⚠️';
    case 'message':
      return '💬';
    case 'like':
      return '❤️';
    case 'share':
      return '🔄';
    default:
      return 'ℹ️';
  }
}