import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cn } from "../../../lib/utils";

interface CalendarProps {
  selected: {
    from?: Date | null;
    to?: Date | null;
  };
  onSelect: (dates: { from?: Date | null; to?: Date | null }) => void;
  disabled?: (date: Date) => boolean;
  initialFocus?: boolean;
  mode?: "single" | "range";
  className?: string;
}

export function Calendar({
  selected,
  onSelect,
  disabled,
  initialFocus,
  mode = "single",
  className,
}: CalendarProps) {
  const handleChange = (dates: [Date | null, Date | null] | Date | null) => {
    if (mode === "range" && Array.isArray(dates)) {
      const [start, end] = dates;
      onSelect({ from: start, to: end });
    } else if (mode === "single" && dates instanceof Date) {
      onSelect({ from: dates, to: dates });
    }
  };

  return (
    <div className={cn("relative", className)}>
      <ReactDatePicker
        selected={selected.from}
        onChange={handleChange}
        startDate={selected.from}
        endDate={selected.to}
        selectsRange={mode === "range"}
        inline
        calendarClassName="bg-white dark:bg-gray-800 p-3 rounded-lg shadow"
        dayClassName={() => 
          "text-sm hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
        }
        monthClassName={() => ""}
        weekDayClassName={() => 
          "text-gray-500 dark:text-gray-400 text-sm py-2"
        }
        disabledKeyboardNavigation={!initialFocus}
        disabled={disabled}
      />
    </div>
  );
}