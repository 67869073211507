import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthStore } from '../stores/authStore';
import { toast } from 'react-hot-toast';

export interface Post {
  _id: string;
  content: string;
  platform: string;
  media?: Array<{
    url: string;
    type: 'image' | 'video';
    alt?: string;
  }>;
  scheduledDate?: Date;
  status: 'draft' | 'scheduled' | 'pending' | 'published' | 'failed';
  metadata?: {
    accountId?: string;
    accountType?: 'profile' | 'page';
    pageId?: string;
    pageName?: string;
    platform?: string;
    postId?: string;
    error?: string;
    originalCreatedAt?: string;
    originalUpdatedAt?: string;
  };
  createdAt: string;
  updatedAt: string;
  analytics?: {
    likes: number;
    comments: number;
    shares: number;
    clicks: number;
    reach: number;
    engagement: number;
    impressions: number;
    saves: number;
    lastSync?: string;
  };
}

export function usePosts() {
  const { token } = useAuthStore();
  const queryClient = useQueryClient();
  const apiUrl = import.meta.env.VITE_API_URL;

  const usePostsList = (params: {
    page?: number;
    limit?: number;
    platform?: string;
    pageId?: string | null;
    status?: string;
    search?: string;
    startDate?: string;
    endDate?: string;
    includeCommentCount?: boolean;
  } = {}) => {
    console.log("Fetching posts with params:", params);

    return useQuery({
      queryKey: ['posts', params],
      queryFn: async () => {
        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            queryParams.append(key, value.toString());
          }
        });

        const response = await fetch(
          `${apiUrl}/posts?${queryParams.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch posts');
        }

        const data = await response.json();
        console.log("Posts response:", data);
        return data;
      },
      enabled: !!token
    });
  };

  const usePost = (postId: string | null | undefined) => {
    console.log("usePost hook called for postId:", postId);

    return useQuery({
      queryKey: ['post', postId],
      queryFn: async () => {
        if (!postId) throw new Error('Post ID is required');

        const response = await fetch(
          `${apiUrl}/posts/${postId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch post');
        }

        return response.json();
      },
      enabled: !!postId && !!token
    });
  };

  const create = useMutation({
    mutationFn: async (data: any) => {
      const response = await fetch(`${apiUrl}/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create post');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      toast.success('Publication créée');
    },
    onError: (error: Error) => {
      console.error('Create post error:', error);
      toast.error(`Erreur: ${error.message}`);
    }
  });

  const update = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: any }) => {
      const response = await fetch(`${apiUrl}/posts/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to update post');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      toast.success('Publication mise à jour');
    },
    onError: (error: Error) => {
      console.error('Update post error:', error);
      toast.error(`Erreur: ${error.message}`);
    }
  });

  const remove = useMutation({
    mutationFn: async (id: string) => {
      const response = await fetch(`${apiUrl}/posts/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to delete post');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      toast.success('Publication supprimée');
    },
    onError: (error: Error) => {
      console.error('Delete post error:', error);
      toast.error(`Erreur: ${error.message}`);
    }
  });

  return {
    usePost,
    usePostsList,
    create,
    update,
    remove,
  };
}