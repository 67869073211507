import React, { useState } from 'react';
import { 
  LayoutDashboard, 
  TrendingUp, 
  Users, 
  MessageSquare,
  Calendar,
  Plus,
  ArrowUp,
  ArrowDown,
  Loader2
} from 'lucide-react';
import { Line, Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useDashboard } from '../hooks/useDashboard';
import { useSocialAccounts } from '../hooks/useSocialAccounts';
import { PostEditor } from '../components/PostEditor';

export function Dashboard() {
  const [selectedPeriod, setSelectedPeriod] = useState('7d');
  const [selectedPlatform, setSelectedPlatform] = useState('all');
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [showPostEditor, setShowPostEditor] = useState(false);

  const { useAccounts } = useSocialAccounts();
  const { data: accounts = [] } = useAccounts();
  const { useDashboardStats } = useDashboard();

  // Récupérer les pages disponibles pour la plateforme sélectionnée
  const availablePages = React.useMemo(() => {
    const account = accounts.find(a => a.platform === selectedPlatform);
    return account?.pages || [];
  }, [accounts, selectedPlatform]);

  // Récupérer les statistiques
  const { data: stats, isLoading, error } = useDashboardStats({
    period: selectedPeriod,
    platform: selectedPlatform,
    pageId: selectedPageId
  });

  const handleNewPost = () => {
    setShowPostEditor(true);
  };

  // Options communes pour les graphiques
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="space-y-6 p-6">
      {/* En-tête */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold flex items-center gap-2">
            <LayoutDashboard className="w-6 h-6 text-indigo-500" />
            Tableau de bord
          </h1>
          <p className="text-gray-500 dark:text-gray-400 mt-1">
            Bienvenue sur votre tableau de bord de gestion des réseaux sociaux
          </p>
        </div>
        <div className="flex items-center gap-4">
          {/* Période */}
          <select
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
            className="px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="7d">7 derniers jours</option>
            <option value="30d">30 derniers jours</option>
            <option value="90d">90 derniers jours</option>
          </select>

          {/* Plateforme */}
          <select
            value={selectedPlatform}
            onChange={(e) => setSelectedPlatform(e.target.value)}
            className="px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
          >
            <option value="all">Toutes les plateformes</option>
            {accounts.map(account => (
              <option key={account._id} value={account.platform}>
                {account.platform.charAt(0).toUpperCase() + account.platform.slice(1)}
              </option>
            ))}
          </select>

          {/* Pages Facebook */}
          {selectedPlatform === 'facebook' && availablePages.length > 0 && (
            <select
              value={selectedPageId || ''}
              onChange={(e) => setSelectedPageId(e.target.value || null)}
              className="px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700"
            >
              <option value="">Toutes les pages</option>
              {availablePages.map(page => (
                <option key={page.id} value={page.id}>
                  {page.name}
                </option>
              ))}
            </select>
          )}

          <button
            onClick={handleNewPost}
            className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            <Plus className="w-5 h-5" />
            Nouveau post
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center py-12">
          <Loader2 className="w-8 h-8 animate-spin text-indigo-500" />
        </div>
      ) : error ? (
        <div className="text-center py-12 text-red-500">
          Une erreur est survenue lors du chargement des données
        </div>
      ) : stats ? (
        <>
          {/* Cartes de métriques */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <MetricCard
              title="Engagement total"
              value={stats.engagement.total}
              change={stats.engagement.change}
              target={stats.engagement.target}
              icon={<TrendingUp className="w-5 h-5" />}
            />
            <MetricCard
              title="Abonnés"
              value={stats.followers.total}
              change={stats.followers.change}
              icon={<Users className="w-5 h-5" />}
            />
            <MetricCard
              title="Publications"
              value={stats.posts.total}
              change={stats.posts.change}
              icon={<Calendar className="w-5 h-5" />}
            />
            <MetricCard
              title="Messages"
              value={stats.messages.total}
              change={stats.messages.change}
              icon={<MessageSquare className="w-5 h-5" />}
            />
          </div>

          {/* Graphiques */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Engagement au fil du temps */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-semibold mb-4">Engagement au fil du temps</h3>
              <div className="h-80">
                <Line
                  data={{
                    labels: stats.engagementOverTime.map(d => 
                      format(new Date(d.date), 'dd/MM', { locale: fr })
                    ),
                    datasets: [
                      {
                        label: 'Engagement',
                        data: stats.engagementOverTime.map(d => d.value),
                        borderColor: 'rgb(99, 102, 241)',
                        backgroundColor: 'rgba(99, 102, 241, 0.1)',
                        fill: true,
                      },
                    ],
                  }}
                  options={chartOptions}
                />
              </div>
            </div>

            {/* Distribution des interactions */}
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
              <h3 className="text-lg font-semibold mb-4">Distribution des interactions</h3>
              <div className="h-80">
                <Bar
                  data={{
                    labels: ['Likes', 'Commentaires', 'Partages', 'Clics', 'Enregistrements'],
                    datasets: [
                      {
                        label: 'Nombre d\'interactions',
                        data: stats.interactionDistribution,
                        backgroundColor: [
                          'rgba(99, 102, 241, 0.8)',
                          'rgba(139, 92, 246, 0.8)',
                          'rgba(59, 130, 246, 0.8)',
                          'rgba(16, 185, 129, 0.8)',
                          'rgba(245, 158, 11, 0.8)',
                        ],
                      },
                    ],
                  }}
                  options={chartOptions}
                />
              </div>
            </div>
          </div>

          {/* Meilleures publications */}
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
            <div className="p-6">
              <h3 className="text-lg font-semibold mb-4">Meilleures publications</h3>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="border-b dark:border-gray-700">
                      <th className="text-left py-3 px-4">Contenu</th>
                      <th className="text-left py-3 px-4">Plateforme</th>
                      <th className="text-left py-3 px-4">Date</th>
                      <th className="text-right py-3 px-4">Engagement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.topPosts.map((post) => (
                      <tr key={post.id} className="border-b dark:border-gray-700">
                        <td className="py-3 px-4">
                          <p className="line-clamp-2">{post.content}</p>
                        </td>
                        <td className="py-3 px-4 capitalize">{post.platform}</td>
                        <td className="py-3 px-4">
                          {format(new Date(post.createdAt), 'dd/MM/yyyy', { locale: fr })}
                        </td>
                        <td className="py-3 px-4 text-right">
                          {post.engagement.toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {/* Éditeur de post */}
      {showPostEditor && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-4xl w-full p-6">
            <PostEditor
              onSuccess={() => setShowPostEditor(false)}
              onClose={() => setShowPostEditor(false)}
              defaultPlatforms={[selectedPlatform]}
            />
          </div>
        </div>
      )}
    </div>
  );
}

interface MetricCardProps {
  title: string;
  value: number;
  change: number;
  target?: number;
  icon: React.ReactNode;
}

function MetricCard({ title, value, change, target, icon }: MetricCardProps) {
  const isPositive = change >= 0;

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
      <div className="flex items-center justify-between">
        <div className="p-2 bg-indigo-50 dark:bg-indigo-900/30 rounded-lg">
          <div className="text-indigo-600 dark:text-indigo-400">
            {icon}
          </div>
        </div>
        <div className={`flex items-center gap-1 text-sm ${
          isPositive ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
        }`}>
          {isPositive ? (
            <ArrowUp className="w-4 h-4" />
          ) : (
            <ArrowDown className="w-4 h-4" />
          )}
          <span>{Math.abs(change)}%</span>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400">{title}</h3>
        <p className="text-2xl font-semibold mt-1">{value.toLocaleString()}</p>
        {target && (
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
            Objectif: {target.toLocaleString()}
          </p>
        )}
      </div>
    </div>
  );
}