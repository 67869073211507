import React, { useState } from 'react';
import { RefreshCw, Loader2, Share2, Settings2 } from 'lucide-react';
import { SocialAccountsList } from '../SocialAccountsList';
import { SocialConnect } from '../SocialConnect';
import { useSyncManual } from '../../hooks/useSyncManual';
import { useSocialAccounts } from '../../hooks/useSocialAccounts';
import { toast } from 'react-hot-toast';

export function SocialAccountsSettings() {
  const [syncingAccount, setSyncingAccount] = useState<string | null>(null);
  const { syncComments, syncMedia, syncAll } = useSyncManual();
  const { useAccounts } = useSocialAccounts();
  const { data: accounts = [], isLoading } = useAccounts();

  const handleSync = async (accountId: string, type: 'comments' | 'media' | 'all') => {
    try {
      setSyncingAccount(accountId);
      switch (type) {
        case 'comments':
          await syncComments.mutateAsync(accountId);
          break;
        case 'media':
          await syncMedia.mutateAsync(accountId);
          break;
        case 'all':
          await syncAll.mutateAsync(accountId);
          break;
      }
      toast.success('Synchronisation réussie');
    } catch (error) {
      console.error('Sync error:', error);
      toast.error('Erreur lors de la synchronisation');
    } finally {
      setSyncingAccount(null);
    }
  };

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium flex items-center gap-2">
          <Share2 className="w-5 h-5 text-indigo-500" />
          Comptes sociaux connectés
        </h2>
        <button
          onClick={() => {}}
          className="p-2 text-gray-500 hover:text-indigo-500 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <Settings2 className="w-5 h-5" />
        </button>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center py-12">
          <Loader2 className="w-8 h-8 animate-spin text-indigo-500" />
        </div>
      ) : (
        <div className="space-y-4">
          {accounts.map((account) => (
            <div
              key={account._id}
              className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all"
            >
              <div className="flex items-center gap-3">
                <div className="w-12 h-12 bg-indigo-50 dark:bg-indigo-900/30 rounded-full flex items-center justify-center">
                  <span className="text-xl font-semibold text-indigo-600 dark:text-indigo-400 capitalize">
                    {account.platform.charAt(0)}
                  </span>
                </div>
                <div>
                  <p className="font-medium">{account.username}</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400 capitalize">
                    {account.platform}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <button
                  onClick={() => handleSync(account._id, 'comments')}
                  disabled={syncingAccount === account._id}
                  className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                >
                  {syncingAccount === account._id ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <RefreshCw className="w-4 h-4" />
                  )}
                  <span className="hidden sm:inline">Commentaires</span>
                </button>

                <button
                  onClick={() => handleSync(account._id, 'media')}
                  disabled={syncingAccount === account._id}
                  className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                >
                  {syncingAccount === account._id ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <RefreshCw className="w-4 h-4" />
                  )}
                  <span className="hidden sm:inline">Médias</span>
                </button>

                <button
                  onClick={() => handleSync(account._id, 'all')}
                  disabled={syncingAccount === account._id}
                  className="flex items-center gap-2 px-3 py-1.5 text-sm bg-indigo-50 dark:bg-indigo-900/30 text-indigo-600 dark:text-indigo-400 rounded-lg hover:bg-indigo-100 dark:hover:bg-indigo-900/50"
                >
                  {syncingAccount === account._id ? (
                    <Loader2 className="w-4 h-4 animate-spin" />
                  ) : (
                    <RefreshCw className="w-4 h-4" />
                  )}
                  <span className="hidden sm:inline">Tout synchroniser</span>
                </button>
              </div>
            </div>
          ))}

          <div className="p-6 bg-gray-50 dark:bg-gray-700/50 border-t dark:border-gray-700">
            <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-4">
              Connecter un nouveau compte
            </h3>
            <SocialConnect />
          </div>
        </div>
      )}
    </div>
  );
}