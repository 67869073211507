import { useAuthStore } from '../stores/authStore';

interface MediaFile {
  url: string;
  type: 'image' | 'video';
  previewUrl?: string;
  externalUrl?: string;
  alt?: string;
}

export interface Post {
  id: string;
  content: string;
  platform: string;
  media?: Array<{
    url: string;
    type: 'image' | 'video';
    alt?: string;
  }>;
  scheduledDate?: Date;
  status: 'draft' | 'scheduled' | 'pending' | 'published' | 'failed';
  metadata?: {
    accountId?: string;
    accountType?: 'profile' | 'page';
    pageId?: string;
  };
  hashtags?: string[];
  mentions?: string[];
  createdAt: Date;
  updatedAt: Date;
}

export interface OptimalTime {
  hour: number;
  engagement: number;
  confidence: number;
}

export interface PlatformOptimalTimes {
  platform: string;
  times: OptimalTime[];
  bestDays: string[];
  frequency: string;
  audienceTimezone: string;
}

export async function getPost(id: string) {
  if (!id) {
    throw new Error('Post ID is required');
  }
  
  const { token } = useAuthStore.getState();
  
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/posts/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to fetch post');
  }

  return response.json();
}

export function getMediaUrl(media: any): string {
  console.log('getMediaUrl input:', media);
  
  // Si c'est une URL externe (Facebook, etc)
  if (media.externalUrl) {
    console.log('Using external URL:', media.externalUrl);
    return media.externalUrl;
  }

  // Si c'est une URL complète
  if (media.url?.startsWith('http')) {
    console.log('Using full URL:', media.url);
    return media.url;
  }

  // Pour les fichiers locaux, construire l'URL avec le nom du fichier
  if (media.url) {
    const baseUrl = import.meta.env.VITE_API_URL.replace(/\/api$/, '');
    const url = `${baseUrl}/uploads/${media.url}`;
    console.log('Using local URL:', url);
    return url;
  }

  console.log('No valid URL found');
  return '';
}

export async function uploadMedia(files: FileList) {
  const { token } = useAuthStore.getState();
  const formData = new FormData();
  
  Array.from(files).forEach(file => {
    formData.append('media', file);
  });

  try {
    const baseUrl = import.meta.env.VITE_API_URL.replace(/\/api$/, '');
    console.log('Upload URL:', `${baseUrl}/api/posts/upload`);
    
    const response = await fetch(`${baseUrl}/api/posts/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Upload failed');
    }

    const result = await response.json();
    console.log('Upload result:', result);
    
    return result.files.map((file: any) => ({
      url: file.url,
      type: file.type,
    }));
  } catch (error) {
    console.error('Upload error:', error);
    throw error;
  }
}

export async function syncPostMedia(postId: string) {
  const { token } = useAuthStore.getState();
  
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/posts/${postId}/sync-media`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to sync media');
  }

  return response.json();
}


export async function createPost(data: Partial<Post>) {
  const { token } = useAuthStore.getState();

  // Si le post contient des médias, utiliser les URLs serveur
  if (data.media?.length) {
    data.media = data.media.map(media => ({
      ...media,
      url: media.url // Utiliser l'URL serveur
    }));
  }

  const response = await fetch(`${import.meta.env.VITE_API_URL}/posts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to create post');
  }

  return response.json();
}

export async function updatePost(id: string, data: Partial<Post>) {
  const { token } = useAuthStore.getState();

  // Si le post contient des médias, utiliser les URLs serveur
  if (data.media?.length) {
    data.media = data.media.map(media => ({
      ...media,
      url: media.url // Utiliser l'URL serveur
    }));
  }

  const response = await fetch(`${import.meta.env.VITE_API_URL}/posts/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to update post');
  }

  return response.json();
}

export async function deletePost(id: string) {
  const { token } = useAuthStore.getState();
  
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/posts/${id}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to delete post');
  }

  return response.json();
}

export async function getOptimalTimes(): Promise<PlatformOptimalTimes[]> {
  const { token } = useAuthStore.getState();
  
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/posts/optimal-times`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'Failed to fetch optimal times');
  }

  return response.json();
}