import React from 'react';
import { Check, Facebook, Twitter, Instagram, Linkedin, Youtube, Music } from 'lucide-react';
import { RadioGroup } from '@headlessui/react';

const PLATFORMS = [
  { id: 'facebook', name: 'Facebook', icon: Facebook, hasPages: true },
  { id: 'twitter', name: 'Twitter', icon: Twitter, hasPages: false },
  { id: 'instagram', name: 'Instagram', icon: Instagram, hasPages: false },
  { id: 'linkedin', name: 'LinkedIn', icon: Linkedin, hasPages: true },
  { id: 'tiktok', name: 'TikTok', icon: Music, hasPages: false },
  { id: 'youtube', name: 'YouTube', icon: Youtube, hasPages: false },
];

interface PlatformSelectorProps {
  selectedPlatforms: string[];
  onPlatformChange: (platforms: string[]) => void;
  className?: string;
}

export function PlatformSelector({ selectedPlatforms, onPlatformChange, className = '' }: PlatformSelectorProps) {
  const handlePlatformToggle = (platformId: string) => {
    if (selectedPlatforms.includes(platformId)) {
      onPlatformChange(selectedPlatforms.filter(id => id !== platformId));
    } else {
      onPlatformChange([...selectedPlatforms, platformId]);
    }
  };

  return (
    <div className={`space-y-4 ${className}`}>
      <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
        Plateformes
      </label>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {PLATFORMS.map((platform) => {
          const Icon = platform.icon;
          const isSelected = selectedPlatforms.includes(platform.id);

          return (
            <button
              key={platform.id}
              onClick={() => handlePlatformToggle(platform.id)}
              className={`relative flex items-center gap-3 p-4 rounded-lg border-2 transition-all ${
                isSelected
                  ? 'border-indigo-500 bg-indigo-50 dark:bg-indigo-900/30'
                  : 'border-gray-200 dark:border-gray-700 hover:border-indigo-200 dark:hover:border-indigo-800'
              }`}
            >
              <Icon className={`w-5 h-5 ${
                isSelected ? 'text-indigo-500' : 'text-gray-500 dark:text-gray-400'
              }`} />
              <span className={`text-sm font-medium ${
                isSelected ? 'text-indigo-700 dark:text-indigo-300' : 'text-gray-700 dark:text-gray-300'
              }`}>
                {platform.name}
              </span>
              {platform.hasPages && (
                <span className="absolute top-1 right-1 text-[10px] px-1 bg-indigo-100 dark:bg-indigo-900/50 text-indigo-600 dark:text-indigo-400 rounded">
                  Pages
                </span>
              )}
              {isSelected && (
                <Check className="absolute top-1 right-1 w-4 h-4 text-indigo-500" />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
}