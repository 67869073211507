// src/lib/api.ts
import { useAuthStore } from '../stores/authStore';

const API_URL = import.meta.env.VITE_API_URL;

if (!API_URL) {
  throw new Error('VITE_API_URL is not defined in environment variables');
}

// Fonction utilitaire pour les headers d'authentification
const getAuthHeaders = () => {
  const token = useAuthStore.getState().token;
  return {
    'Content-Type': 'application/json',
    ...(token ? { Authorization: token } : {})
  };
};
// Fonction utilitaire pour les requêtes
async function fetchWithAuth(url: string, options: RequestInit = {}) {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...getAuthHeaders(),
      ...(options.headers || {}),
    },
  });

  const data = await response.json();

  if (!response.ok) {
    // Gérer les erreurs d'authentification
    if (response.status === 401 || response.status === 403) {
      useAuthStore.getState().logout();
    }
    throw new Error(data.message || 'Request failed');
  }

  return data;
}

// Fonctions d'authentification
export async function register(data: { email: string; password: string; name: string }) {
  return fetchWithAuth(`${API_URL}/auth/register`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function login(data: { email: string; password: string }) {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
  
  const result = await response.json();
  
  if (!response.ok) {
    throw new Error(result.message || 'Login failed');
  }

  // Important : le token est retourné tel quel, sans modification
  return {
    user: result.user,
    token: result.token
  };
}
// Fonctions pour les autres endpoints protégés
export const api = {
  // Posts
  getPosts: () => fetchWithAuth(`${API_URL}/posts`),
  createPost: (data: any) => fetchWithAuth(`${API_URL}/posts`, {
    method: 'POST',
    body: JSON.stringify(data),
  }),
  updatePost: (id: string, data: any) => fetchWithAuth(`${API_URL}/posts/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  }),
  deletePost: (id: string) => fetchWithAuth(`${API_URL}/posts/${id}`, {
    method: 'DELETE',
  }),

  // Comments
  getComments: () => fetchWithAuth(`${API_URL}/comments`),
  createComment: (data: any) => fetchWithAuth(`${API_URL}/comments`, {
    method: 'POST',
    body: JSON.stringify(data),
  }),

  // Teams
  getTeams: () => fetchWithAuth(`${API_URL}/teams`),
  createTeam: (data: any) => fetchWithAuth(`${API_URL}/teams`, {
    method: 'POST',
    body: JSON.stringify(data),
  }),
  updateTeam: (id: string, data: any) => fetchWithAuth(`${API_URL}/teams/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  }),
  deleteTeam: (id: string) => fetchWithAuth(`${API_URL}/teams/${id}`, {
    method: 'DELETE',
  }),

  // Test authentication
  checkAuth: () => fetchWithAuth(`${API_URL}/health`),
};

export default api;