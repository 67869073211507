import React, { useState, useEffect, useMemo } from 'react';
import { PostListItem } from '../components/postlist/PostListItem';
import { PostDateRangePicker } from '../components/postlist/DateRangePicker';
import { PlatformFilterSelect } from '../components/postlist/PlatformFilterSelect';
import { useSocialAccounts } from '../hooks/useSocialAccounts';
import { format } from 'date-fns';
import { Brain, Filter, RotateCcw } from 'lucide-react';
import { Button } from '../components/ui/button';
import { PostPreviewDialog } from './comments/PostPreviewDialog';
import { PostUpdateDialog } from './PostUpdateDialog ';
import { Card, CardContent, CardFooter } from '../components/ui/card';
import { Input } from '../components/ui/input';
import { Badge } from '../components/ui/badge';
import { Tabs, TabsList, TabsTrigger } from '../components/ui/tabs';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '../components/ui/select';
import { usePosts } from '../hooks/usePosts';
import { toast } from 'react-hot-toast';

export function PostsList() {
  const [expandedPostId, setExpandedPostId] = useState(null);
  const [showPostPreview, setShowPostPreview] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [filters, setFilters] = useState({
    search: '',
    platforms: [],
    pageId: null,
    dateRange: {
      from: null,
      to: null
    }
  });

  const { useAccounts } = useSocialAccounts();
  const { data: accounts = [] } = useAccounts();
  const { usePostsList, remove } = usePosts();

  // Fonction pour obtenir les propriétés du badge de statut
  const getStatusBadgeProps = (status) => {
    switch (status) {
      case 'published':
        return { label: 'Publié', variant: 'success' };
      case 'scheduled':
        return { label: 'Planifié', variant: 'warning' };
      case 'pending':
        return { label: 'En attente', variant: 'default' };
      case 'draft':
        return { label: 'Brouillon', variant: 'secondary' };
      case 'failed':
        return { label: 'Échec', variant: 'destructive' };
      default:
        return { label: status, variant: 'default' };
    }
  };

  // Log quand les filtres changent
  useEffect(() => {
    console.log("Filters changed:", {
      search: filters.search,
      platforms: filters.platforms,
      pageId: filters.pageId,
      dateRange: filters.dateRange
    });
  }, [filters]);

  // Récupérer les pages disponibles
  const availablePages = useMemo(() => {
    console.log("Calculating available pages from accounts:", accounts);
    const pages = accounts
      .filter(account => filters.platforms.length === 0 || filters.platforms.includes(account.platform))
      .reduce((pages, account) => {
        if (account.pages) {
          return [
            ...pages,
            ...account.pages.map(page => ({
              ...page,
              platform: account.platform
            }))
          ];
        }
        return pages;
      }, []);
    console.log("Available pages:", pages);
    return pages;
  }, [accounts, filters.platforms]);

  // Fetch posts avec les filtres
  const { data: postsData, isLoading } = usePostsList({
    page: 1,
    limit: 20,
    platform: filters.platforms.length === 1 ? filters.platforms[0] : undefined,
    pageId: filters.pageId,
    search: filters.search,
    status: selectedStatus === 'all' ? undefined : selectedStatus,
    startDate: filters.dateRange.from?.toISOString(),
    endDate: filters.dateRange.to?.toISOString(),
    includeCommentCount: true
  });

  // Enrichir les posts avec les informations de la page
  const enrichedPosts = useMemo(() => {
    if (!postsData?.posts) return [];
    
    console.log("Enriching posts with page info", {
      postsCount: postsData.posts.length,
      accountsCount: accounts.length
    });

    return postsData.posts.map(post => {
      if (!post.metadata?.pageId) return post;
      
      const account = accounts.find(acc => acc.platform === post.platform);
      const page = account?.pages?.find(p => p.id === post.metadata.pageId);
      
      return {
        ...post,
        metadata: {
          ...post.metadata,
          pageName: page?.name || 'Page non trouvée'
        }
      };
    });
  }, [postsData?.posts, accounts]);

  const handlePreview = (post) => {
    setSelectedPost(post);
    setShowPostPreview(true);
  };

  const handleEdit = (postId) => {
    const post = postsData?.posts.find(p => p._id === postId);
    if (post) {
      setSelectedPost(post);
      setShowUpdateDialog(true);
    }
  };

  const handleDelete = async (postId) => {
    try {
      await remove.mutateAsync(postId);
      toast.success('Publication supprimée avec succès');
    } catch (error) {
      console.error('Error deleting post:', error);
      toast.error('Erreur lors de la suppression de la publication');
    }
  };

  const resetFilters = () => {
    setFilters({
      search: '',
      platforms: [],
      pageId: null,
      dateRange: {
        from: null,
        to: null
      }
    });
    setSelectedStatus('all');
  };

  const statusTabs = [
    { value: 'all', label: 'Tous', count: postsData?.total || 0 },
    { value: 'published', label: 'Publiés', count: postsData?.statusCounts?.published || 0 },
    { value: 'scheduled', label: 'Planifiés', count: postsData?.statusCounts?.scheduled || 0 },
    { value: 'pending', label: 'En attente', count: postsData?.statusCounts?.pending || 0 },
    { value: 'draft', label: 'Brouillons', count: postsData?.statusCounts?.draft || 0 },
  ];

  return (
    <div className="relative min-h-full space-y-4">
      {/* En-tête avec onglets et filtres */}
      <div className="flex flex-col gap-4">
        {/* Onglets de statut */}
        <Tabs value={selectedStatus} onValueChange={setSelectedStatus} className="w-full">
          <TabsList className="w-full justify-start">
            {statusTabs.map(tab => (
              <TabsTrigger key={tab.value} value={tab.value} className="flex gap-2">
                {tab.label}
                <Badge variant="secondary">
                  {tab.count}
                </Badge>
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        {/* Bouton Filtres */}
        <div className="flex justify-end">
          <Button
            variant={showFilters ? "secondary" : "outline"}
            onClick={() => setShowFilters(!showFilters)}
            className="gap-2"
          >
            <Filter className="h-4 w-4" />
            Filtres {filters.platforms.length > 0 || filters.pageId ? 
              `(${filters.platforms.length + (filters.pageId ? 1 : 0)})` : ''}
          </Button>
        </div>
      </div>

      {/* Contenu principal */}
      <div className={`grid gap-6 transition-all duration-200 ${showFilters ? 'grid-cols-[300px_1fr]' : 'grid-cols-1'}`}>
        {/* Sidebar Filtres */}
        {showFilters && (
          <Card className="h-fit sticky top-4">
            <CardContent className="p-6 space-y-6">
              <div className="flex items-center justify-between">
                <h3 className="font-medium">Filtres</h3>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={resetFilters}
                  className="text-muted-foreground hover:text-primary"
                >
                  <RotateCcw className="w-4 h-4 mr-2" />
                  Réinitialiser
                </Button>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Recherche</label>
                <Input
                  type="text"
                  value={filters.search}
                  onChange={(e) => setFilters(prev => ({
                    ...prev,
                    search: e.target.value
                  }))}
                  placeholder="Rechercher..."
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Plateformes</label>
                <PlatformFilterSelect
                  platforms={accounts.map(acc => ({
                    id: acc.platform,
                    name: acc.platform
                  }))}
                  selected={filters.platforms}
                  onChange={(platforms) => setFilters(prev => ({
                    ...prev,
                    platforms
                  }))}
                />
              </div>

              {availablePages.length > 0 && (
                <div className="space-y-2">
                  <label className="text-sm font-medium">Pages</label>
                  <Select
                    value={filters.pageId || 'all'}
                    onValueChange={(value) => setFilters(prev => ({
                      ...prev,
                      pageId: value === 'all' ? null : value
                    }))}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Toutes les pages" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">Toutes les pages</SelectItem>
                      {availablePages.map((page) => (
                        <SelectItem key={page.id} value={page.id}>
                          <div className="flex items-center gap-2">
                            <span className="capitalize text-xs text-muted-foreground">
                              {page.platform}:
                            </span>
                            <span>{page.name}</span>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

              <div className="space-y-2">
                <label className="text-sm font-medium">Période</label>
                <PostDateRangePicker
                  startDate={filters.dateRange.from}
                  endDate={filters.dateRange.to}
                  onChange={(range) => setFilters(prev => ({
                    ...prev,
                    dateRange: range
                  }))}
                />
              </div>
            </CardContent>
            
            <CardFooter className="border-t bg-muted/50 py-3">
              <p className="text-sm text-muted-foreground">
                {Object.values(filters).filter(value => 
                  Array.isArray(value) ? value.length > 0 : value !== null && value !== ''
                ).length} filtres actifs
              </p>
            </CardFooter>
          </Card>
        )}

        {/* Liste des posts */}
        <div className="space-y-4">
          {isLoading ? (
            <Card>
              <CardContent className="p-6 text-center">
                Chargement...
              </CardContent>
            </Card>
          ) : enrichedPosts.length > 0 ? (
            enrichedPosts.map((post) => (
              <PostListItem
                key={post._id}
                post={post}
                isExpanded={expandedPostId === post._id}
                onToggleExpand={() => setExpandedPostId(
                  expandedPostId === post._id ? null : post._id
                )}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onPreview={handlePreview}
              />
            ))
          ) : (
            <Card>
              <CardContent className="p-6 text-center text-muted-foreground">
                Aucun post ne correspond aux critères sélectionnés
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      {/* Dialogs */}
      {showPostPreview && selectedPost && (
        <PostPreviewDialog
          post={selectedPost}
          isOpen={showPostPreview}
          onClose={() => {
            setShowPostPreview(false);
            setSelectedPost(null);
          }}
        />
      )}

      {showUpdateDialog && selectedPost && (
        <PostUpdateDialog
          post={selectedPost}
          isOpen={showUpdateDialog}
          onClose={() => {
            setShowUpdateDialog(false);
            setSelectedPost(null);
          }}
        />
      )}
    </div>
  );
}