import React, { useState, useEffect } from 'react';
import { Brain, X, Calendar, Save, Loader2, Sparkles } from 'lucide-react';
import { MultiPlatformEditor } from './suggestions/ui/MultiPlatformEditor';
import { AISettingsPanel } from './suggestions/ui/AISettings';
import { PostSuggestions } from './suggestions/PostSuggestions';
import { useAISettings } from './suggestions/hooks/useAISettings';
import { PlatformSelector } from './PlatformSelector';
import { PageSelector } from './PageSelector';
import { DateTimePicker } from './DateTimePicker';
import { useSocialAccounts } from '../hooks/useSocialAccounts';
import { usePosts } from '../hooks/usePosts';
import { toast } from 'react-hot-toast';

interface PlatformContent {
  platform: string;
  content: string;
  hashtags: string[];
}

interface PostEditorProps {
  mode?: 'create' | 'edit' | 'schedule';
  postId?: string;
  onSuccess?: () => void;
  onClose?: () => void;
  defaultPlatforms?: string[];
  defaultScheduledDate?: Date;
}

export function PostEditor({ 
  mode = 'create', 
  postId, 
  onSuccess, 
  onClose,
  defaultPlatforms = [],
  defaultScheduledDate
}: PostEditorProps) {
  // États
  const [platformContents, setPlatformContents] = useState<PlatformContent[]>([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>(defaultPlatforms);
  const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
  const [media, setMedia] = useState<Array<{ url: string; type: 'image' | 'video' }>>([]);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(defaultScheduledDate || null);
  const [showAISettings, setShowAISettings] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Hooks
  const { create, update, usePost } = usePosts();
  const { data: existingPost } = usePost(postId);
  const { useAccounts } = useSocialAccounts();
  const { settings, updateSettings } = useAISettings();
  const { data: accounts = [] } = useAccounts();

  // Récupérer les pages Facebook
  const facebookPages = React.useMemo(() => {
    const fbAccount = accounts.find(a => a.platform === 'facebook');
    return fbAccount?.pages || [];
  }, [accounts]);

  // Effet pour charger un post existant
  useEffect(() => {
    if (existingPost) {
      setPlatformContents([{
        platform: existingPost.platform,
        content: existingPost.content,
        hashtags: existingPost.hashtags || []
      }]);
      setSelectedPlatforms([existingPost.platform]);
      setMedia(existingPost.media || []);
      setScheduledDate(existingPost.scheduledDate ? new Date(existingPost.scheduledDate) : null);
      setSelectedPageId(existingPost.metadata?.pageId || null);
    }
  }, [existingPost]);

  // Effet pour initialiser les contenus des plateformes
  useEffect(() => {
    if (selectedPlatforms.length > 0) {
      setPlatformContents(prev => {
        const newContents = selectedPlatforms.map(platform => {
          const existing = prev.find(p => p.platform === platform);
          return existing || {
            platform,
            content: '',
            hashtags: []
          };
        });
        return newContents;
      });
    }
  }, [selectedPlatforms]);

  const handleContentChange = (platform: string, content: string) => {
    setPlatformContents(prev => {
      const index = prev.findIndex(p => p.platform === platform);
      if (index >= 0) {
        const updated = [...prev];
        updated[index] = { ...updated[index], content };
        return updated;
      }
      return [...prev, { platform, content, hashtags: [] }];
    });
  };

  const handleSuggestionSelect = (suggestion: string, platform: string) => {
    handleContentChange(platform, suggestion);
    setShowSuggestions(false);
  };

  


// Dans PostEditor.tsx, modifier la fonction handlePublish :

const handlePublish = async (platform?: string, action: 'draft' | 'publish' | 'schedule' = 'publish') => {
  if (!platformContents.length) return;

  setIsLoading(true);
  try {
    const publishPromises = platformContents
      .filter(content => !platform || content.platform === platform)
      .map(async (content) => {
        if (!validatePost(content, action)) {
          throw new Error('Validation failed');
        }

        const selectedPage = content.platform === 'facebook' && selectedPageId ? 
          facebookPages.find(p => p.id === selectedPageId) : null;

        // Créer l'objet moderation séparément pour s'assurer qu'il est complet
        const moderation = {
          status: 'pending',
          score: 0,
          flags: [] as string[]
        };

        const postData = {
          content: content.content,
          platform: content.platform,
          media: media || [],
          hashtags: content.hashtags || [],
          status: action === 'draft' ? 'draft' : action === 'schedule' ? 'scheduled' : 'pending',
          scheduledDate: action === 'schedule' ? scheduledDate : null,
          pageId: selectedPageId,
          metadata: {
            pageId: selectedPageId,
            pageName: selectedPage?.name || null,
            accountType: 'page',
            moderation // Utiliser l'objet moderation créé ci-dessus
          }
        };

        // Debug log
        console.log('Sending post data:', JSON.stringify(postData, null, 2));

        if (mode === 'edit' && postId) {
          return update.mutateAsync({
            id: postId,
            data: postData
          });
        } else {
          return create.mutateAsync(postData);
        }
      });

    await Promise.all(publishPromises);

    toast.success(
      action === 'draft' ? 'Brouillon enregistré' :
      action === 'schedule' ? 'Publication planifiée' :
      'Publication en cours'
    );

    onSuccess?.();
    onClose?.();
  } catch (error) {
    console.error('Publication error:', error);
    if (error instanceof Error && error.message === 'Validation failed') {
      return;
    }
    toast.error(error instanceof Error ? error.message : 'Erreur lors de la publication');
  } finally {
    setIsLoading(false);
  }
};

// Valider que le pageId est bien sélectionné avant d'autoriser la publication
const validatePost = (content: PlatformContent, action: 'draft' | 'publish' | 'schedule') => {
  // Les brouillons n'ont pas besoin de validation
  if (action === 'draft') return true;

  // Vérifier que le contenu n'est pas vide
  if (!content.content?.trim()) {
    toast.error('Le contenu ne peut pas être vide');
    return false;
  }

  // Pour Facebook, vérifier qu'une page est sélectionnée
  if (content.platform === 'facebook') {
    if (!selectedPageId) {
      toast.error('Veuillez sélectionner une page Facebook');
      return false;
    }
    
    // Vérifier que la page existe bien dans la liste
    const pageExists = facebookPages.some(page => page.id === selectedPageId);
    if (!pageExists) {
      toast.error('La page Facebook sélectionnée est invalide');
      return false;
    }
  }

  // Si on planifie, vérifier qu'une date est sélectionnée
  if (action === 'schedule' && !scheduledDate) {
    toast.error('Veuillez sélectionner une date de publication');
    return false;
  }

  return true;
};


  return (
    <div className="flex flex-col h-[calc(100vh-200px)] max-h-[800px]">
      {/* En-tête fixe */}
      <div className="sticky top-0 bg-white dark:bg-gray-800 border-b dark:border-gray-700 p-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold">
          {mode === 'edit' ? 'Modifier la publication' :
           mode === 'schedule' ? 'Planifier une publication' :
           'Nouvelle publication'}
        </h2>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setShowSuggestions(true)}
            className="p-2 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 rounded-lg"
            title="Suggestions IA"
          >
            <Sparkles className="w-5 h-5" />
          </button>
          <button
            onClick={() => setShowAISettings(true)}
            className="p-2 text-gray-500 hover:text-indigo-600 dark:text-gray-400 dark:hover:text-indigo-400 rounded-lg"
            title="Paramètres IA"
          >
            <Brain className="w-5 h-5" />
          </button>
          {onClose && (
            <button
              onClick={onClose}
              className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg"
            >
              <X className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>

      {/* Contenu défilant */}
      <div className="flex-1 overflow-y-auto py-4 space-y-6">
        {/* Sélection des plateformes */}
        <PlatformSelector
          selectedPlatforms={selectedPlatforms}
          onPlatformChange={setSelectedPlatforms}
        />

        {/* Sélection de page Facebook */}
        {selectedPlatforms.includes('facebook') && facebookPages.length > 0 && (
          <PageSelector
            pages={facebookPages}
            selectedPageId={selectedPageId}
            onPageSelect={setSelectedPageId}
          />
        )}

        {/* Éditeur multi-plateformes */}
        {selectedPlatforms.length > 0 && (
          <div className="border dark:border-gray-700 rounded-lg">
            <MultiPlatformEditor
              platforms={selectedPlatforms}
              contents={platformContents}
              media={media}
              onMediaUpload={setMedia}
              onContentChange={handleContentChange}
              onPublish={handlePublish}
              isPublishing={isLoading}
              onRequestSuggestions={() => setShowSuggestions(true)}
            />
          </div>
        )}

        {/* Planification */}
        {mode === 'schedule' && (
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700 dark:text-gray-300">
              <Calendar className="w-5 h-5" />
              Date et heure de publication
            </label>
            <DateTimePicker
              value={scheduledDate}
              onChange={setScheduledDate}
              minDate={new Date()}
            />
          </div>
        )}
      </div>

      {/* Actions fixes en bas */}
      <div className="flex justify-between items-center pt-4 border-t dark:border-gray-700 mt-4">
        <button
          onClick={() => handlePublish(undefined, 'draft')}
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
        >
          <Save className="w-5 h-5" />
          Brouillon
        </button>

        <button
          onClick={() => handlePublish(undefined, mode === 'schedule' ? 'schedule' : 'publish')}
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isLoading ? (
            <Loader2 className="w-5 h-5 animate-spin" />
          ) : (
            <Calendar className="w-5 h-5" />
          )}
          {mode === 'schedule' ? 'Planifier' : 'Publier'}
        </button>
      </div>

      {/* Suggestions */}
      {showSuggestions && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-2xl w-full p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium">Suggestions IA</h3>
              <button onClick={() => setShowSuggestions(false)}>
                <X className="w-5 h-5" />
              </button>
            </div>
            <PostSuggestions
              content={platformContents[0]?.content || ''}
              platforms={selectedPlatforms}
              onSelect={(suggestion) => handleSuggestionSelect(suggestion, selectedPlatforms[0])}
              settings={settings}
              context={[
                selectedPageId ? `Page: ${facebookPages.find(p => p.id === selectedPageId)?.name}` : null,
                media?.length ? `Media: ${media.length} fichiers` : null
              ].filter(Boolean)}
            />
          </div>
        </div>
      )}

      {/* Panneau des paramètres IA */}
      <AISettingsPanel
        isOpen={showAISettings}
        onClose={() => setShowAISettings(false)}
        settings={settings}
        onUpdate={updateSettings}
        onSave={() => setShowAISettings(false)}
      />
    </div>
  );
}