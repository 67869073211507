import React from 'react';
import { motion } from 'framer-motion';
import { 
  Calendar, 
  MessageCircle, 
  BarChart2, 
  Brain,
  Zap,
  Users,
  Globe,
  Lock
} from 'lucide-react';
import { Link } from 'react-router-dom';

const features = [
  {
    name: 'Planification intelligente',
    description: 'Planifiez vos publications sur tous vos réseaux sociaux avec notre calendrier intelligent qui suggère les meilleurs moments pour publier.',
    icon: Calendar,
    image: 'https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?q=80&w=2070&auto=format&fit=crop'
  },
  {
    name: 'Engagement communautaire',
    description: 'Gérez tous vos commentaires et messages depuis une seule interface avec des réponses automatiques intelligentes.',
    icon: MessageCircle,
    image: 'https://images.unsplash.com/photo-1577563908411-5077b6dc7624?q=80&w=2070&auto=format&fit=crop'
  },
  {
    name: 'Analytics avancés',
    description: 'Suivez et analysez vos performances avec des tableaux de bord détaillés et des rapports personnalisables.',
    icon: BarChart2,
    image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?q=80&w=2070&auto=format&fit=crop'
  },
  {
    name: 'IA intégrée',
    description: 'Optimisez votre contenu avec notre IA qui génère des suggestions et analyse le sentiment de votre audience.',
    icon: Brain,
    image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?q=80&w=2070&auto=format&fit=crop'
  }
];

export function Features() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      {/* Hero Section */}
      <div 
        className="relative h-[500px] bg-cover bg-center"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?q=80&w=2074&auto=format&fit=crop")',
        }}
      >
        <div className="absolute inset-0 bg-black/50" />
        <div className="absolute inset-0 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center text-white max-w-3xl mx-auto px-4"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Toutes les fonctionnalités dont vous avez besoin
            </h1>
            <p className="text-xl text-white/90 mb-8">
              Une suite complète d'outils pour gérer votre présence sur les réseaux sociaux
            </p>
            <Link
              to="/signup"
              className="inline-flex items-center justify-center px-8 py-3 text-base font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Commencer gratuitement
            </Link>
          </motion.div>
        </div>
      </div>

      {/* Main Features */}
      <div className="container mx-auto px-4 py-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-6xl mx-auto">
          {features.map((feature, index) => (
            <motion.div
              key={feature.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
              className="relative group"
            >
              <div className="aspect-video rounded-2xl overflow-hidden mb-6">
                <img
                  src={feature.image}
                  alt={feature.name}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              <div className="flex items-start gap-4">
                <div className="p-2 bg-indigo-100 dark:bg-indigo-900/30 rounded-lg">
                  <feature.icon className="w-6 h-6 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 dark:text-white">
                    {feature.name}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300">
                    {feature.description}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Additional Features */}
      <div className="bg-indigo-600 py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <h2 className="text-3xl font-bold text-white mb-6">
              Et bien plus encore...
            </h2>
            <p className="text-xl text-indigo-100">
              Découvrez toutes les fonctionnalités qui font d'Acerfi Social Hub la plateforme idéale pour votre présence sociale
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="bg-white dark:bg-gray-800 rounded-xl p-6"
            >
              <Zap className="w-8 h-8 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2 dark:text-white">
                Performance optimale
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Une plateforme rapide et fiable pour gérer vos réseaux sociaux sans interruption.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-white dark:bg-gray-800 rounded-xl p-6"
            >
              <Users className="w-8 h-8 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2 dark:text-white">
                Collaboration d'équipe
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Travaillez efficacement en équipe avec des rôles et permissions personnalisables.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="bg-white dark:bg-gray-800 rounded-xl p-6"
            >
              <Globe className="w-8 h-8 text-indigo-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2 dark:text-white">
                Support multilingue
              </h3>
              <p className="text-gray-600 dark:text-gray-300">
                Gérez votre contenu dans plusieurs langues pour toucher une audience mondiale.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-4xl mx-auto text-center"
        >
          <Lock className="w-12 h-12 text-indigo-600 mx-auto mb-6" />
          <h2 className="text-3xl font-bold mb-6 dark:text-white">
            Prêt à améliorer votre présence sociale ?
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
            Commencez gratuitement pendant 14 jours, sans carte bancaire
          </p>
          <Link
            to="/signup"
            className="inline-flex items-center justify-center px-8 py-3 text-base font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Démarrer maintenant
          </Link>
        </motion.div>
      </div>
    </div>
  );
}